
import { PCBuild } from './types';
import { getDefaultPerformance } from './defaultPerformance';

/**
 * Ensures a build has performance metrics by adding default ones if needed
 */
export const addDefaultPerformance = (build: PCBuild): PCBuild => {
  if (!build.performance) {
    return {
      ...build,
      performance: getDefaultPerformance(build.price || 0)
    };
  }
  return build;
};

/**
 * Adds default performance metrics to an array of builds
 */
export const addDefaultPerformanceToBuilds = (builds: PCBuild[]): PCBuild[] => {
  return builds.map(build => addDefaultPerformance(build));
};

/**
 * Calculate the total power consumption of a PC build
 */
export const calculatePowerConsumption = (build: PCBuild): number => {
  // This is a simplified calculation
  // In a real app, we would calculate based on actual component specs
  const basePower = 50; // Base system power
  let totalPower = basePower;
  
  // Add CPU power (estimate based on range)
  const cpuString = String(build.components.cpu).toLowerCase();
  if (cpuString.includes('i9') || cpuString.includes('ryzen 9')) {
    totalPower += 150;
  } else if (cpuString.includes('i7') || cpuString.includes('ryzen 7')) {
    totalPower += 125;
  } else if (cpuString.includes('i5') || cpuString.includes('ryzen 5')) {
    totalPower += 95;
  } else {
    totalPower += 65;
  }
  
  // Add GPU power (estimate based on range)
  const gpuString = String(build.components.gpu).toLowerCase();
  if (gpuString.includes('4090')) {
    totalPower += 450;
  } else if (gpuString.includes('4080')) {
    totalPower += 320;
  } else if (gpuString.includes('4070 ti') || gpuString.includes('3080')) {
    totalPower += 285;
  } else if (gpuString.includes('4070') || gpuString.includes('3070')) {
    totalPower += 220;
  } else if (gpuString.includes('4060') || gpuString.includes('3060')) {
    totalPower += 170;
  } else if (gpuString.includes('6800') || gpuString.includes('6700')) {
    totalPower += 230;
  } else if (gpuString.includes('6600')) {
    totalPower += 180;
  } else {
    totalPower += 150;
  }
  
  // Add RAM power (very rough estimate)
  totalPower += 10;
  
  // Add storage power (rough estimate)
  totalPower += 10;
  
  // Add cooling power
  const coolingString = String(build.components.cooling).toLowerCase();
  if (coolingString.includes('liquid') || coolingString.includes('aio')) {
    totalPower += 15;
  } else {
    totalPower += 5;
  }
  
  // Add extra for fans, RGB, etc.
  if (build.theme.includes('rgb')) {
    totalPower += 20;
  } else {
    totalPower += 10;
  }
  
  return totalPower;
};
