
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { PCBuild } from '@/utils/pcBuilderUtils';
import { toast } from '@/hooks/use-toast';
import { useFirebaseAuth } from '@/contexts/FirebaseAuthContext';

// Define the shape of our user data
type SavedBuild = {
  id: string;
  buildId: string;
  name: string;
  createdAt: Date;
};

// Context type definition
type UserContextType = {
  isAuthenticated: boolean;
  savedBuilds: SavedBuild[];
  saveBuild: (build: PCBuild) => Promise<void>;
  deleteSavedBuild: (buildId: string) => Promise<void>;
  fetchSavedBuilds: () => Promise<void>;
};

// Create the context with a default value
const UserContext = createContext<UserContextType>({
  isAuthenticated: false,
  savedBuilds: [],
  saveBuild: async () => {},
  deleteSavedBuild: async () => {},
  fetchSavedBuilds: async () => {},
});

// Hook to use the context
export const useUserContext = () => useContext(UserContext);

// Provider component
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, currentUser } = useFirebaseAuth();
  const [savedBuilds, setSavedBuilds] = useState<SavedBuild[]>([]);
  const userId = currentUser?.uid || null;

  // Fetch saved builds when the user changes
  useEffect(() => {
    if (isAuthenticated && userId) {
      fetchSavedBuilds();
    } else {
      setSavedBuilds([]);
    }
  }, [isAuthenticated, userId]);

  // Mock functions for saving/fetching builds
  const fetchSavedBuilds = async () => {
    if (!isAuthenticated || !userId) return;
    
    try {
      // In a real app, this would be an API call or database query
      // For this demo, we'll use localStorage to persist data
      const savedBuildsData = localStorage.getItem(`saved-builds-${userId}`);
      if (savedBuildsData) {
        const builds = JSON.parse(savedBuildsData);
        // Convert string dates back to Date objects
        builds.forEach((build: any) => {
          build.createdAt = new Date(build.createdAt);
        });
        setSavedBuilds(builds);
      }
    } catch (error) {
      console.error('Error fetching saved builds:', error);
      toast({
        title: 'Error',
        description: 'Could not load your saved builds',
        variant: 'destructive',
      });
    }
  };

  const saveBuild = async (build: PCBuild) => {
    if (!isAuthenticated || !userId) {
      toast({
        title: 'Not signed in',
        description: 'You need to sign in to save builds',
        variant: 'destructive',
      });
      return;
    }
    
    try {
      // Create a new saved build
      const newSavedBuild: SavedBuild = {
        id: crypto.randomUUID(),
        buildId: build.id,
        name: build.name,
        createdAt: new Date(),
      };
      
      // Add to state
      const updatedBuilds = [...savedBuilds, newSavedBuild];
      setSavedBuilds(updatedBuilds);
      
      // In a real app, this would be an API call to save to a database
      // For this demo, we'll use localStorage
      localStorage.setItem(`saved-builds-${userId}`, JSON.stringify(updatedBuilds));
      
      toast({
        title: 'Build Saved',
        description: `${build.name} has been saved to your account`,
      });
    } catch (error) {
      console.error('Error saving build:', error);
      toast({
        title: 'Error',
        description: 'Could not save your build',
        variant: 'destructive',
      });
    }
  };

  const deleteSavedBuild = async (savedBuildId: string) => {
    if (!isAuthenticated || !userId) return;
    
    try {
      // Filter out the build to delete
      const updatedBuilds = savedBuilds.filter(build => build.id !== savedBuildId);
      setSavedBuilds(updatedBuilds);
      
      // Update storage
      localStorage.setItem(`saved-builds-${userId}`, JSON.stringify(updatedBuilds));
      
      toast({
        title: 'Build Removed',
        description: 'The build has been removed from your saved builds',
      });
    } catch (error) {
      console.error('Error deleting saved build:', error);
      toast({
        title: 'Error',
        description: 'Could not delete your saved build',
        variant: 'destructive',
      });
    }
  };

  const value = {
    isAuthenticated,
    savedBuilds,
    saveBuild,
    deleteSavedBuild,
    fetchSavedBuilds,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
