
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Edit2 } from 'lucide-react';
import { useImageTracker } from '@/hooks/useImageTracker';
import EditImageDialog from './EditImageDialog';
import { cn } from '@/lib/utils';

interface ImageEditButtonProps {
  imageId: string;
  imageUrl: string;
  category?: string;
  fallbackUrl?: string;
  onImageChange?: (newUrl: string) => void;
  className?: string;
  size?: 'sm' | 'default' | 'lg';
  children?: React.ReactNode;
  variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link';
}

const ImageEditButton = ({
  imageId,
  imageUrl,
  category,
  fallbackUrl,
  onImageChange,
  className,
  size = 'sm',
  children,
  variant = 'ghost'
}: ImageEditButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const {
    currentUrl,
    pendingUrl,
    isEdited,
    startEditing,
    cancelEditing,
    saveEdit,
    resetToOriginal,
    updatePendingUrl
  } = useImageTracker(imageId, imageUrl, { category, fallbackUrl });
  
  const handleSave = () => {
    saveEdit();
    if (onImageChange) {
      onImageChange(pendingUrl);
    }
  };
  
  const handleOpenDialog = () => {
    startEditing();
    setIsDialogOpen(true);
  };
  
  return (
    <>
      <Button 
        variant={variant} 
        size={size} 
        onClick={handleOpenDialog}
        className={cn(
          isEdited ? "text-amber-500" : "", 
          className
        )}
        title={isEdited ? "Image has been edited" : "Edit image"}
      >
        {children || <Edit2 className="h-4 w-4" />}
      </Button>
      
      <EditImageDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        imageId={imageId}
        originalUrl={imageUrl}
        currentUrl={currentUrl}
        pendingUrl={pendingUrl}
        onPendingUrlChange={updatePendingUrl}
        onSave={handleSave}
        onCancel={cancelEditing}
        onReset={resetToOriginal}
        category={category}
        fallbackUrl={fallbackUrl}
      />
    </>
  );
};

export default ImageEditButton;
