
import { create } from 'zustand';
import { PCBuild, Accessory, UseCase, ThemeOption, ComponentSpec, ComponentType } from '@/utils/pcBuilder/types';

export interface PCBuilderStore {
  // PC Builder state
  budget: number;
  useCase: UseCase;
  theme: ThemeOption;
  currentStep: number;
  selectedBuild: PCBuild | null;
  filteredBuilds: PCBuild[];
  recommendedAccessories: Accessory[];
  selectedAccessories: Set<string>;
  accessoryGroups: any[];
  orderCompleted: boolean;
  expandedBudgetRange: boolean;
  isCustomFiltered: boolean;
  
  // PC Part Selector state
  selectedComponents: Record<string, ComponentSpec>;
  availableComponents: Record<string, ComponentSpec[]>;
  customBuild: PCBuild | null;
  compatibilityMessages: string[];
  
  // PC Builder actions
  setBudget: (budget: number) => void;
  setUseCase: (useCase: UseCase) => void;
  setTheme: (theme: ThemeOption) => void;
  setCurrentStep: (step: number) => void;
  setSelectedBuild: (build: PCBuild | null) => void;
  setFilteredBuilds: (builds: PCBuild[]) => void;
  setRecommendedAccessories: (accessories: Accessory[]) => void;
  setAccessoryGroups: (groups: any[]) => void;
  toggleAccessorySelection: (accessoryId: string) => void;
  setOrderCompleted: (completed: boolean) => void;
  setExpandedBudgetRange: (expanded: boolean) => void;
  setIsCustomFiltered: (isCustom: boolean) => void;
  resetBuilderState: () => void;
  
  // PC Part Selector actions
  selectComponent: (type: string, component: ComponentSpec) => void;
  setAvailableComponents: (type: string, components: ComponentSpec[]) => void;
  updateCustomBuild: () => void;
  resetCustomBuild: () => void;
  resetSelection: () => void; // Add this for PCPartPicker.tsx
}

const usePCBuilderStore = create<PCBuilderStore>((set, get) => ({
  // PC Builder state
  budget: 1500,
  useCase: 'gaming',
  theme: 'rgb',
  currentStep: 1,
  selectedBuild: null,
  filteredBuilds: [],
  recommendedAccessories: [],
  selectedAccessories: new Set<string>(),
  accessoryGroups: [],
  orderCompleted: false,
  expandedBudgetRange: false,
  isCustomFiltered: false,
  
  // PC Part Selector state
  selectedComponents: {},
  availableComponents: {
    cpu: [],
    motherboard: [],
    gpu: [],
    ram: [],
    storage: [],
    case: [],
    psu: [],
    cooling: [],
    os: [],
    monitor: [],
    keyboard: [],
    mouse: [],
    headset: [],
    speakers: [],
    webcam: [],
    microphone: [],
    networkCard: [],
    soundCard: []
  },
  customBuild: null,
  compatibilityMessages: [],
  
  // PC Builder actions
  setBudget: (budget) => set({ budget }),
  setUseCase: (useCase) => set({ useCase }),
  setTheme: (theme) => set({ theme }),
  setCurrentStep: (currentStep) => set({ currentStep }),
  setSelectedBuild: (selectedBuild) => set({ selectedBuild }),
  setFilteredBuilds: (filteredBuilds) => set({ filteredBuilds }),
  setRecommendedAccessories: (recommendedAccessories) => set({ recommendedAccessories }),
  setAccessoryGroups: (accessoryGroups) => set({ accessoryGroups }),
  
  toggleAccessorySelection: (accessoryId) => set((state) => {
    const newSelectedAccessories = new Set(state.selectedAccessories);
    if (newSelectedAccessories.has(accessoryId)) {
      newSelectedAccessories.delete(accessoryId);
    } else {
      newSelectedAccessories.add(accessoryId);
    }
    return { selectedAccessories: newSelectedAccessories };
  }),
  
  setOrderCompleted: (orderCompleted) => set({ orderCompleted }),
  setExpandedBudgetRange: (expandedBudgetRange) => set({ expandedBudgetRange }),
  setIsCustomFiltered: (isCustomFiltered) => set({ isCustomFiltered }),
  
  resetBuilderState: () => set({
    budget: 1500,
    useCase: 'gaming',
    theme: 'rgb',
    currentStep: 1,
    selectedBuild: null,
    filteredBuilds: [],
    recommendedAccessories: [],
    selectedAccessories: new Set<string>(),
    accessoryGroups: [],
    orderCompleted: false,
    expandedBudgetRange: false,
    isCustomFiltered: false
  }),
  
  // Reset all selections in PC Part Picker
  resetSelection: () => set({
    selectedComponents: {},
    customBuild: null,
    compatibilityMessages: []
  }),
  
  // PC Part Selector actions
  selectComponent: (type, component) => set((state) => ({
    selectedComponents: {
      ...state.selectedComponents,
      [type]: component
    }
  })),
  
  setAvailableComponents: (type, components) => set((state) => ({
    availableComponents: {
      ...state.availableComponents,
      [type]: components
    }
  })),
  
  updateCustomBuild: () => set((state) => {
    const { selectedComponents, budget, useCase, theme } = state;
    
    // Calculate total price of selected components
    const totalPrice = Object.values(selectedComponents).reduce(
      (sum, component) => sum + (component?.price || 0), 
      0
    );
    
    // Create a component record that matches PCBuildComponents
    const componentRecord: Record<string, string> = {};
    Object.entries(selectedComponents).forEach(([type, component]) => {
      if (component) {
        componentRecord[type] = component.name || '';
      }
    });
    
    // Create a build from selected components
    const customBuild: PCBuild = {
      id: 'custom-build',
      name: 'Custom PC Build',
      description: 'Your custom configured PC build',
      price: totalPrice > 0 ? totalPrice : budget,
      components: {
        cpu: componentRecord.cpu || '',
        motherboard: componentRecord.motherboard || '',
        gpu: componentRecord.gpu || '',
        ram: componentRecord.ram || '',
        storage: componentRecord.storage || '',
        case: componentRecord.case || '',
        psu: componentRecord.psu || '',
        cooling: componentRecord.cooling || ''
      },
      performance: {
        overall: 75, // Default placeholder - would be calculated based on components
        gaming: 80,
        productivity: 70,
        videoEditing: 65,
        streaming: 60
      },
      useCase: useCase,
      theme: theme,
      isValidated: true
    };
    
    return { customBuild };
  }),
  
  resetCustomBuild: () => set({
    selectedComponents: {},
    customBuild: null,
    compatibilityMessages: []
  })
}));

export default usePCBuilderStore;
