
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PCPartSelector from '@/components/PCPartSelector';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { ArrowLeft, Save, Info } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import usePCBuilderStore from '@/store/usePCBuilderStore';
import { ComponentSpec } from '@/components/PCPartSelector/types';

const PCBuilder = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [savedBuilds, setSavedBuilds] = useState<any[]>([]);
  
  // Get reset function from store
  const { resetSelection } = usePCBuilderStore();
  
  useEffect(() => {
    // Reset the builder state when component mounts
    resetSelection();
    
    // Load saved builds from localStorage
    const loadSavedBuilds = () => {
      try {
        const savedData = localStorage.getItem('saved_pc_builds');
        if (savedData) {
          setSavedBuilds(JSON.parse(savedData));
        }
      } catch (error) {
        console.error('Error loading saved builds:', error);
        toast({
          title: "Error Loading Saved Builds",
          description: "Could not load your saved builds. Local storage may be unavailable.",
          variant: "destructive"
        });
      }
    };
    
    loadSavedBuilds();
  }, [resetSelection, toast]);
  
  const handleBuildComplete = (buildData: Record<string, ComponentSpec>) => {
    // Save build to localStorage
    const newBuild = {
      id: Date.now().toString(),
      name: `Build #${savedBuilds.length + 1}`,
      date: new Date().toISOString(),
      components: buildData,
      totalPrice: Object.values(buildData).reduce((total: number, part: ComponentSpec) => {
        return total + (part?.price || 0);
      }, 0)
    };
    
    const updatedBuilds = [...savedBuilds, newBuild];
    setSavedBuilds(updatedBuilds);
    
    try {
      localStorage.setItem('saved_pc_builds', JSON.stringify(updatedBuilds));
      toast({
        title: "Build Saved",
        description: "Your PC build has been saved successfully."
      });
    } catch (error) {
      console.error('Error saving build:', error);
      toast({
        title: "Error",
        description: "Failed to save your PC build. Local storage may be unavailable.",
        variant: "destructive"
      });
    }
  };
  
  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-6">
        <div className="flex items-center gap-4">
          <Button 
            variant="outline" 
            size="icon"
            onClick={() => navigate('/')}
            className="h-9 w-9"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-2xl font-bold">PC Builder</h1>
        </div>
        
        <div>
          <Button
            variant="outline"
            onClick={() => navigate('/saved-builds')}
            disabled={savedBuilds.length === 0}
            className="flex items-center gap-2"
          >
            <Save className="h-4 w-4" />
            View Saved Builds ({savedBuilds.length})
          </Button>
        </div>
      </div>
      
      <Card className="mb-8 p-6 bg-gradient-to-r from-blue-50/50 to-indigo-50/50 dark:from-blue-950/20 dark:to-indigo-950/20 border border-blue-100 dark:border-blue-900/30">
        <div className="flex items-start gap-4">
          <div className="mt-1">
            <Info className="h-5 w-5 text-blue-500 dark:text-blue-400" />
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Build Your Ultimate PC</h3>
            <p className="text-sm text-muted-foreground leading-relaxed">
              Select components for your PC build from our comprehensive database of trusted vendors. Our system will help you choose compatible parts and estimate the total cost. You can save your completed builds for future reference.
            </p>
            <div className="mt-3 flex flex-wrap gap-3">
              <Badge variant="outline" className="bg-blue-50/50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 border-blue-200 dark:border-blue-800">
                Compatibility Check
              </Badge>
              <Badge variant="outline" className="bg-blue-50/50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 border-blue-200 dark:border-blue-800">
                Price Tracking
              </Badge>
              <Badge variant="outline" className="bg-blue-50/50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 border-blue-200 dark:border-blue-800">
                Performance Estimation
              </Badge>
              <Badge variant="outline" className="bg-blue-50/50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 border-blue-200 dark:border-blue-800">
                Component Filtering
              </Badge>
            </div>
          </div>
        </div>
      </Card>
      
      <PCPartSelector onBuildComplete={handleBuildComplete} />
    </div>
  );
};

export default PCBuilder;
