
import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { 
  User, 
  GoogleAuthProvider, 
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '@/firebase/config';
import { toast } from 'sonner';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Auth context type definition
type FirebaseAuthContextType = {
  currentUser: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  authError: string | null;
  signInWithGoogle: () => Promise<void>;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  signUpWithEmail: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
};

// Create the auth context
const FirebaseAuthContext = createContext<FirebaseAuthContextType>({
  currentUser: null,
  isAuthenticated: false,
  loading: true,
  authError: null,
  signInWithGoogle: async () => {},
  signInWithEmail: async () => {},
  signUpWithEmail: async () => {},
  logOut: async () => {}
});

// Hook to use the auth context
export const useFirebaseAuth = () => useContext(FirebaseAuthContext);

// Auth provider component
export const FirebaseAuthProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState<string | null>(null);

  // Monitor auth state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
      console.log("Auth state changed:", user?.email || "No user");
    });

    return () => unsubscribe();
  }, []);

  // Sign in with Google
  const signInWithGoogle = async (): Promise<void> => {
    setLoading(true);
    setAuthError(null);
    
    try {
      const provider = new GoogleAuthProvider();
      
      // Using a try/catch for development environment
      try {
        const result = await signInWithPopup(auth, provider);
        console.log("Signed in successfully:", result.user.email);
        toast.success("Signed in with Google", {
          description: "Welcome to PC Builder!"
        });
      } catch (popupError: any) {
        console.error("Popup error:", popupError);
        
        // If we're in development, show a more helpful message
        if (popupError.code === 'auth/unauthorized-domain') {
          toast.error("Development domain not authorized", {
            description: "Please add this domain to your Firebase console or use email/password auth for testing."
          });
          
          setAuthError("This domain is not authorized in Firebase. For development, please use email/password authentication or add this domain in the Firebase console.");
        } else {
          throw popupError; // Re-throw if it's not the domain error
        }
      }
    } catch (error: any) {
      console.error("Firebase Google sign in error:", error);
      const errorMessage = error.message || "Failed to sign in with Google";
      setAuthError(errorMessage);
      
      toast.error("Sign in failed", {
        description: errorMessage
      });
      
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Sign in with email/password
  const signInWithEmail = async (email: string, password: string): Promise<void> => {
    setLoading(true);
    setAuthError(null);
    
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log("Signed in successfully with email:", result.user.email);
      toast.success("Signed in successfully", {
        description: "Welcome back to PC Builder!"
      });
    } catch (error: any) {
      console.error("Firebase email sign in error:", error);
      let message = "Failed to sign in";
      
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        message = "Invalid email or password";
      } else if (error.code === 'auth/too-many-requests') {
        message = "Too many failed login attempts. Please try again later";
      }
      
      setAuthError(message);
      toast.error("Sign in failed", {
        description: message
      });
      
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Sign up with email/password
  const signUpWithEmail = async (email: string, password: string): Promise<void> => {
    setLoading(true);
    setAuthError(null);
    
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      console.log("Signed up successfully:", result.user.email);
      toast.success("Account created successfully", {
        description: "Welcome to PC Builder!"
      });
    } catch (error: any) {
      console.error("Firebase sign up error:", error);
      let message = "Failed to create account";
      
      if (error.code === 'auth/email-already-in-use') {
        message = "Email already in use";
      } else if (error.code === 'auth/weak-password') {
        message = "Password is too weak";
      } else if (error.code === 'auth/invalid-email') {
        message = "Invalid email address";
      }
      
      setAuthError(message);
      toast.error("Sign up failed", {
        description: message
      });
      
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Log out
  const logOut = async (): Promise<void> => {
    try {
      setLoading(true);
      await signOut(auth);
      console.log("Signed out successfully");
      toast.success("Signed out successfully");
    } catch (error: any) {
      console.error("Firebase sign out error:", error);
      setAuthError(error.message || "Failed to sign out");
      toast.error("Error", {
        description: "Failed to sign out"
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    currentUser,
    isAuthenticated: !!currentUser,
    loading,
    authError,
    signInWithGoogle,
    signInWithEmail,
    signUpWithEmail,
    logOut
  };

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};
