
/**
 * Helper functions to safely convert PC component types to strings
 */

/**
 * Safely converts any component to a string representation
 * Handles both simple strings and complex component objects
 */
export const componentToString = (component: any): string => {
  if (component === null || component === undefined) {
    return 'N/A';
  }
  
  if (typeof component === 'string') {
    return component;
  }
  
  // If it's an object with a name property, use that
  if (typeof component === 'object' && component.name) {
    return component.name;
  }
  
  // If it's an array (like Storage[]), join with commas
  if (Array.isArray(component)) {
    return component.map(item => componentToString(item)).join(', ');
  }
  
  // Last resort, try to convert to string
  try {
    return String(component);
  } catch (e) {
    return 'Unknown';
  }
};

/**
 * Safely converts an array or string value to an array
 * Useful for useCase and theme properties which can be either strings or arrays
 */
export const ensureArray = <T>(value: T | T[]): T[] => {
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};
