
// Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyD6Pgs1kkAQHqeS-SKD3LINOev-oplhXjM",
  authDomain: "sacred-evening-453203-d4.firebaseapp.com",
  projectId: "sacred-evening-453203-d4",
  storageBucket: "sacred-evening-453203-d4.appspot.com",
  messagingSenderId: "876219964709",
  appId: "1:876219964709:web:0jkiqoua280v2hapdsv42soj85tnjt3n"
};
