
import { supabase } from '@/utils/supabase/supabaseClient';
import { ErrorInfo } from 'react';

interface ErrorLogData {
  component: string;
  error_message: string;
  error_stack?: string;
  browser_info?: any;
  user_id?: string;
  priority?: 'low' | 'medium' | 'high';
  metadata?: any;
}

/**
 * Service for tracking and logging errors to Supabase
 */
export const ErrorTrackingService = {
  /**
   * Log an error to the Supabase database
   */
  async logError(data: ErrorLogData): Promise<boolean> {
    try {
      // Get browser information
      const browserInfo = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        platform: navigator.platform,
        viewport: {
          width: window.innerWidth,
          height: window.innerHeight
        },
        url: window.location.href,
        referrer: document.referrer || null
      };
      
      // Insert error into Supabase
      const { error } = await supabase
        .from('error_logs')
        .insert([{
          ...data,
          browser_info: browserInfo,
          timestamp: new Date().toISOString()
        }]);
      
      if (error) {
        console.error('Failed to log error to database:', error);
        return false;
      }
      
      return true;
    } catch (err) {
      // Fallback logging if the error logging itself fails
      console.error('Error in error logging system:', err);
      return false;
    }
  },
  
  /**
   * Get all errors (admin only)
   */
  async getErrors(options: { resolved?: boolean } = {}) {
    try {
      let query = supabase
        .from('error_logs')
        .select('*')
        .order('timestamp', { ascending: false });
      
      if (options.resolved !== undefined) {
        query = query.eq('resolved', options.resolved);
      }
      
      const { data, error } = await query;
      
      if (error) {
        console.error('Failed to fetch errors:', error);
        return null;
      }
      
      return data;
    } catch (err) {
      console.error('Error fetching error logs:', err);
      return null;
    }
  },
  
  /**
   * Mark an error as resolved
   */
  async markAsResolved(errorId: string, resolved: boolean = true) {
    try {
      const { error } = await supabase
        .from('error_logs')
        .update({ resolved })
        .eq('id', errorId);
      
      if (error) {
        console.error('Failed to update error status:', error);
        return false;
      }
      
      return true;
    } catch (err) {
      console.error('Error updating error log:', err);
      return false;
    }
  }
};

/**
 * Hook into window error events to automatically log unhandled errors
 */
export const setupGlobalErrorTracking = () => {
  // Capture unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    const error = event.reason;
    ErrorTrackingService.logError({
      component: 'global',
      error_message: error.message || 'Unhandled Promise Rejection',
      error_stack: error.stack,
      priority: 'high',
      metadata: { type: 'unhandledrejection' }
    });
  });
  
  // Capture regular JS errors
  window.addEventListener('error', (event) => {
    ErrorTrackingService.logError({
      component: event.filename || 'global',
      error_message: event.message || 'Unknown error',
      error_stack: event.error?.stack,
      priority: 'high',
      metadata: { 
        type: 'window.error',
        lineNo: event.lineno,
        colNo: event.colno
      }
    });
    
    return false; // Let the error propagate
  });
  
  console.log('Global error tracking initialized');
};

/**
 * Enhance the ErrorBoundary component to log errors to Supabase
 */
export const logErrorBoundaryError = (error: Error, errorInfo: ErrorInfo) => {
  ErrorTrackingService.logError({
    component: errorInfo.componentStack?.split('\n')[1]?.trim() || 'ErrorBoundary',
    error_message: error.message || 'Unknown error in component',
    error_stack: error.stack,
    priority: 'medium',
    metadata: { 
      type: 'react-error-boundary',
      componentStack: errorInfo.componentStack || ''
    }
  });
};
