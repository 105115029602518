
import { useState, useEffect } from 'react';
import { cn } from "@/lib/utils";
import LoadingState from './LoadingState';
import { ErrorState } from './ErrorState';

export interface ImageValidatorItemProps {
  src: string;
  alt: string;
  className?: string;
  fallbackSrc?: string;
  category?: string;
}

const ImageValidatorItem = ({
  src,
  alt,
  className = "",
  fallbackSrc,
  category
}: ImageValidatorItemProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(src);
  
  // Reset states when src changes
  useEffect(() => {
    if (!src || src.trim() === '') {
      setIsLoading(false);
      setHasError(true);
      return;
    }
    
    setCurrentSrc(src);
    setIsLoading(true);
    setHasError(false);
  }, [src, fallbackSrc]);

  const handleImageLoad = () => {
    setIsLoading(false);
    setHasError(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
    
    // Try to use fallback if available
    if (fallbackSrc && currentSrc !== fallbackSrc) {
      setCurrentSrc(fallbackSrc);
    }
  };

  return (
    <div className={cn(
      "relative overflow-hidden w-full h-full",
      className
    )}>
      {/* Loading state */}
      {isLoading && (
        <LoadingState 
          className="absolute inset-0 z-20" 
        />
      )}

      {/* Actual image */}
      <img
        src={currentSrc}
        alt={alt}
        className={cn(
          "w-full h-full object-cover transition-opacity duration-300",
          (!isLoading && !hasError) ? 'opacity-100' : 'opacity-0'
        )}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />

      {/* Error state */}
      {hasError && (
        <ErrorState 
          className="absolute inset-0 z-20" 
          category={category}
        />
      )}
    </div>
  );
};

export default ImageValidatorItem;
