
import { Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import Guides from './pages/Guides';
import PCBuilder from './pages/PCPartPicker';
import Purchase from './pages/Purchase';
import NotFound from './pages/NotFound';
import SavedBuilds from './pages/SavedBuilds';
import Contact from './pages/Contact';
import Account from './pages/Account';
import GuideDetail from './components/GuideDetail';
import { ThemeProvider } from './components/ThemeProvider';
import { Toaster } from './components/ui/toaster';
import { Toaster as SonnerToaster } from './components/ui/sonner';
import GlobalErrorHandler from './components/GlobalErrorHandler';
import { OptimalBuildPage } from './components/OptimalBuild';
import { FirebaseAuthProvider } from './contexts/FirebaseAuthContext';
import { UserProvider } from './contexts/UserContext';

function App() {
  return (
    <ThemeProvider>
      <GlobalErrorHandler>
        <FirebaseAuthProvider>
          <UserProvider>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/guides" element={<Guides />} />
              <Route path="/guides/:guideId" element={<GuideDetail />} />
              <Route path="/pc-builder" element={<PCBuilder />} />
              <Route path="/pc-part-picker" element={<PCBuilder />} />
              <Route path="/optimal-build" element={<OptimalBuildPage />} />
              <Route path="/saved-builds" element={<SavedBuilds />} />
              <Route path="/purchase" element={<Purchase />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/account" element={<Account />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Toaster />
            <SonnerToaster position="bottom-right" />
          </UserProvider>
        </FirebaseAuthProvider>
      </GlobalErrorHandler>
    </ThemeProvider>
  );
}

export default App;
