
import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';

interface LoadingStateProps {
  className?: string;
  text?: string;
}

const LoadingState = ({ className, text }: LoadingStateProps) => {
  return (
    <div className={cn(
      "flex flex-col items-center justify-center w-full h-full bg-muted/30",
      className
    )}>
      <Loader2 className="h-6 w-6 text-muted-foreground/80 animate-spin mb-2" />
      {text && (
        <p className="text-xs text-muted-foreground">{text}</p>
      )}
    </div>
  );
};

export default LoadingState;
