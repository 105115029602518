
import { getCategoryFallback } from '../imageFallbacks';

/**
 * Checks if a URL has a valid image extension
 * @param url The URL to check
 * @returns Boolean indicating if this appears to be an image URL
 */
export const hasValidImageExtension = (url: string): boolean => {
  // Handle empty URLs
  if (!url) return false;
  
  // Validation for images that end with standard image extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|webp|bmp|svg)($|\?)/i;
  
  // For data URLs, check if they're images
  if (url.startsWith('data:')) {
    return url.startsWith('data:image/');
  }
  
  // For URLs with extensions
  return imageExtensions.test(url);
};

/**
 * Store tracking information for edited images
 */
interface TrackedImage {
  originalUrl: string;
  currentUrl: string;
  timestamp: number;
  category?: string;
}

// Local storage key for tracking edited images
const IMAGE_TRACKER_KEY = 'image_edits_tracker';

/**
 * Tracks an edited image in local storage
 * @param id Unique identifier for the image
 * @param originalUrl Original image URL
 * @param newUrl New image URL
 * @param category Optional category for the image
 */
export const trackEditedImage = (
  id: string,
  originalUrl: string,
  newUrl: string,
  category?: string
): void => {
  try {
    // Get existing tracked images
    const trackedImages = getTrackedImages();
    
    // Update or add this image
    trackedImages[id] = {
      originalUrl,
      currentUrl: newUrl,
      timestamp: Date.now(),
      category
    };
    
    // Save back to localStorage
    localStorage.setItem(IMAGE_TRACKER_KEY, JSON.stringify(trackedImages));
    
    // Dispatch an event to notify other components
    const event = new CustomEvent('image-edited', { 
      detail: { id, currentUrl: newUrl, originalUrl, category }
    });
    window.dispatchEvent(event);
  } catch (error) {
    console.error('Failed to track edited image:', error);
  }
};

/**
 * Gets the currently active URL for an image (edited or original)
 * @param id Unique identifier for the image
 * @param originalUrl Original image URL
 * @returns The active URL (edited or original)
 */
export const getActiveImageUrl = (id: string, originalUrl: string): string => {
  try {
    const trackedImages = getTrackedImages();
    return trackedImages[id]?.currentUrl || originalUrl;
  } catch (error) {
    console.error('Failed to get active image URL:', error);
    return originalUrl;
  }
};

/**
 * Checks if an image has been edited
 * @param id Unique identifier for the image
 * @returns Boolean indicating if the image has been edited
 */
export const hasBeenEdited = (id: string): boolean => {
  try {
    const trackedImages = getTrackedImages();
    return !!trackedImages[id];
  } catch (error) {
    console.error('Failed to check if image has been edited:', error);
    return false;
  }
};

/**
 * Resets an edited image back to its original URL
 * @param id Unique identifier for the image
 */
export const resetEditedImage = (id: string): void => {
  try {
    const trackedImages = getTrackedImages();
    
    // If this image is being tracked
    if (trackedImages[id]) {
      const originalUrl = trackedImages[id].originalUrl;
      
      // Remove from tracking
      delete trackedImages[id];
      
      // Save back to localStorage
      localStorage.setItem(IMAGE_TRACKER_KEY, JSON.stringify(trackedImages));
      
      // Dispatch an event to notify other components
      const event = new CustomEvent('image-reset', { 
        detail: { id, originalUrl }
      });
      window.dispatchEvent(event);
    }
  } catch (error) {
    console.error('Failed to reset edited image:', error);
  }
};

/**
 * Gets all tracked images from localStorage
 * @returns Record of tracked images by ID
 */
const getTrackedImages = (): Record<string, TrackedImage> => {
  try {
    const tracked = localStorage.getItem(IMAGE_TRACKER_KEY);
    return tracked ? JSON.parse(tracked) : {};
  } catch (error) {
    console.error('Failed to get tracked images:', error);
    return {};
  }
};
