
import { PCComponent } from '@/utils/pcBuilder/types';

/**
 * Converts a component to a string representation
 */
export function componentToString(component: PCComponent | string | any[] | null | undefined): string {
  if (component === null || component === undefined) {
    return '';
  }
  
  if (typeof component === 'string') {
    return component;
  }
  
  // Handle array of components (like multiple storage drives)
  if (Array.isArray(component)) {
    return component.map(item => componentToString(item)).join(', ');
  }
  
  // Handle object with name property
  if (typeof component === 'object' && 'name' in component) {
    return component.name;
  }
  
  // Fallback
  return String(component);
}

/**
 * Formats a price as a currency string
 */
export function formatPrice(price: number | string | undefined): string {
  if (price === undefined) {
    return '$0.00';
  }
  
  const numPrice = typeof price === 'string' ? parseFloat(price) : price;
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(numPrice);
}

/**
 * Capitalizes the first letter of each word in a string
 */
export function capitalizeWords(str: string): string {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

/**
 * Ensures a value is converted to a string array
 */
export function ensureStringArray(value: string | string[] | null | undefined): string[] {
  if (value === null || value === undefined) {
    return [];
  }
  
  if (Array.isArray(value)) {
    return value;
  }
  
  return [value];
}

/**
 * Ensures a use case value is converted to a string array
 */
export function ensureUseCaseArray(value: string | string[] | null | undefined): string[] {
  return ensureStringArray(value);
}

/**
 * Ensures a theme value is converted to a string array
 */
export function ensureThemeArray(value: string | string[] | null | undefined): string[] {
  return ensureStringArray(value);
}

/**
 * Converts a component to lowercase string for searching
 */
export function componentToLowerCase(component: any): string {
  const str = componentToString(component);
  return str.toLowerCase();
}

/**
 * Ensures a value is converted to an array
 */
export function ensureArray<T>(value: T | T[] | null | undefined): T[] {
  if (value === null || value === undefined) {
    return [];
  }
  
  if (Array.isArray(value)) {
    return value;
  }
  
  return [value];
}

/**
 * Converts a component to an input value for forms
 */
export function componentToInputValue(component: any): string {
  return componentToString(component);
}
