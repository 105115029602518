import { Accessory } from '@/utils/pcBuilder';
import { processAccessories } from './accessoryProcessor';

const rawAccessories: any[] = [
  // MONITORS
  {
    id: 'monitor-1',
    name: 'LG UltraGear 27GP950-B 27" 4K Gaming Monitor',
    description: '27-inch 4K Nano IPS panel with 144Hz refresh rate, 1ms response time, HDMI 2.1 and NVIDIA G-Sync',
    imageUrl: 'https://m.media-amazon.com/images/I/81dAe2wXIqL._AC_SL1500_.jpg',
    price: 499,
    category: 'monitor',
    compatibleWith: ['gaming', 'streaming', 'content-creation'],
    theme: ['rgb', 'gaming']
  },
  {
    id: 'monitor-2',
    name: 'ASUS ProArt PA32UCG-K 32" 4K Monitor',
    description: '32-inch 4K Mini LED IPS panel with 10-bit color, 100% Adobe RGB, HDR1400, and Thunderbolt 4',
    imageUrl: 'https://m.media-amazon.com/images/I/81ximAvJ1jL._AC_SL1500_.jpg',
    price: 699,
    category: 'monitor',
    compatibleWith: ['content-creation', 'office-work', 'programming'],
    theme: ['minimal', 'sleek', 'luxury']
  },
  {
    id: 'monitor-3',
    name: 'Samsung Odyssey G9 49" Curved Monitor',
    description: '49-inch 5120x1440 QLED curved ultra-wide panel with 240Hz refresh rate and 1ms response time',
    imageUrl: 'https://m.media-amazon.com/images/I/61SQz8S+fEL._AC_SL1000_.jpg',
    price: 999,
    category: 'monitor',
    compatibleWith: ['gaming', 'content-creation', 'programming'],
    theme: ['rgb', 'gaming', 'luxury']
  },
  {
    id: 'monitor-4',
    name: 'Dell Alienware 34" QD-OLED Curved Gaming Monitor',
    description: '34-inch QD-OLED curved ultra-wide with 175Hz refresh rate, 0.1ms response time and AlienFX lighting',
    imageUrl: 'https://m.media-amazon.com/images/I/81YYsGwM+RL._AC_SL1500_.jpg',
    price: 899,
    category: 'monitor',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'gaming', 'cyberpunk']
  },
  {
    id: 'monitor-5',
    name: 'Apple Studio Display 27" 5K Retina',
    description: '27-inch 5K Retina display with 12MP Ultra Wide camera, studio-quality mics, and 600 nits brightness',
    imageUrl: 'https://m.media-amazon.com/images/I/61aBtPvjxuL._AC_SL1500_.jpg',
    price: 1599,
    category: 'monitor',
    compatibleWith: ['content-creation', 'office-work'],
    theme: ['minimal', 'white', 'sleek']
  },

  // KEYBOARDS
  {
    id: 'keyboard-1',
    name: 'Corsair K100 RGB Mechanical Gaming Keyboard',
    description: 'Optical-mechanical switches with per-key RGB lighting, dedicated macro keys, and wrist rest',
    imageUrl: '/lovable-uploads/56372c02-256d-4395-a0dc-eacda8b6098f.png',
    price: 179,
    category: 'keyboard',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'gaming']
  },
  {
    id: 'keyboard-2',
    name: 'Logitech MX Keys Wireless Keyboard',
    description: 'Low-profile tactile keys with multi-device connectivity, smart illumination, and USB-C charging',
    imageUrl: 'https://m.media-amazon.com/images/I/71+Nf7ySFPL._AC_SL1500_.jpg',
    price: 119,
    category: 'keyboard',
    compatibleWith: ['programming', 'office-work', 'general-use'],
    theme: ['minimal', 'sleek']
  },
  {
    id: 'keyboard-3',
    name: 'Keychron Q1 Pro Mechanical Keyboard',
    description: 'Fully customizable gasket-mounted mechanical keyboard with QMK/VIA support and wireless connectivity',
    imageUrl: 'https://m.media-amazon.com/images/I/71+TJ9KupuL._AC_SL1500_.jpg',
    price: 199,
    category: 'keyboard',
    compatibleWith: ['programming', 'gaming'],
    theme: ['minimal', 'retro', 'industrial']
  },
  {
    id: 'keyboard-4',
    name: 'Razer BlackWidow V4 Pro RGB Mechanical Keyboard',
    description: 'Command Dial, 8 macro keys, Razer Chroma RGB lighting, and Yellow mechanical switches',
    imageUrl: 'https://m.media-amazon.com/images/I/81QmeBR3oZL._AC_SL1500_.jpg',
    price: 229,
    category: 'keyboard',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'cyberpunk', 'stealth']
  },

  // MICE
  {
    id: 'mouse-1',
    name: 'Razer Basilisk V3 Gaming Mouse',
    description: 'Customizable gaming mouse with 26,000 DPI optical sensor, 11 programmable buttons, and HyperScroll wheel',
    imageUrl: '/lovable-uploads/56372c02-256d-4395-a0dc-eacda8b6098f.png',
    price: 69,
    category: 'mouse',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'gaming'],
    productUrl: 'https://www.newegg.com/razer-rz01-04000100-r3u1-basilisk-v3/p/N82E16826153324'
  },
  {
    id: 'mouse-2',
    name: 'Logitech MX Master 3S Wireless Mouse',
    description: 'High-precision 8,000 DPI sensor, MagSpeed silent scrolling, ergonomic design, and up to 70 days battery life',
    imageUrl: 'https://m.media-amazon.com/images/I/618M5ksI7LL._AC_SL1500_.jpg',
    price: 99,
    category: 'mouse',
    compatibleWith: ['programming', 'office-work', 'general-use', 'content-creation'],
    theme: ['minimal', 'sleek']
  },
  {
    id: 'mouse-3',
    name: 'Logitech G Pro X Superlight Wireless Gaming Mouse',
    description: 'Ultra-lightweight 63g design with HERO 25K sensor, 70-hour battery life, and zero additive PTFE feet',
    imageUrl: 'https://m.media-amazon.com/images/I/61MzxMYApWL._AC_SL1500_.jpg',
    price: 159,
    category: 'mouse',
    compatibleWith: ['gaming'],
    theme: ['minimal', 'stealth', 'white']
  },
  {
    id: 'mouse-4',
    name: 'Glorious Model O Wireless Gaming Mouse',
    description: 'Ultra-lightweight 69g honeycomb design with RGB lighting, 19,000 DPI sensor, and 71-hour battery life',
    imageUrl: 'https://m.media-amazon.com/images/I/61jCH1CJMdL._AC_SL1500_.jpg',
    price: 89,
    category: 'mouse',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'gaming']
  },

  // HEADSETS & AUDIO
  {
    id: 'headset-1',
    name: 'SteelSeries Arctis Nova Pro Wireless Headset',
    description: 'Dual wireless connectivity, active noise cancellation, hot-swappable batteries, and premium audio drivers',
    imageUrl: '/lovable-uploads/2fd16431-09c8-4464-a853-10b328f93ec4.png',
    price: 349,
    category: 'headset',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['stealth', 'rgb', 'gaming']
  },
  {
    id: 'headset-2',
    name: 'Sony WH-1000XM5 Wireless Headphones',
    description: 'Industry-leading noise cancellation, 30-hour battery life, and high-resolution audio with LDAC support',
    imageUrl: 'https://m.media-amazon.com/images/I/61+btxzpfDL._AC_SL1500_.jpg',
    price: 349,
    category: 'headset',
    compatibleWith: ['content-creation', 'general-use'],
    theme: ['minimal', 'sleek']
  },
  {
    id: 'speaker-1',
    name: 'Logitech G560 LIGHTSYNC Gaming Speakers',
    description: 'DTS:X virtual surround sound, integrated game-driven RGB lighting, and powerful 240W peak output',
    imageUrl: 'https://m.media-amazon.com/images/I/71CRGJJaeMW._AC_SL1500_.jpg',
    price: 199,
    category: 'speaker',
    compatibleWith: ['content-creation', 'general-use', 'gaming'],
    theme: ['rgb', 'gaming']
  },
  {
    id: 'speaker-2',
    name: 'Audioengine A5+ Wireless Powered Speakers',
    description: 'High-fidelity bookshelf speakers with built-in DAC, Bluetooth aptX HD, and 150W peak power output',
    imageUrl: 'https://m.media-amazon.com/images/I/71u-oKl27wL._AC_SL1500_.jpg',
    price: 499,
    category: 'speaker',
    compatibleWith: ['content-creation', 'general-use'],
    theme: ['minimal', 'sleek', 'luxury']
  },

  // STREAMING GEAR
  {
    id: 'webcam-1',
    name: 'Logitech StreamCam 4K Webcam',
    description: 'Premium 4K webcam with advanced facial tracking, auto-focus, smart exposure, and USB-C connectivity',
    imageUrl: 'https://m.media-amazon.com/images/I/61R1ZKwFU0L._AC_SL1500_.jpg',
    price: 169,
    category: 'webcam',
    compatibleWith: ['streaming', 'content-creation'],
    theme: ['minimal', 'sleek']
  },
  {
    id: 'webcam-2',
    name: 'Elgato Facecam Pro 4K60 Webcam',
    description: '4K60 webcam with Sony STARVIS sensor, f/2.0 prime lens, and advanced Camera Hub software',
    imageUrl: 'https://m.media-amazon.com/images/I/51LMgXDvWcL._AC_SL1044_.jpg',
    price: 299,
    category: 'webcam',
    compatibleWith: ['streaming', 'content-creation'],
    theme: ['stealth', 'minimal']
  },
  {
    id: 'microphone-1',
    name: 'Blue Yeti X Professional USB Microphone',
    description: 'Four-capsule condenser array, voice metering LED display, Blue VO!CE presets, and multi-function smart knob',
    imageUrl: 'https://m.media-amazon.com/images/I/71AnC9mUeaL._AC_SL1500_.jpg',
    price: 149,
    category: 'microphone',
    compatibleWith: ['streaming', 'content-creation'],
    theme: ['rgb', 'stealth']
  },
  {
    id: 'microphone-2',
    name: 'Shure SM7B Vocal Dynamic Microphone',
    description: 'Professional XLR vocal microphone with wide-range frequency response, electromagnetic shielding, and air suspension',
    imageUrl: 'https://m.media-amazon.com/images/I/71TgDCv9NeL._AC_SL1500_.jpg',
    price: 399,
    category: 'microphone',
    compatibleWith: ['streaming', 'content-creation'],
    theme: ['minimal', 'stealth', 'industrial']
  },
  {
    id: 'stream-deck-1',
    name: 'Elgato Stream Deck MK.2',
    description: '15 customizable LCD keys, interchangeable faceplates, adjustable stand, and Stream Deck software',
    imageUrl: 'https://m.media-amazon.com/images/I/71U6RlyxvsL._AC_SL1500_.jpg',
    price: 149,
    category: 'accessory',
    compatibleWith: ['streaming', 'content-creation'],
    theme: ['rgb', 'gaming', 'cyberpunk']
  },

  // FURNITURE
  {
    id: 'chair-1',
    name: 'Secretlab TITAN Evo 2022 Gaming Chair',
    description: 'Premium magnetic memory foam head pillow, 4-way L-ADAPT lumbar support, and reclinable to 165 degrees',
    imageUrl: '/lovable-uploads/f099c66e-d0b1-4867-9dfb-6a8b5f7bc982.png',
    price: 549,
    category: 'chair',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['gaming', 'stealth', 'cyberpunk']
  },
  {
    id: 'chair-2',
    name: 'Herman Miller Aeron Ergonomic Chair',
    description: 'PostureFit SL adjustable support, 8Z Pellicle suspension, and fully adjustable arms, seat, and back',
    imageUrl: 'https://m.media-amazon.com/images/I/71cFjYeJLuL._AC_SL1500_.jpg',
    price: 1395,
    category: 'chair',
    compatibleWith: ['programming', 'office-work', 'content-creation'],
    theme: ['minimal', 'sleek', 'luxury']
  },
  {
    id: 'chair-3',
    name: 'IKEA MARKUS Office Chair',
    description: 'Mesh back for breathability, built-in lumbar support, and adjustable height and tilt with a 10-year warranty',
    imageUrl: 'https://www.ikea.com/us/en/images/products/markus-office-chair-vissle-dark-gray__0724714_pe734597_s5.jpg',
    price: 229,
    category: 'chair',
    compatibleWith: ['programming', 'office-work', 'general-use'],
    theme: ['minimal', 'industrial']
  },
  {
    id: 'desk-1',
    name: 'Fully Jarvis Standing Desk',
    description: 'Electric height-adjustable desk with programmable memory presets, supports up to 350 lbs',
    imageUrl: 'https://m.media-amazon.com/images/I/61bGBT6ZJuL._AC_SL1500_.jpg',
    price: 599,
    category: 'desk',
    compatibleWith: ['gaming', 'programming', 'office-work', 'content-creation'],
    theme: ['minimal', 'sleek']
  },
  {
    id: 'desk-2',
    name: 'IKEA BEKANT Corner Desk',
    description: 'L-shaped corner desk with cable management net, adjustable feet, and 10-year warranty',
    imageUrl: 'https://www.ikea.com/us/en/images/products/bekant-corner-desk-left-white-stained-oak-veneer-white__0845299_pe612887_s5.jpg',
    price: 299,
    category: 'desk',
    compatibleWith: ['programming', 'office-work', 'general-use'],
    theme: ['minimal', 'white']
  },

  // ACCESSORIES
  {
    id: 'mouse-pad-1',
    name: 'Corsair MM700 RGB Extended Mouse Pad',
    description: '36.6" x 15.8" extended mouse pad with 360° RGB lighting, micro-weave cloth surface, and USB pass-through',
    imageUrl: 'https://c1.neweggimages.com/ProductImageCompressAll1280/26-988-110-V01.jpg',
    price: 59,
    category: 'accessory',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'gaming', 'cyberpunk'],
    productUrl: 'https://www.newegg.com/corsair-ch-9417070-ww-mm700-rgb/p/N82E16826988110'
  },
  {
    id: 'headset-stand-1',
    name: 'Razer Base Station V2 Chroma Headset Stand',
    description: 'Headset stand with RGB lighting, 3.5mm audio output, and 2-port USB 3.0 hub',
    imageUrl: 'https://m.media-amazon.com/images/I/619gYMHLUCL._AC_SL1500_.jpg',
    price: 69,
    category: 'accessory',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'gaming', 'cyberpunk']
  },
  {
    id: 'cable-mgmt-1',
    name: 'OMNIMOUNT Cable Management System',
    description: 'Comprehensive cable management solution with under-desk tray, clips, and ties for a clean setup',
    imageUrl: 'https://m.media-amazon.com/images/I/71RGU08TlmL._AC_SL1500_.jpg',
    price: 39,
    category: 'accessory',
    compatibleWith: ['general-use', 'programming', 'office-work', 'content-creation', 'gaming', 'streaming'],
    theme: ['minimal', 'sleek', 'industrial']
  },
  {
    id: 'light-1',
    name: 'Elgato Key Light Air',
    description: 'Professional LED panel with 1400 lumens, color temperature adjustability, and app control',
    imageUrl: 'https://m.media-amazon.com/images/I/613hLokBuOL._AC_SL1000_.jpg',
    price: 129,
    category: 'lighting',
    compatibleWith: ['streaming', 'content-creation'],
    theme: ['minimal', 'sleek']
  },
  {
    id: 'light-2',
    name: 'Govee RGBIC LED Strip Lights',
    description: '16.4ft addressable RGB LED strip with multiple color zones, music sync, and smart app control',
    imageUrl: 'https://m.media-amazon.com/images/I/716AHo89stL._AC_SL1500_.jpg',
    price: 39,
    category: 'lighting',
    compatibleWith: ['gaming', 'streaming'],
    theme: ['rgb', 'cyberpunk']
  }
];

const accessories: Accessory[] = processAccessories(rawAccessories);

export default accessories;
