
import { PCBuild } from '@/utils/pcBuilder';
import { getDefaultPerformance } from '@/utils/pcBuilder/defaultPerformance';

// Premium gaming PC builds
const gamingBuilds: PCBuild[] = [
  {
    id: 'premium-performance',
    name: 'High-End Gaming PC',
    description: 'Premium gaming rig for 4K gaming and content creation.',
    imageUrl: '/lovable-uploads/9c5f6cf7-05f4-4aa5-b8ed-3a6f1e4ade4a.png',
    price: 2299,
    components: {
      cpu: 'Intel Core i7-14700K',
      motherboard: 'MSI MPG Z790 Gaming Plus WiFi',
      gpu: 'Nvidia RTX 4080 Super 16GB',
      ram: '32GB (2x16GB) DDR5-6000 CL30',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Corsair 5000D Airflow',
      psu: '1000W 80+ Gold',
      cooling: 'NZXT Kraken X63 RGB'
    },
    useCase: ['gaming', 'streaming', 'content-creation'],
    theme: ['rgb', 'luxury'],
    isValidated: true,
    performance: getDefaultPerformance(2299)
  },
  {
    id: 'dream-machine',
    name: 'Ultimate Gaming PC',
    description: 'No-compromise gaming and productivity powerhouse for enthusiasts.',
    imageUrl: '/lovable-uploads/6aad9443-53d6-46b7-a3e0-0d95ef3fa3f4.png',
    price: 3999,
    components: {
      cpu: 'Intel Core i9-14900K',
      motherboard: 'ASUS ROG Maximus Z790 Hero',
      gpu: 'Nvidia RTX 4090 24GB',
      ram: '64GB (2x32GB) DDR5-6400 CL32',
      storage: '4TB NVMe Gen4 SSD',
      case: 'Lian Li O11 Dynamic EVO',
      psu: '1600W 80+ Titanium',
      cooling: 'Corsair iCUE H150i Elite LCD'
    },
    useCase: ['gaming', 'streaming', 'content-creation', 'programming'],
    theme: ['rgb', 'luxury'],
    isValidated: true,
    performance: getDefaultPerformance(3999)
  },
  {
    id: 'amd-gaming-rig',
    name: 'AMD Gaming Rig',
    description: 'Full AMD build optimized for gaming with great price-to-performance.',
    imageUrl: '/lovable-uploads/aa1614bc-6e14-42e3-b6e8-4fc2fd8841fb.png',
    price: 1299,
    components: {
      cpu: 'AMD Ryzen 5 7600X',
      motherboard: 'MSI MPG B650 GAMING EDGE WIFI',
      gpu: 'AMD Radeon RX 7800 XT',
      ram: '32GB (2x16GB) DDR5-6000 CL30',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Phanteks Eclipse G360A',
      psu: '750W 80+ Gold',
      cooling: 'Deepcool AK400'
    },
    useCase: ['gaming', 'general-use'],
    theme: ['rgb', 'gaming'],
    isValidated: true,
    performance: getDefaultPerformance(1299)
  },
  {
    id: 'retro-gamer',
    name: 'Retro Gaming PC',
    description: 'Modern PC with retro aesthetics, perfect for emulation and modern titles.',
    imageUrl: '/lovable-uploads/2fd16431-09c8-4464-a853-10b328f93ec4.png',
    price: 1299,
    components: {
      cpu: 'AMD Ryzen 7 5800X3D',
      motherboard: 'MSI MAG B550 TOMAHAWK',
      gpu: 'Nvidia RTX 4060 Ti 8GB',
      ram: '32GB (2x16GB) DDR4-3600',
      storage: '2TB NVMe SSD',
      case: 'NZXT H5 Flow (Customized)',
      psu: '750W 80+ Gold',
      cooling: 'Cooler Master Hyper 212 RGB'
    },
    useCase: ['gaming', 'general-use'],
    theme: ['retro', 'rgb'],
    isValidated: true,
    performance: getDefaultPerformance(1299)
  },
  {
    id: 'gaming-stealth',
    name: 'Stealth Gaming Rig',
    description: 'Powerful gaming PC with understated design and no RGB lighting.',
    imageUrl: '/lovable-uploads/e2e6587d-c8a0-4c5b-a753-1c9ceaa67df7.png',
    price: 1899,
    components: {
      cpu: 'Intel Core i7-14700K',
      motherboard: 'MSI MAG Z790 TOMAHAWK WiFi',
      gpu: 'Nvidia RTX 4070 Ti Super 16GB',
      ram: '32GB (2x16GB) DDR5-6000',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Fractal Design North',
      psu: '850W 80+ Gold',
      cooling: 'be quiet! Dark Rock Pro 5'
    },
    useCase: ['gaming', 'general-use'],
    theme: ['stealth', 'minimal'],
    isValidated: true,
    performance: getDefaultPerformance(1899)
  },
  {
    id: 'sleek-gaming',
    name: 'Sleek Gaming PC',
    description: 'Elegant gaming rig with clean aesthetics and high performance.',
    imageUrl: '/lovable-uploads/05f8be3d-1657-4cb4-8a59-05dd4e0c01e5.png',
    price: 1649,
    components: {
      cpu: 'AMD Ryzen 7 7800X3D',
      motherboard: 'ASUS ROG Strix B650E-F Gaming WiFi',
      gpu: 'Nvidia RTX 4070 Super 12GB',
      ram: '32GB (2x16GB) DDR5-6000',
      storage: '2TB NVMe Gen4 SSD',
      case: 'NZXT H5 Flow',
      psu: '750W 80+ Gold',
      cooling: 'NZXT Kraken X53'
    },
    useCase: ['gaming', 'general-use'],
    theme: ['sleek', 'minimal'],
    isValidated: true,
    performance: getDefaultPerformance(1649)
  },
  {
    id: 'luxury-gaming',
    name: 'Luxury Gaming PC',
    description: 'Premium gaming setup with high-end components and elegant design.',
    imageUrl: '/lovable-uploads/9c5f6cf7-05f4-4aa5-b8ed-3a6f1e4ade4a.png',
    price: 2799,
    components: {
      cpu: 'Intel Core i9-14900KS',
      motherboard: 'MSI MEG Z790 ACE',
      gpu: 'Nvidia RTX 4080 Super 16GB',
      ram: '64GB (2x32GB) DDR5-7200',
      storage: '4TB NVMe Gen4 SSD',
      case: 'Lian Li O11 Dynamic EVO',
      psu: '1200W 80+ Platinum',
      cooling: 'EKWB AIO Elite 360'
    },
    useCase: ['gaming', 'content-creation'],
    theme: ['luxury', 'sleek'],
    isValidated: true,
    performance: getDefaultPerformance(2799)
  },
  {
    id: 'industrial-gaming',
    name: 'Industrial Gaming PC',
    description: 'Rugged gaming build with industrial aesthetics and reliable components.',
    imageUrl: '/lovable-uploads/56372c02-256d-4395-a0dc-eacda8b6098f.png',
    price: 1599,
    components: {
      cpu: 'AMD Ryzen 7 7700X',
      motherboard: 'ASRock B650 PG Lightning',
      gpu: 'AMD Radeon RX 7700 XT',
      ram: '32GB (2x16GB) DDR5-5600',
      storage: '1TB NVMe Gen4 SSD + 2TB HDD',
      case: 'Cooler Master HAF 700',
      psu: '850W 80+ Gold',
      cooling: 'DeepCool AK620'
    },
    useCase: ['gaming', 'general-use'],
    theme: ['industrial', 'rgb'],
    isValidated: true,
    performance: getDefaultPerformance(1599)
  },
  {
    id: 'gaming-white',
    name: 'Arctic Gaming PC',
    description: 'All-white gaming build with high performance and clean aesthetics.',
    imageUrl: '/lovable-uploads/f2d1cfde-8590-403f-a1de-faf52a742a86.png',
    price: 1749,
    components: {
      cpu: 'Intel Core i5-14600K',
      motherboard: 'ASUS PRIME Z790-A',
      gpu: 'Nvidia RTX 4070 Super 12GB (White)',
      ram: '32GB (2x16GB) DDR5-6000 (White)',
      storage: '2TB NVMe Gen4 SSD',
      case: 'NZXT H7 Flow (White)',
      psu: '850W 80+ Gold',
      cooling: 'NZXT Kraken X63 RGB (White)'
    },
    useCase: ['gaming', 'streaming'],
    theme: ['white', 'minimal'],
    isValidated: true,
    performance: getDefaultPerformance(1749)
  }
];

export default gamingBuilds;
