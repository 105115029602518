
import { ImageIcon } from "lucide-react";
import { cn } from "@/lib/utils";

interface ErrorStateProps {
  errorType?: string;
  className?: string;
  customMessage?: string;
  category?: string;
}

export const ErrorState = ({ errorType, className, customMessage, category }: ErrorStateProps) => {
  // Determine error message based on the category
  const getMessage = () => {
    if (customMessage) return customMessage;
    
    if (category) {
      return `${category} image unavailable`;
    }
    
    return "Image unavailable";
  };

  return (
    <div className={cn(
      "w-full h-full flex flex-col items-center justify-center bg-muted/50",
      className
    )}>
      <ImageIcon className="w-10 h-10 text-muted-foreground/60 mb-2" />
      <p className="text-sm text-muted-foreground">
        {getMessage()}
      </p>
    </div>
  );
};
