
import { useFirebaseAuth } from '@/contexts/FirebaseAuthContext';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { User } from 'lucide-react';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';

const UserCard = () => {
  const { currentUser } = useFirebaseAuth();

  // Get user initials for the avatar fallback
  const getUserInitials = () => {
    if (!currentUser?.displayName) return 'U';
    return currentUser.displayName
      .split(' ')
      .map(name => name[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Card className="w-full shadow-lg">
      <CardHeader>
        <div className="flex items-center gap-4">
          <Avatar>
            {currentUser?.photoURL ? (
              <AvatarImage src={currentUser.photoURL} alt={currentUser.displayName || 'User'} />
            ) : (
              <AvatarFallback>{getUserInitials()}</AvatarFallback>
            )}
          </Avatar>
          <div>
            <CardTitle>{currentUser?.displayName || 'User'}</CardTitle>
            <CardDescription>{currentUser?.email || 'No email available'}</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-sm text-muted-foreground">
          You're signed in with Google. View your full account details in the Account tab.
        </p>
      </CardContent>
    </Card>
  );
};

export default UserCard;
