
import React, { ErrorInfo, ReactNode, Component } from 'react';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class GlobalErrorHandler extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    console.log("GlobalErrorHandler initialized");
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): State {
    console.error('Global error caught in getDerivedStateFromError:', error);
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Global error caught in componentDidCatch:', error);
    console.error('Error details:', errorInfo);
    this.setState({ errorInfo });
    
    // Try to log to server if needed
    try {
      if (typeof fetch === 'function') {
        fetch('/api/log-error', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            error: error.toString(),
            stack: error.stack,
            componentStack: errorInfo.componentStack,
            url: window.location.href,
            timestamp: new Date().toISOString()
          }),
        }).catch(e => console.error('Failed to send error log:', e));
      }
    } catch (e) {
      console.error('Error during error reporting:', e);
    }
  }
  
  handleReset = (): void => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  }
  
  handleReload = (): void => {
    window.location.reload();
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Use custom fallback if provided
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      // Default fallback with inline styles for maximum reliability
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          padding: '20px',
          backgroundColor: 'white',
          color: 'black',
        }}>
          <div style={{
            maxWidth: '600px',
            width: '100%',
            padding: '24px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            backgroundColor: '#f8f8f8',
          }}>
            <h1 style={{ color: 'red', marginBottom: '20px', fontSize: '24px' }}>Something went wrong</h1>
            <div style={{
              maxWidth: '100%',
              padding: '16px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#f8f8f8',
              overflowX: 'auto',
              marginBottom: '20px',
            }}>
              <pre style={{ 
                whiteSpace: 'pre-wrap', 
                margin: 0,
                fontFamily: 'monospace',
                fontSize: '14px'
              }}>
                {this.state.error?.message || 'Unknown error'}
              </pre>
              {this.state.errorInfo && (
                <details style={{ marginTop: '10px' }}>
                  <summary style={{ cursor: 'pointer', marginBottom: '10px' }}>Component Stack</summary>
                  <pre style={{ 
                    whiteSpace: 'pre-wrap',
                    fontSize: '12px',
                    color: '#555'
                  }}>
                    {this.state.errorInfo.componentStack}
                  </pre>
                </details>
              )}
            </div>
            <div style={{ display: 'flex', gap: '12px' }}>
              <button
                onClick={this.handleReset}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#f0f0f0',
                  color: '#333',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Try Again
              </button>
              <button
                onClick={this.handleReload}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#4a90e2',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Refresh Page
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default GlobalErrorHandler;
