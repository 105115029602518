
import React from 'react';
import { Button } from "@/components/ui/button"
import { Link } from 'react-router-dom';

interface HeroProps {
  onGetStarted?: () => void;
}

const Hero = ({ onGetStarted }: HeroProps) => {
  return (
    <section className="relative py-20 md:py-32 overflow-hidden">
      {/* Gaming PC background */}
      <div className="absolute inset-0 bg-black/50 z-10"></div>
      <div className="absolute inset-0 bg-cover bg-center" 
           style={{ backgroundImage: "url('https://images.unsplash.com/photo-1587202372775-e229f172b9d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80')" }}>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/30 pointer-events-none z-10"></div>
      
      <div className="container mx-auto px-4 relative z-20">
        <div className="text-center max-w-4xl mx-auto mb-12">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 text-white">
            PC4U: Your Perfect PC
          </h1>
          <p className="text-lg md:text-xl mb-10 text-gray-100 max-w-2xl mx-auto">
            Build a custom PC that fits your needs and budget with our intuitive PC builder tool.
          </p>
          <div className="flex justify-center">
            <Link to="/pc-part-picker">
              <Button size="lg" className="font-medium px-8 py-8 text-lg bg-blue-600 hover:bg-blue-700 rounded-lg shadow-lg">
                PC4U: Find My PC
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
