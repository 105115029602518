
import { useState } from 'react';
import { useFirebaseAuth } from '@/contexts/FirebaseAuthContext';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { User, AlertCircle } from 'lucide-react';
import FirebaseSignInButton from './FirebaseSignInButton';
import UserCard from './UserCard';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Separator } from '@/components/ui/separator';
import EmailPasswordForm from './EmailPasswordForm';

const FirebaseAuth = () => {
  const { currentUser, isAuthenticated, signInWithGoogle, loading: authLoading } = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      console.log('Initiating Firebase Google sign-in');
      await signInWithGoogle();
    } catch (error: any) {
      console.error("Firebase sign-in error:", error);
      setError(error.message || "Failed to sign in with Google");
    } finally {
      setIsLoading(false);
    }
  };

  // If already authenticated, show user card
  if (isAuthenticated && currentUser) {
    return <UserCard />;
  }

  return (
    <Card className="w-full max-w-md mx-auto shadow-lg">
      <CardHeader className="text-center">
        <CardTitle className="flex items-center justify-center gap-2">
          <User className="h-5 w-5" />
          User Account
        </CardTitle>
        <CardDescription>Sign in with Google or email to save your PC builds</CardDescription>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="w-full mt-4">
          <FirebaseSignInButton 
            onClick={handleGoogleSignIn} 
            isLoading={isLoading || authLoading} 
            disabled={false}
          />
        </div>
        
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-muted-foreground">Or continue with</span>
          </div>
        </div>
        
        <EmailPasswordForm />
        
        <p className="text-xs text-center text-muted-foreground mt-4">
          By signing in, you agree to our Terms of Service and Privacy Policy
        </p>
      </CardContent>
    </Card>
  );
};

export default FirebaseAuth;
