
import { ComponentSpec, ComponentType } from './types';

// Expanded mock data for PC parts with many more options
const mockComponents: Partial<Record<ComponentType, ComponentSpec[]>> = {
  cpu: [
    {
      id: 'cpu1',
      name: 'Intel Core i9-14900K',
      brand: 'Intel',
      price: 599.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 24,
        threads: 32,
        speed: '5.6 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu2',
      name: 'AMD Ryzen 9 7950X',
      brand: 'AMD',
      price: 549.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 16,
        threads: 32,
        speed: '5.7 GHz',
        socket: 'AM5'
      }
    },
    {
      id: 'cpu3',
      name: 'Intel Core i7-14700K',
      brand: 'Intel',
      price: 399.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 20,
        threads: 28,
        speed: '5.5 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu4',
      name: 'AMD Ryzen 7 7800X3D',
      brand: 'AMD',
      price: 449.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 8,
        threads: 16,
        speed: '5.0 GHz',
        socket: 'AM5'
      }
    },
    {
      id: 'cpu5',
      name: 'Intel Core i5-14600K',
      brand: 'Intel',
      price: 319.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 14,
        threads: 20,
        speed: '5.3 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu6',
      name: 'AMD Ryzen 5 7600X',
      brand: 'AMD',
      price: 249.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 6,
        threads: 12,
        speed: '5.3 GHz',
        socket: 'AM5'
      }
    },
    {
      id: 'cpu7',
      name: 'Intel Core i9-13900K',
      brand: 'Intel',
      price: 529.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 24,
        threads: 32,
        speed: '5.4 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu8',
      name: 'AMD Ryzen 9 7900X',
      brand: 'AMD',
      price: 399.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 12,
        threads: 24,
        speed: '5.6 GHz',
        socket: 'AM5'
      }
    },
    {
      id: 'cpu9',
      name: 'Intel Core i3-14100F',
      brand: 'Intel',
      price: 124.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 4,
        threads: 8,
        speed: '4.9 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu10',
      name: 'AMD Ryzen 7 5800X3D',
      brand: 'AMD',
      price: 329.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 8,
        threads: 16,
        speed: '4.5 GHz',
        socket: 'AM4'
      }
    },
    {
      id: 'cpu11',
      name: 'Intel Core i5-13600K',
      brand: 'Intel',
      price: 329.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 14,
        threads: 20,
        speed: '5.1 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu12',
      name: 'AMD Ryzen 5 7600',
      brand: 'AMD',
      price: 229.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 6,
        threads: 12,
        speed: '5.1 GHz',
        socket: 'AM5'
      }
    },
    {
      id: 'cpu13',
      name: 'Intel Core i7-13700F',
      brand: 'Intel',
      price: 349.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 16,
        threads: 24,
        speed: '5.2 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu14',
      name: 'AMD Ryzen 9 7900',
      brand: 'AMD',
      price: 429.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 12,
        threads: 24,
        speed: '5.4 GHz',
        socket: 'AM5'
      }
    },
    {
      id: 'cpu15',
      name: 'Intel Core i3-13100',
      brand: 'Intel',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 4,
        threads: 8,
        speed: '4.5 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu16',
      name: 'AMD Ryzen 7 5700X',
      brand: 'AMD',
      price: 269.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 8,
        threads: 16,
        speed: '4.6 GHz',
        socket: 'AM4'
      }
    },
    {
      id: 'cpu17',
      name: 'Intel Core i9-12900KS',
      brand: 'Intel',
      price: 499.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 16,
        threads: 24,
        speed: '5.5 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu18',
      name: 'AMD Ryzen 5 5600X',
      brand: 'AMD',
      price: 199.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 6,
        threads: 12,
        speed: '4.6 GHz',
        socket: 'AM4'
      }
    },
    {
      id: 'cpu19',
      name: 'Intel Core i7-12700K',
      brand: 'Intel',
      price: 349.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 12,
        threads: 20,
        speed: '5.0 GHz',
        socket: 'LGA1700'
      }
    },
    {
      id: 'cpu20',
      name: 'AMD Ryzen 9 5950X',
      brand: 'AMD',
      price: 499.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        cores: 16,
        threads: 32,
        speed: '4.9 GHz',
        socket: 'AM4'
      }
    }
  ],
  motherboard: [
    {
      id: 'mb1',
      name: 'ASUS ROG Maximus Z790 Hero',
      brand: 'ASUS',
      price: 629.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'Z790',
        socket: 'LGA1700',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb2',
      name: 'MSI MPG X670E Carbon WiFi',
      brand: 'MSI',
      price: 479.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'X670E',
        socket: 'AM5',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb3',
      name: 'Gigabyte Z790 AORUS Elite AX',
      brand: 'Gigabyte',
      price: 289.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'Z790',
        socket: 'LGA1700',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb4',
      name: 'ASUS ROG STRIX B650E-F Gaming WiFi',
      brand: 'ASUS',
      price: 269.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B650E',
        socket: 'AM5',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb5',
      name: 'ASRock B760M Pro RS/D4',
      brand: 'ASRock',
      price: 119.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B760',
        socket: 'LGA1700',
        formFactor: 'Micro ATX',
        memorySlots: 4,
        maxMemory: '128GB DDR4'
      }
    },
    {
      id: 'mb6',
      name: 'MSI MAG B650 TOMAHAWK WIFI',
      brand: 'MSI',
      price: 239.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B650',
        socket: 'AM5',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb7',
      name: 'ASUS ProArt X670E-CREATOR WIFI',
      brand: 'ASUS',
      price: 499.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'X670E',
        socket: 'AM5',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb8',
      name: 'Gigabyte B650 AORUS ELITE AX',
      brand: 'Gigabyte',
      price: 229.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B650',
        socket: 'AM5',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb9',
      name: 'ASRock Z790 Steel Legend WiFi',
      brand: 'ASRock',
      price: 249.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'Z790',
        socket: 'LGA1700',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb10',
      name: 'ASUS TUF GAMING B650-PLUS',
      brand: 'ASUS',
      price: 199.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B650',
        socket: 'AM5',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb11',
      name: 'MSI PRO Z690-A',
      brand: 'MSI',
      price: 179.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'Z690',
        socket: 'LGA1700',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb12',
      name: 'Gigabyte X670 GAMING X AX',
      brand: 'Gigabyte',
      price: 259.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'X670',
        socket: 'AM5',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb13',
      name: 'ASUS ROG STRIX Z690-I GAMING WIFI',
      brand: 'ASUS',
      price: 399.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'Z690',
        socket: 'LGA1700',
        formFactor: 'Mini ITX',
        memorySlots: 2,
        maxMemory: '64GB'
      }
    },
    {
      id: 'mb14',
      name: 'MSI MEG X670E ACE',
      brand: 'MSI',
      price: 699.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'X670E',
        socket: 'AM5',
        formFactor: 'E-ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb15',
      name: 'ASRock B650M PG Riptide WiFi',
      brand: 'ASRock',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B650',
        socket: 'AM5',
        formFactor: 'Micro ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb16',
      name: 'Gigabyte B760 AORUS ELITE AX',
      brand: 'Gigabyte',
      price: 209.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B760',
        socket: 'LGA1700',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb17',
      name: 'ASUS Prime B550M-A WiFi',
      brand: 'ASUS',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B550',
        socket: 'AM4',
        formFactor: 'Micro ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb18',
      name: 'MSI MPG B550 GAMING EDGE WIFI',
      brand: 'MSI',
      price: 189.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B550',
        socket: 'AM4',
        formFactor: 'ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    },
    {
      id: 'mb19',
      name: 'ASUS ROG STRIX B550-I GAMING',
      brand: 'ASUS',
      price: 229.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'B550',
        socket: 'AM4',
        formFactor: 'Mini ITX',
        memorySlots: 2,
        maxMemory: '64GB'
      }
    },
    {
      id: 'mb20',
      name: 'Gigabyte Z790 AORUS MASTER',
      brand: 'Gigabyte',
      price: 499.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        chipset: 'Z790',
        socket: 'LGA1700',
        formFactor: 'E-ATX',
        memorySlots: 4,
        maxMemory: '128GB'
      }
    }
  ],
  gpu: [
    {
      id: 'gpu1',
      name: 'NVIDIA GeForce RTX 4090',
      brand: 'NVIDIA',
      price: 1599.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '24GB GDDR6X',
        coreClock: '2.23 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu2',
      name: 'AMD Radeon RX 7900 XTX',
      brand: 'AMD',
      price: 999.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '24GB GDDR6',
        coreClock: '2.3 GHz',
        ports: 'HDMI 2.1, 2x DisplayPort 2.1'
      }
    },
    {
      id: 'gpu3',
      name: 'NVIDIA GeForce RTX 4080 Super',
      brand: 'NVIDIA',
      price: 999.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '16GB GDDR6X',
        coreClock: '2.21 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu4',
      name: 'AMD Radeon RX 7800 XT',
      brand: 'AMD',
      price: 499.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '16GB GDDR6',
        coreClock: '2.12 GHz',
        ports: 'HDMI 2.1, 2x DisplayPort 2.1'
      }
    },
    {
      id: 'gpu5',
      name: 'NVIDIA GeForce RTX 4070 Ti Super',
      brand: 'NVIDIA',
      price: 799.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '16GB GDDR6X',
        coreClock: '2.34 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu6',
      name: 'AMD Radeon RX 7700 XT',
      brand: 'AMD',
      price: 449.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '12GB GDDR6',
        coreClock: '2.17 GHz',
        ports: 'HDMI 2.1, 2x DisplayPort 2.1'
      }
    },
    {
      id: 'gpu7',
      name: 'NVIDIA GeForce RTX 4060 Ti',
      brand: 'NVIDIA',
      price: 399.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '8GB GDDR6',
        coreClock: '2.31 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu8',
      name: 'Intel Arc A770 16GB',
      brand: 'Intel',
      price: 349.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '16GB GDDR6',
        coreClock: '2.1 GHz',
        ports: 'HDMI 2.0, 3x DisplayPort 2.0'
      }
    },
    {
      id: 'gpu9',
      name: 'NVIDIA GeForce RTX 3060',
      brand: 'NVIDIA',
      price: 299.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '12GB GDDR6',
        coreClock: '1.78 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu10',
      name: 'AMD Radeon RX 6600 XT',
      brand: 'AMD',
      price: 349.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '8GB GDDR6',
        coreClock: '2.36 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4'
      }
    },
    {
      id: 'gpu11',
      name: 'NVIDIA GeForce RTX 3070 Ti',
      brand: 'NVIDIA',
      price: 599.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '8GB GDDR6X',
        coreClock: '1.77 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu12',
      name: 'AMD Radeon RX 6700 XT',
      brand: 'AMD',
      price: 449.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '12GB GDDR6',
        coreClock: '2.42 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4'
      }
    },
    {
      id: 'gpu13',
      name: 'NVIDIA GeForce RTX 3060',
      brand: 'NVIDIA',
      price: 329.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '12GB GDDR6',
        coreClock: '1.78 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu14',
      name: 'Intel Arc A750',
      brand: 'Intel',
      price: 289.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '8GB GDDR6',
        coreClock: '2.05 GHz',
        ports: 'HDMI 2.0, 3x DisplayPort 2.0'
      }
    },
    {
      id: 'gpu15',
      name: 'AMD Radeon RX 6950 XT',
      brand: 'AMD',
      price: 699.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '16GB GDDR6',
        coreClock: '2.31 GHz',
        ports: 'HDMI 2.1, 2x DisplayPort 1.4'
      }
    },
    {
      id: 'gpu16',
      name: 'NVIDIA GeForce RTX 3080 12GB',
      brand: 'NVIDIA',
      price: 799.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '12GB GDDR6X',
        coreClock: '1.71 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    },
    {
      id: 'gpu17',
      name: 'AMD Radeon RX 6600',
      brand: 'AMD',
      price: 279.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '8GB GDDR6',
        coreClock: '2.28 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4'
      }
    },
    {
      id: 'gpu18',
      name: 'NVIDIA GeForce GTX 1660 Super',
      brand: 'NVIDIA',
      price: 249.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '6GB GDDR6',
        coreClock: '1.53 GHz',
        ports: 'HDMI 2.0, 3x DisplayPort 1.4'
      }
    },
    {
      id: 'gpu19',
      name: 'AMD Radeon RX 6500 XT',
      brand: 'AMD',
      price: 199.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '4GB GDDR6',
        coreClock: '2.61 GHz',
        ports: 'HDMI 2.1, DisplayPort 1.4'
      }
    },
    {
      id: 'gpu20',
      name: 'NVIDIA GeForce RTX 3050',
      brand: 'NVIDIA',
      price: 249.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        memory: '8GB GDDR6',
        coreClock: '1.55 GHz',
        ports: 'HDMI 2.1, 3x DisplayPort 1.4a'
      }
    }
  ],
  ram: [
    {
      id: 'ram1',
      name: 'Corsair Vengeance RGB Pro DDR5-6000',
      brand: 'Corsair',
      price: 189.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '6000MHz',
        timing: 'CL36'
      }
    },
    {
      id: 'ram2',
      name: 'G.Skill Trident Z5 RGB DDR5-7200',
      brand: 'G.Skill',
      price: 249.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '7200MHz',
        timing: 'CL34'
      }
    },
    {
      id: 'ram3',
      name: 'Kingston FURY Beast DDR5-5600',
      brand: 'Kingston',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '5600MHz',
        timing: 'CL40'
      }
    },
    {
      id: 'ram4',
      name: 'Corsair Vengeance LPX DDR4-3600',
      brand: 'Corsair',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '3600MHz',
        timing: 'CL18'
      }
    },
    {
      id: 'ram5',
      name: 'G.Skill Ripjaws V DDR4-3200',
      brand: 'G.Skill',
      price: 79.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '3200MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram6',
      name: 'Crucial Ballistix DDR4-3600',
      brand: 'Crucial',
      price: 89.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '3600MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram7',
      name: 'TeamGroup T-Force Delta RGB DDR5-6400',
      brand: 'TeamGroup',
      price: 179.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '6400MHz',
        timing: 'CL32'
      }
    },
    {
      id: 'ram8',
      name: 'Corsair Dominator Platinum RGB DDR5-6200',
      brand: 'Corsair',
      price: 229.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '32GB (2x16GB)',
        speed: '6200MHz',
        timing: 'CL36'
      }
    },
    {
      id: 'ram9',
      name: 'HyperX Fury RGB DDR4-3200',
      brand: 'HyperX',
      price: 84.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3200MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram10',
      name: 'Crucial Ballistix MAX DDR4-4400',
      brand: 'Crucial',
      price: 169.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '4400MHz',
        timing: 'CL19'
      }
    },
    {
      id: 'ram11',
      name: 'Corsair Vengeance RGB RT DDR4-3600',
      brand: 'Corsair',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3600MHz',
        timing: 'CL18'
      }
    },
    {
      id: 'ram12',
      name: 'G.Skill Trident Z Neo DDR4-3800',
      brand: 'G.Skill',
      price: 129.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3800MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram13',
      name: 'TeamGroup T-Force Xtreem ARGB DDR4-3600',
      brand: 'TeamGroup',
      price: 119.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3600MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram14',
      name: 'Kingston HyperX Predator DDR4-4000',
      brand: 'Kingston',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '4000MHz',
        timing: 'CL19'
      }
    },
    {
      id: 'ram15',
      name: 'Crucial Ballistix RGB DDR4-3200',
      brand: 'Crucial',
      price: 89.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3200MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram16',
      name: 'G.Skill Ripjaws V DDR4-3600',
      brand: 'G.Skill',
      price: 94.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3600MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram17',
      name: 'Corsair Vengeance RGB Pro SL DDR4-3600',
      brand: 'Corsair',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3600MHz',
        timing: 'CL18'
      }
    },
    {
      id: 'ram18',
      name: 'TeamGroup T-Force Delta RGB DDR4-3200',
      brand: 'TeamGroup',
      price: 79.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3200MHz',
        timing: 'CL16'
      }
    },
    {
      id: 'ram19',
      name: 'Patriot Viper Steel DDR4-4400',
      brand: 'Patriot',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '4400MHz',
        timing: 'CL19'
      }
    },
    {
      id: 'ram20',
      name: 'ADATA XPG Spectrix D50 DDR4-3600',
      brand: 'ADATA',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '16GB (2x8GB)',
        speed: '3600MHz',
        timing: 'CL18'
      }
    }
  ],
  storage: [
    {
      id: 'storage1',
      name: 'Samsung 980 Pro NVMe SSD 1TB',
      brand: 'Samsung',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'NVMe SSD',
        speed: '7000MB/s Read, 5000MB/s Write'
      }
    },
    {
      id: 'storage2',
      name: 'Western Digital Black SN850X NVMe SSD 2TB',
      brand: 'Western Digital',
      price: 229.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '2TB',
        type: 'NVMe SSD',
        speed: '7300MB/s Read, 6600MB/s Write'
      }
    },
    {
      id: 'storage3',
      name: 'Crucial P5 Plus NVMe SSD 1TB',
      brand: 'Crucial',
      price: 129.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'NVMe SSD',
        speed: '6600MB/s Read, 5000MB/s Write'
      }
    },
    {
      id: 'storage4',
      name: 'Samsung 870 EVO SATA SSD 2TB',
      brand: 'Samsung',
      price: 189.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '2TB',
        type: 'SATA SSD',
        speed: '560MB/s Read, 530MB/s Write'
      }
    },
    {
      id: 'storage5',
      name: 'Seagate Barracuda HDD 4TB',
      brand: 'Seagate',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '4TB',
        type: 'HDD',
        speed: '7200 RPM, 210MB/s Transfer'
      }
    },
    {
      id: 'storage6',
      name: 'Sabrent Rocket 4 Plus NVMe SSD 2TB',
      brand: 'Sabrent',
      price: 249.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '2TB',
        type: 'NVMe SSD',
        speed: '7100MB/s Read, 6600MB/s Write'
      }
    },
    {
      id: 'storage7',
      name: 'Kingston KC3000 NVMe SSD 1TB',
      brand: 'Kingston',
      price: 139.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'NVMe SSD',
        speed: '7000MB/s Read, 6000MB/s Write'
      }
    },
    {
      id: 'storage8',
      name: 'Crucial MX500 SATA SSD 1TB',
      brand: 'Crucial',
      price: 89.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'SATA SSD',
        speed: '560MB/s Read, 510MB/s Write'
      }
    },
    {
      id: 'storage9',
      name: 'Western Digital Blue HDD 6TB',
      brand: 'Western Digital',
      price: 129.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '6TB',
        type: 'HDD',
        speed: '5640 RPM, 180MB/s Transfer'
      }
    },
    {
      id: 'storage10',
      name: 'Sabrent Rocket Q NVMe SSD 4TB',
      brand: 'Sabrent',
      price: 399.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '4TB',
        type: 'NVMe SSD',
        speed: '3400MB/s Read, 3000MB/s Write'
      }
    },
    {
      id: 'storage11',
      name: 'Corsair MP600 Pro XT NVMe SSD 2TB',
      brand: 'Corsair',
      price: 259.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '2TB',
        type: 'NVMe SSD',
        speed: '7100MB/s Read, 6800MB/s Write'
      }
    },
    {
      id: 'storage12',
      name: 'Samsung 980 SATA SSD 1TB',
      brand: 'Samsung',
      price: 119.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'SATA SSD',
        speed: '560MB/s Read, 530MB/s Write'
      }
    },
    {
      id: 'storage13',
      name: 'Seagate IronWolf NAS HDD 8TB',
      brand: 'Seagate',
      price: 199.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '8TB',
        type: 'HDD',
        speed: '7200 RPM, 210MB/s Transfer'
      }
    },
    {
      id: 'storage14',
      name: 'ADATA XPG SX8200 Pro NVMe SSD 1TB',
      brand: 'ADATA',
      price: 124.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'NVMe SSD',
        speed: '3500MB/s Read, 3000MB/s Write'
      }
    },
    {
      id: 'storage15',
      name: 'Western Digital Black SN770 NVMe SSD 1TB',
      brand: 'Western Digital',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'NVMe SSD',
        speed: '5150MB/s Read, 4900MB/s Write'
      }
    },
    {
      id: 'storage16',
      name: 'Kingston KC2500 NVMe SSD 500GB',
      brand: 'Kingston',
      price: 79.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '500GB',
        type: 'NVMe SSD',
        speed: '3500MB/s Read, 2900MB/s Write'
      }
    },
    {
      id: 'storage17',
      name: 'Crucial BX500 SATA SSD 2TB',
      brand: 'Crucial',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '2TB',
        type: 'SATA SSD',
        speed: '540MB/s Read, 500MB/s Write'
      }
    },
    {
      id: 'storage18',
      name: 'Toshiba X300 HDD 10TB',
      brand: 'Toshiba',
      price: 249.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '10TB',
        type: 'HDD',
        speed: '7200 RPM, 240MB/s Transfer'
      }
    },
    {
      id: 'storage19',
      name: 'Sabrent Rocket Q4 NVMe SSD 2TB',
      brand: 'Sabrent',
      price: 229.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '2TB',
        type: 'NVMe SSD',
        speed: '4900MB/s Read, 3500MB/s Write'
      }
    },
    {
      id: 'storage20',
      name: 'Seagate FireCuda 530 NVMe SSD 1TB',
      brand: 'Seagate',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        capacity: '1TB',
        type: 'NVMe SSD',
        speed: '7300MB/s Read, 6000MB/s Write'
      }
    }
  ],
  case: [
    {
      id: 'case1',
      name: 'Corsair 4000D Airflow',
      brand: 'Corsair',
      price: 104.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '453 x 230 x 466 mm',
        features: 'High Airflow, Tempered Glass'
      }
    },
    {
      id: 'case2',
      name: 'NZXT H510 Flow',
      brand: 'NZXT',
      price: 89.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '428 x 210 x 460 mm',
        features: 'Perforated Front Panel, Cable Management'
      }
    },
    {
      id: 'case3',
      name: 'Lian Li O11 Dynamic EVO',
      brand: 'Lian Li',
      price: 169.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '465 x 285 x 459 mm',
        features: 'Dual-Chamber Design, Glass Panels'
      }
    },
    {
      id: 'case4',
      name: 'Phanteks P500A D-RGB',
      brand: 'Phanteks',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Full Tower',
        dimensions: '505 x 240 x 510 mm',
        features: 'Ultra-High Airflow, RGB Fans'
      }
    },
    {
      id: 'case5',
      name: 'Fractal Design Meshify 2',
      brand: 'Fractal Design',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '474 x 229 x 505 mm',
        features: 'Mesh Front, Modular Interior'
      }
    },
    {
      id: 'case6',
      name: 'Cooler Master MasterBox TD500 Mesh',
      brand: 'Cooler Master',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '493 x 217 x 469 mm',
        features: 'Polygonal Mesh Front, RGB Fans'
      }
    },
    {
      id: 'case7',
      name: 'be quiet! Pure Base 500DX',
      brand: 'be quiet!',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '463 x 232 x 450 mm',
        features: 'Airflow Optimized, ARGB Lighting'
      }
    },
    {
      id: 'case8',
      name: 'Corsair iCUE 5000X RGB',
      brand: 'Corsair',
      price: 189.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '520 x 245 x 510 mm',
        features: 'Tri-Chamber Layout, 4 Tempered Glass Panels'
      }
    },
    {
      id: 'case9',
      name: 'NZXT H7 Flow',
      brand: 'NZXT',
      price: 129.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '480 x 230 x 505 mm',
        features: 'Perforated Panel Design, Cable Management'
      }
    },
    {
      id: 'case10',
      name: 'Lian Li Lancool II Mesh',
      brand: 'Lian Li',
      price: 119.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '478 x 229 x 494 mm',
        features: 'Mesh Front Panel, Hinged Tempered Glass'
      }
    },
    {
      id: 'case11',
      name: 'Silverstone FARA R1',
      brand: 'Silverstone',
      price: 69.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '430 x 210 x 450 mm',
        features: 'Mesh Front Panel, Compact Design'
      }
    },
    {
      id: 'case12',
      name: 'Phanteks Eclipse P400A Digital',
      brand: 'Phanteks',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '465 x 210 x 470 mm',
        features: 'Ultra-Fine Mesh Front, D-RGB Fans'
      }
    },
    {
      id: 'case13',
      name: 'Fractal Design Focus G',
      brand: 'Fractal Design',
      price: 59.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '445 x 213 x 464 mm',
        features: 'Windowed Side Panel, Two Pre-installed Fans'
      }
    },
    {
      id: 'case14',
      name: 'Thermaltake Core P3',
      brand: 'Thermaltake',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '512 x 333 x 470 mm',
        features: 'Open Frame Design, Wall Mountable'
      }
    },
    {
      id: 'case15',
      name: 'Corsair 5000D Airflow',
      brand: 'Corsair',
      price: 174.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '520 x 245 x 520 mm',
        features: 'High Airflow, Dual Chamber Layout'
      }
    },
    {
      id: 'case16',
      name: 'NZXT H210i',
      brand: 'NZXT',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'Mini ITX',
        dimensions: '349 x 210 x 372 mm',
        features: 'Integrated RGB and Fan Controller, Small Footprint'
      }
    },
    {
      id: 'case17',
      name: 'Cooler Master HAF 700 EVO',
      brand: 'Cooler Master',
      price: 399.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Full Tower',
        dimensions: '626 x 291 x 675 mm',
        features: 'Extreme Cooling, 7" Touch Screen Panel'
      }
    },
    {
      id: 'case18',
      name: 'be quiet! Silent Base 802',
      brand: 'be quiet!',
      price: 169.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Mid Tower',
        dimensions: '539 x 281 x 553 mm',
        features: 'Interchangeable Front Panel, Sound Dampening'
      }
    },
    {
      id: 'case19',
      name: 'Lian Li PC-O11 Dynamic Mini',
      brand: 'Lian Li',
      price: 129.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'Mini-ITX/ATX',
        dimensions: '420 x 269 x 380 mm',
        features: 'Compact Design, Dual-Chamber Layout'
      }
    },
    {
      id: 'case20',
      name: 'Phanteks Enthoo Pro 2',
      brand: 'Phanteks',
      price: 199.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        formFactor: 'ATX Full Tower',
        dimensions: '580 x 240 x 560 mm',
        features: 'Extreme Expandability, Dual System Support'
      }
    }
  ],
  psu: [
    {
      id: 'psu1',
      name: 'Corsair RM850x',
      brand: 'Corsair',
      price: 139.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '850W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu2',
      name: 'EVGA SuperNOVA 750 G5',
      brand: 'EVGA',
      price: 129.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '750W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu3',
      name: 'Seasonic FOCUS GX-650',
      brand: 'Seasonic',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '650W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu4',
      name: 'be quiet! Straight Power 11 750W',
      brand: 'be quiet!',
      price: 139.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '750W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu5',
      name: 'Thermaltake Toughpower GF1 850W',
      brand: 'Thermaltake',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '850W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu6',
      name: 'Corsair SF750',
      brand: 'Corsair',
      price: 184.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '750W',
        efficiency: '80+ Platinum',
        modularity: 'Fully Modular SFX'
      }
    },
    {
      id: 'psu7',
      name: 'NZXT C850',
      brand: 'NZXT',
      price: 149.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '850W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu8',
      name: 'Seasonic PRIME TX-1000',
      brand: 'Seasonic',
      price: 309.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '1000W',
        efficiency: '80+ Titanium',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu9',
      name: 'EVGA SuperNOVA 650 G6',
      brand: 'EVGA',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '650W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu10',
      name: 'Cooler Master V850 Gold V2',
      brand: 'Cooler Master',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '850W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu11',
      name: 'Corsair RM650',
      brand: 'Corsair',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '650W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu12',
      name: 'Fractal Design Ion+ 660P',
      brand: 'Fractal Design',
      price: 119.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '660W',
        efficiency: '80+ Platinum',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu13',
      name: 'be quiet! Pure Power 11 600W',
      brand: 'be quiet!',
      price: 89.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '600W',
        efficiency: '80+ Gold',
        modularity: 'Semi-Modular'
      }
    },
    {
      id: 'psu14',
      name: 'Antec High Current Gamer 750W',
      brand: 'Antec',
      price: 119.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '750W',
        efficiency: '80+ Gold',
        modularity: 'Semi-Modular'
      }
    },
    {
      id: 'psu15',
      name: 'Silverstone SX700-PT',
      brand: 'Silverstone',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '700W',
        efficiency: '80+ Platinum',
        modularity: 'Fully Modular SFX'
      }
    },
    {
      id: 'psu16',
      name: 'EVGA SuperNOVA 1000 T2',
      brand: 'EVGA',
      price: 299.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '1000W',
        efficiency: '80+ Titanium',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu17',
      name: 'Thermaltake Smart BM2 550W',
      brand: 'Thermaltake',
      price: 59.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '550W',
        efficiency: '80+ Bronze',
        modularity: 'Semi-Modular'
      }
    },
    {
      id: 'psu18',
      name: 'Seasonic S12III 500W',
      brand: 'Seasonic',
      price: 49.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '500W',
        efficiency: '80+ Bronze',
        modularity: 'Non-Modular'
      }
    },
    {
      id: 'psu19',
      name: 'MSI MPG A850GF',
      brand: 'MSI',
      price: 139.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '850W',
        efficiency: '80+ Gold',
        modularity: 'Fully Modular'
      }
    },
    {
      id: 'psu20',
      name: 'Corsair HX1200',
      brand: 'Corsair',
      price: 259.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        power: '1200W',
        efficiency: '80+ Platinum',
        modularity: 'Fully Modular'
      }
    }
  ],
  cooling: [
    {
      id: 'cooling1',
      name: 'Noctua NH-D15',
      brand: 'Noctua',
      price: 99.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '220W',
        fans: 'Dual 140mm'
      }
    },
    {
      id: 'cooling2',
      name: 'Corsair H150i RGB Pro XT',
      brand: 'Corsair',
      price: 179.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '360mm',
        fans: 'Triple 120mm'
      }
    },
    {
      id: 'cooling3',
      name: 'be quiet! Dark Rock Pro 4',
      brand: 'be quiet!',
      price: 89.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '250W',
        fans: 'Dual 135mm/120mm'
      }
    },
    {
      id: 'cooling4',
      name: 'NZXT Kraken X63',
      brand: 'NZXT',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '280mm',
        fans: 'Dual 140mm'
      }
    },
    {
      id: 'cooling5',
      name: 'Arctic Liquid Freezer II 240',
      brand: 'Arctic',
      price: 119.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '240mm',
        fans: 'Dual 120mm'
      }
    },
    {
      id: 'cooling6',
      name: 'Cooler Master Hyper 212 RGB',
      brand: 'Cooler Master',
      price: 44.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '150W',
        fans: 'Single 120mm RGB'
      }
    },
    {
      id: 'cooling7',
      name: 'EK-AIO 360 D-RGB',
      brand: 'EKWB',
      price: 189.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '360mm',
        fans: 'Triple 120mm D-RGB'
      }
    },
    {
      id: 'cooling8',
      name: 'Scythe Fuma 2',
      brand: 'Scythe',
      price: 59.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '180W',
        fans: 'Dual 120mm'
      }
    },
    {
      id: 'cooling9',
      name: 'Thermaltake Floe DX RGB 360',
      brand: 'Thermaltake',
      price: 199.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '360mm',
        fans: 'Triple 120mm RGB'
      }
    },
    {
      id: 'cooling10',
      name: 'DeepCool AK620',
      brand: 'DeepCool',
      price: 69.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '260W',
        fans: 'Dual 120mm'
      }
    },
    {
      id: 'cooling11',
      name: 'Corsair H100i RGB Pro XT',
      brand: 'Corsair',
      price: 139.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '240mm',
        fans: 'Dual 120mm'
      }
    },
    {
      id: 'cooling12',
      name: 'Noctua NH-U12A',
      brand: 'Noctua',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '180W',
        fans: 'Dual 120mm'
      }
    },
    {
      id: 'cooling13',
      name: 'NZXT Kraken Z73',
      brand: 'NZXT',
      price: 269.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '360mm',
        fans: 'Triple 120mm',
        features: 'LCD Display'
      }
    },
    {
      id: 'cooling14',
      name: 'be quiet! Pure Rock 2',
      brand: 'be quiet!',
      price: 44.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '150W',
        fans: 'Single 120mm'
      }
    },
    {
      id: 'cooling15',
      name: 'Fractal Design Celsius+ S36 Dynamic',
      brand: 'Fractal Design',
      price: 169.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '360mm',
        fans: 'Triple 120mm'
      }
    },
    {
      id: 'cooling16',
      name: 'Cooler Master MasterAir MA624',
      brand: 'Cooler Master',
      price: 74.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '230W',
        fans: 'Dual 140mm/120mm'
      }
    },
    {
      id: 'cooling17',
      name: 'Alphacool Eisbaer Aurora 280',
      brand: 'Alphacool',
      price: 159.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '280mm',
        fans: 'Dual 140mm',
        features: 'Expandable Loop'
      }
    },
    {
      id: 'cooling18',
      name: 'Thermalright Peerless Assassin 120',
      brand: 'Thermalright',
      price: 49.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '210W',
        fans: 'Dual 120mm'
      }
    },
    {
      id: 'cooling19',
      name: 'MSI MAG CoreLiquid 240R',
      brand: 'MSI',
      price: 109.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'AIO Liquid Cooler',
        radiator: '240mm',
        fans: 'Dual 120mm RGB'
      }
    },
    {
      id: 'cooling20',
      name: 'Arctic Freezer 34 eSports DUO',
      brand: 'Arctic',
      price: 54.99,
      imageUrl: '/placeholder-image.jpg',
      specs: {
        type: 'Air Cooler',
        tdp: '210W',
        fans: 'Dual 120mm'
      }
    }
  ]
};

export default mockComponents;
