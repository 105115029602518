import { useState, useEffect } from 'react';
import { Slider } from '@/components/ui/slider';
import { formatPrice } from '@/utils/pcBuilderUtils';

interface BudgetSliderProps {
  value: number;
  onChange: (value: number) => void;
}

const BudgetSlider = ({ value, onChange }: BudgetSliderProps) => {
  const [displayValue, setDisplayValue] = useState(value);
  const min = 500;
  const max = 3000;
  const step = 100;

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const handleValueChange = (newValue: number[]) => {
    setDisplayValue(newValue[0]);
    onChange(newValue[0]);
  };

  // Calculate a percentage for positioning the label
  const percentage = ((displayValue - min) / (max - min)) * 100;
  
  // Add boundaries to keep the label within the slider
  const clampedPercentage = Math.min(Math.max(percentage, 5), 95);

  return (
    <div className="space-y-8 w-full max-w-xl mx-auto">
      <div className="text-center animate-fade-in" style={{ animationDelay: '100ms' }}>
        <h3 className="text-2xl font-semibold mb-2">What's your budget?</h3>
        <p className="text-muted-foreground">
          Drag the slider to set your preferred budget range
        </p>
      </div>
      
      <div className="px-4 pt-10 pb-4 relative animate-fade-in" style={{ animationDelay: '200ms' }}>
        <div 
          className="absolute text-xl font-semibold py-2 px-4 bg-primary text-primary-foreground rounded-full -mt-16 transform -translate-x-1/2 transition-all duration-200"
          style={{ left: `${clampedPercentage}%` }}
        >
          {formatPrice(displayValue)}
        </div>
        
        <Slider
          value={[displayValue]}
          min={min}
          max={max}
          step={step}
          onValueChange={handleValueChange}
          className="py-4"
        />
        
        <div className="flex justify-between text-sm text-muted-foreground mt-2">
          <span>{formatPrice(min)}</span>
          <span>{formatPrice(max)}</span>
        </div>
      </div>
    </div>
  );
};

export default BudgetSlider;
