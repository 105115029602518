
import { PCBuild } from '@/utils/pcBuilder';
import { getDefaultPerformance } from '@/utils/pcBuilder/defaultPerformance';

// Workstation PC builds for productivity and professional work
const workstationBuilds: PCBuild[] = [
  {
    id: 'content-creator',
    name: 'Content Creator Pro',
    description: 'Optimized for video editing, 3D rendering, and other creative workflows.',
    imageUrl: '/lovable-uploads/76ceccf9-f8f3-45ca-8aca-7e7cad4f4970.png',
    price: 2799,
    components: {
      cpu: 'AMD Ryzen 9 7950X3D',
      motherboard: 'ASUS ProArt X670E-Creator WiFi',
      gpu: 'Nvidia RTX 4080 Super 16GB',
      ram: '64GB (2x32GB) DDR5-6000 CL30',
      storage: '4TB NVMe Gen4 SSD + 4TB SATA SSD',
      case: 'Fractal Design North',
      psu: '1000W 80+ Platinum',
      cooling: 'ARCTIC Liquid Freezer II 360'
    },
    useCase: ['content-creation', 'streaming', 'programming', 'gaming'],
    theme: ['minimal', 'sleek'],
    isValidated: true,
    performance: getDefaultPerformance(2799)
  },
  {
    id: 'trading-workstation',
    name: 'Trading Workstation',
    description: 'Multi-monitor setup optimized for financial trading and analysis.',
    imageUrl: '/lovable-uploads/e2e6587d-c8a0-4c5b-a753-1c9ceaa67df7.png',
    price: 1899,
    components: {
      cpu: 'Intel Core i7-13700',
      motherboard: 'ASUS ProArt B760-Creator',
      gpu: 'Nvidia RTX 4060 Ti 16GB',
      ram: '64GB (2x32GB) DDR4-3200',
      storage: '2TB NVMe SSD + 4TB HDD',
      case: 'Fractal Design Meshify 2',
      psu: '750W 80+ Gold',
      cooling: 'Noctua NH-U12S'
    },
    useCase: ['trading', 'office-work', 'programming'],
    theme: ['minimal', 'sleek'],
    isValidated: true,
    performance: getDefaultPerformance(1899)
  },
  {
    id: 'industrial-workstation',
    name: 'Industrial Workstation',
    description: 'Rugged build designed for industrial environments and reliability.',
    imageUrl: '/lovable-uploads/56372c02-256d-4395-a0dc-eacda8b6098f.png',
    price: 1499,
    components: {
      cpu: 'Intel Core i7-13700',
      motherboard: 'ASUS TUF Gaming B760-Plus WiFi',
      gpu: 'Nvidia RTX 4060 8GB',
      ram: '32GB (2x16GB) DDR4-3600',
      storage: '1TB NVMe SSD + 4TB HDD',
      case: 'Fractal Design Meshify 2',
      psu: '750W 80+ Gold',
      cooling: 'Noctua NH-D15'
    },
    useCase: ['office-work', 'programming'],
    theme: ['industrial', 'stealth'],
    isValidated: true,
    performance: getDefaultPerformance(1499)
  },
  {
    id: 'programmer-paradise',
    name: 'Programmer Paradise',
    description: 'Optimized for software development with multi-tasking capability.',
    imageUrl: '/lovable-uploads/05f8be3d-1657-4cb4-8a59-05dd4e0c01e5.png',
    price: 1799,
    components: {
      cpu: 'AMD Ryzen 9 7900X',
      motherboard: 'ASUS ProArt B650-Creator',
      gpu: 'Nvidia RTX 4060 8GB',
      ram: '64GB (2x32GB) DDR5-5600',
      storage: '2TB NVMe Gen4 SSD + 2TB SATA SSD',
      case: 'Fractal Design North',
      psu: '750W 80+ Gold',
      cooling: 'be quiet! Dark Rock Pro 4'
    },
    useCase: ['programming'],
    theme: ['minimal', 'sleek'],
    isValidated: true,
    performance: getDefaultPerformance(1799)
  },
  {
    id: 'luxury-workstation',
    name: 'Luxury Workstation',
    description: 'Premium business workstation with elegant design and powerful components.',
    imageUrl: '/lovable-uploads/f2d1cfde-8590-403f-a1de-faf52a742a86.png',
    price: 2299,
    components: {
      cpu: 'Intel Core i9-14900',
      motherboard: 'ASUS ProArt Z790-Creator WiFi',
      gpu: 'Nvidia RTX 4070 12GB',
      ram: '64GB (2x32GB) DDR5-6000',
      storage: '2TB NVMe Gen4 SSD + 4TB SATA SSD',
      case: 'Fractal Design Define 7',
      psu: '850W 80+ Platinum',
      cooling: 'Noctua NH-D15 Chromax Black'
    },
    useCase: ['office-work', 'programming', 'trading'],
    theme: ['luxury', 'minimal'],
    isValidated: true,
    performance: getDefaultPerformance(2299)
  },
  {
    id: 'cyberpunk-workstation',
    name: 'Cyberpunk Workstation',
    description: 'Futuristic workstation with unique aesthetics and solid performance.',
    imageUrl: '/lovable-uploads/f099c66e-d0b1-4867-9dfb-6a8b5f7bc982.png',
    price: 1799,
    components: {
      cpu: 'AMD Ryzen 9 7900X',
      motherboard: 'ASUS TUF Gaming X670E-Plus WiFi',
      gpu: 'Nvidia RTX 4060 Ti 16GB',
      ram: '32GB (2x16GB) DDR5-5600',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Lian Li O11 Dynamic',
      psu: '750W 80+ Gold',
      cooling: 'Corsair iCUE H100i RGB Pro XT'
    },
    useCase: ['programming', 'office-work'],
    theme: ['cyberpunk', 'rgb'],
    isValidated: true,
    performance: getDefaultPerformance(1799)
  },
  {
    id: 'retro-workstation',
    name: 'Retro Workstation',
    description: 'Modern workstation with retro aesthetics for professionals.',
    imageUrl: '/lovable-uploads/2fd16431-09c8-4464-a853-10b328f93ec4.png',
    price: 1399,
    components: {
      cpu: 'Intel Core i7-13700',
      motherboard: 'ASUS ProArt B760-Creator',
      gpu: 'Nvidia RTX 4060 8GB',
      ram: '32GB (2x16GB) DDR4-3600',
      storage: '1TB NVMe SSD + 2TB HDD',
      case: 'Corsair 4000D (Customized)',
      psu: '650W 80+ Gold',
      cooling: 'be quiet! Pure Rock 2'
    },
    useCase: ['office-work', 'programming', 'general-use'],
    theme: ['retro', 'minimal'],
    isValidated: true,
    performance: getDefaultPerformance(1399)
  }
];

export default workstationBuilds;
