
export const formatPrice = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(value);
};

export const getUseCaseDescription = (useCase: string): string => {
  const descriptions: Record<string, string> = {
    gaming: "Optimized for playing modern games with high frame rates",
    workstation: "Built for professional applications and content creation",
    office: "Perfect for everyday productivity and business tasks",
    streaming: "Designed for live streaming and content creation",
    development: "Tailored for software development and coding",
    budget: "Best value for your money without sacrificing performance"
  };
  
  return descriptions[useCase.toLowerCase()] || "Custom build for your specific needs";
};

export const getThemeDescription = (theme: string): string => {
  const descriptions: Record<string, string> = {
    minimalist: "Clean and understated aesthetics with a focus on simplicity",
    rgb: "Vibrant RGB lighting for a customizable and dynamic look",
    gaming: "Aggressive styling with gamer-focused design elements",
    professional: "Elegant and sophisticated design for work environments",
    silent: "Optimized for quiet operation with minimal noise",
    compact: "Space-saving design without compromising performance"
  };
  
  return descriptions[theme.toLowerCase()] || "Custom aesthetic tailored to your preferences";
};

// Re-export everything from the pcBuilder modules for backward compatibility
export * from './pcBuilder/types';
export * from './pcBuilder/formatUtils';
export * from './pcBuilder/buildFilters';
export * from './pcBuilder/compatibilityChecker';
export * from './pcBuilder/accessoryUtils';
export * from './pcBuilder/index';

// Import the CompatibilityIssue type for proper typing
import { CompatibilityIssue } from './pcBuilder/types';

// Ensure we have a properly typed filterCompatibilityIssues function
export const filterCompatibilityIssues = (issues: CompatibilityIssue[], hideAll = false, hideWarnings = false, hideErrors = false) => {
  if (hideAll) return [];
  
  return issues.filter(issue => {
    if (hideWarnings && issue.severity === 'warning') return false;
    if (hideErrors && issue.severity === 'error') return false;
    return true;
  });
};
