import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ArrowLeft, Trash2, Copy, Settings } from 'lucide-react';
import { formatCurrency } from '@/utils/pcBuilder/formatUtils';
import { useToast } from '@/components/ui/use-toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface SavedBuild {
  id: string;
  name: string;
  date: string;
  components: Record<string, any>;
  totalPrice: number;
}

const SavedBuilds = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [builds, setBuilds] = useState<SavedBuild[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [buildToDelete, setBuildToDelete] = useState<string | null>(null);
  
  useEffect(() => {
    loadSavedBuilds();
  }, []);
  
  const loadSavedBuilds = () => {
    try {
      const savedData = localStorage.getItem('saved_pc_builds');
      if (savedData) {
        setBuilds(JSON.parse(savedData));
      }
    } catch (error) {
      console.error('Error loading saved builds:', error);
      toast({
        title: "Error",
        description: "Failed to load saved builds.",
        variant: "destructive"
      });
    }
  };
  
  const handleDeleteBuild = (buildId: string) => {
    setBuildToDelete(buildId);
    setIsDeleteDialogOpen(true);
  };
  
  const confirmDelete = () => {
    if (!buildToDelete) return;
    
    const updatedBuilds = builds.filter(build => build.id !== buildToDelete);
    setBuilds(updatedBuilds);
    
    try {
      localStorage.setItem('saved_pc_builds', JSON.stringify(updatedBuilds));
      toast({
        title: "Build Deleted",
        description: "The PC build has been deleted successfully."
      });
    } catch (error) {
      console.error('Error deleting build:', error);
      toast({
        title: "Error",
        description: "Failed to delete the PC build.",
        variant: "destructive"
      });
    }
    
    setIsDeleteDialogOpen(false);
    setBuildToDelete(null);
  };
  
  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex items-center gap-4 mb-6">
        <Button 
          variant="outline" 
          size="icon"
          onClick={() => navigate('/pc-part-picker')}
        >
          <ArrowLeft className="h-4 w-4" />
        </Button>
        <h1 className="text-2xl font-bold">Saved PC Builds</h1>
      </div>
      
      {builds.length === 0 ? (
        <Card>
          <CardContent className="p-8 text-center">
            <p className="text-muted-foreground">You don't have any saved PC builds yet.</p>
            <Button 
              className="mt-4"
              onClick={() => navigate('/pc-part-picker')}
            >
              Create New Build
            </Button>
          </CardContent>
        </Card>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {builds.map((build) => (
            <Card key={build.id}>
              <CardHeader className="pb-2">
                <div className="flex justify-between items-start">
                  <CardTitle>{build.name}</CardTitle>
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    onClick={() => handleDeleteBuild(build.id)}
                  >
                    <Trash2 className="h-4 w-4 text-destructive" />
                  </Button>
                </div>
                <p className="text-xs text-muted-foreground">
                  Created on {new Date(build.date).toLocaleDateString()}
                </p>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  {Object.entries(build.components).map(([type, component]) => (
                    component && (
                      <div key={type} className="flex justify-between text-sm">
                        <span className="capitalize font-medium">{type}:</span>
                        <span className="text-right max-w-[200px] truncate">{component.name}</span>
                      </div>
                    )
                  ))}
                  
                  <div className="pt-4 mt-4 border-t flex justify-between">
                    <span className="font-bold">Total:</span>
                    <span className="font-bold">{formatCurrency(build.totalPrice)}</span>
                  </div>
                </div>
              </CardContent>
              <CardFooter>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  onClick={() => navigate(`/pc-part-picker/${build.id}`)}
                >
                  <Copy className="h-4 w-4" />
                </Button>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  onClick={() => navigate(`/pc-part-picker/${build.id}/settings`)}
                >
                  <Settings className="h-4 w-4" />
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      )}
      
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete this PC build. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDelete} className="bg-destructive text-destructive-foreground">
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default SavedBuilds;
