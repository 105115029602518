
import { PCBuild } from '@/utils/pcBuilder';
import { getDefaultPerformance } from '@/utils/pcBuilder/defaultPerformance';

// Theme-focused PC builds (white, RGB, retro, etc.)
const themeBuilds: PCBuild[] = [
  {
    id: 'white-frost',
    name: 'White Frost',
    description: 'All-white themed build with powerful components.',
    imageUrl: 'https://images.unsplash.com/photo-1624705015334-520a5c2ae5b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2531&q=80',
    price: 2099,
    components: {
      cpu: 'Intel Core i7-13700K',
      motherboard: 'ASUS ROG STRIX Z790-A GAMING WIFI',
      gpu: 'Nvidia RTX 4070 Ti Super 16GB (White)',
      ram: '32GB (2x16GB) DDR5-6000 CL30 (White)',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Corsair iCUE 5000X RGB (White)',
      psu: '850W 80+ Gold (White Cables)',
      cooling: 'Corsair iCUE H150i ELITE CAPELLIX (White)'
    },
    useCase: ['gaming', 'streaming', 'content-creation'],
    theme: ['white', 'rgb', 'sleek'],
    isValidated: true,
    performance: getDefaultPerformance(2099)
  },
  {
    id: 'cyberpunk-build',
    name: 'Cyberpunk Enthusiast',
    description: 'Futuristic build with aggressive styling and powerful components.',
    imageUrl: 'https://images.unsplash.com/photo-1625842268584-8f3296236761?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=80',
    price: 2499,
    components: {
      cpu: 'AMD Ryzen 9 7900X',
      motherboard: 'ASRock X670E Taichi',
      gpu: 'Nvidia RTX 4080 16GB',
      ram: '32GB (2x16GB) DDR5-6000',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Thermaltake Tower 500',
      psu: '1000W 80+ Platinum',
      cooling: 'NZXT Kraken Z73 RGB'
    },
    useCase: ['gaming', 'streaming'],
    theme: ['cyberpunk', 'rgb'],
    isValidated: true,
    performance: getDefaultPerformance(2499)
  },
  {
    id: 'retro-gaming',
    name: 'Retro Gaming PC',
    description: 'Vintage-inspired design with modern performance for classic and modern gaming.',
    imageUrl: 'https://images.unsplash.com/photo-1593152167544-085d3b9c4938?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=80',
    price: 1799,
    components: {
      cpu: 'AMD Ryzen 7 7800X3D',
      motherboard: 'MSI MAG B650 TOMAHAWK WIFI',
      gpu: 'Nvidia RTX 4070 12GB',
      ram: '32GB (2x16GB) DDR5-5600',
      storage: '2TB NVMe Gen4 SSD',
      case: 'NZXT H9 Flow (Beige/Brown)',
      psu: '850W 80+ Gold',
      cooling: 'be quiet! Dark Rock Pro 4'
    },
    useCase: ['gaming', 'general-use'],
    theme: ['retro', 'minimal'],
    isValidated: true,
    performance: getDefaultPerformance(1799)
  },
  {
    id: 'luxury-showcase',
    name: 'Luxury Showcase',
    description: 'Premium build with top-tier components and elaborate water cooling for discerning enthusiasts.',
    imageUrl: 'https://images.unsplash.com/photo-1624913503273-5f9c4e980dba?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=80',
    price: 4999,
    components: {
      cpu: 'Intel Core i9-14900K',
      motherboard: 'ASUS ROG MAXIMUS Z790 HERO',
      gpu: 'Nvidia RTX 4090 24GB',
      ram: '64GB (4x16GB) DDR5-7200 CL30',
      storage: '4TB NVMe Gen4 SSD + 8TB SATA SSD',
      case: 'Lian Li O11 Dynamic EVO (Black/Gold)',
      psu: '1600W 80+ Titanium',
      cooling: 'Custom Water Cooling Loop with Gold Fittings'
    },
    useCase: ['gaming', 'content-creation'],
    theme: ['luxury', 'rgb'],
    isValidated: true,
    performance: getDefaultPerformance(4999)
  }
];

export default themeBuilds;
