
import { cn } from '@/lib/utils';
import { UseCase, getUseCaseDescription } from '@/utils/pcBuilderUtils';
import { 
  Gamepad2, 
  Video, 
  Palette, 
  Briefcase, 
  Code, 
  Globe 
} from 'lucide-react';

interface UseCaseSelectorProps {
  selectedUseCase: UseCase;
  onChange: (useCase: UseCase) => void;
}

const useCases: { value: UseCase; label: string; icon: React.ReactNode }[] = [
  {
    value: 'gaming',
    label: 'Gaming',
    icon: <Gamepad2 className="h-5 w-5" />
  },
  {
    value: 'streaming',
    label: 'Streaming',
    icon: <Video className="h-5 w-5" />
  },
  {
    value: 'content-creation',
    label: 'Content Creation',
    icon: <Palette className="h-5 w-5" />
  },
  {
    value: 'office-work',
    label: 'Office Work',
    icon: <Briefcase className="h-5 w-5" />
  },
  {
    value: 'programming',
    label: 'Programming',
    icon: <Code className="h-5 w-5" />
  },
  {
    value: 'general-use',
    label: 'General Use',
    icon: <Globe className="h-5 w-5" />
  }
];

const UseCaseSelector = ({ selectedUseCase, onChange }: UseCaseSelectorProps) => {
  return (
    <div className="space-y-8 w-full max-w-xl mx-auto">
      <div className="text-center animate-fade-in" style={{ animationDelay: '100ms' }}>
        <h3 className="text-2xl font-semibold mb-2 font-heading">What will you use your PC for?</h3>
        <p className="text-muted-foreground font-sans">
          Select your primary use case to help us recommend the best components
        </p>
      </div>
      
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-2 animate-fade-in" style={{ animationDelay: '200ms' }}>
        {useCases.map((useCase) => (
          <button
            key={useCase.value}
            type="button"
            onClick={() => onChange(useCase.value)}
            className={cn(
              "relative flex flex-col items-center justify-center p-4 rounded-xl border transition-all duration-300 hover:border-primary/50 hover:bg-primary/5 hover-scale font-sans",
              selectedUseCase === useCase.value
                ? "border-primary bg-primary/10 shadow-sm"
                : "border-border bg-card"
            )}
          >
            <div className={cn(
              "p-2 rounded-full mb-3 transition-colors",
              selectedUseCase === useCase.value
                ? "bg-primary/20 text-primary"
                : "bg-secondary text-muted-foreground"
            )}>
              {useCase.icon}
            </div>
            <span className="font-medium text-sm">{useCase.label}</span>
          </button>
        ))}
      </div>
      
      <div className="py-4 px-5 bg-secondary/50 rounded-lg animate-fade-in" style={{ animationDelay: '300ms' }}>
        <p className="text-sm text-center font-sans">
          {getUseCaseDescription(selectedUseCase)}
        </p>
      </div>
    </div>
  );
};

export default UseCaseSelector;
