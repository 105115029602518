
import { Accessory, formatPrice } from '@/utils/pcBuilder';
import { cn } from '@/lib/utils';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import ImageValidatorItem from '@/components/ImageValidator/ImageValidatorItem';

interface AccessoryItemProps {
  accessory: Accessory;
  isSelected?: boolean;
  onToggle?: () => void;
}

const AccessoryItem = ({ 
  accessory, 
  isSelected = false,
  onToggle
}: AccessoryItemProps) => {
  // Determine if this is an RGB accessory
  const isRgb = Array.isArray(accessory.theme) 
    ? accessory.theme.includes('rgb') 
    : accessory.theme === 'rgb';
  
  return (
    <div className={cn(
      "group relative flex border rounded-xl overflow-hidden transition-all duration-300 hover:border-primary/70 hover:shadow-md",
      isSelected ? "border-primary bg-primary/5" : "border-border"
    )}>
      <div className="w-28 h-28 shrink-0 relative overflow-hidden bg-muted flex items-center justify-center">
        <div className="relative w-full h-full">
          <ImageValidatorItem 
            src={accessory.imageUrl} 
            alt={accessory.name}
            className="w-full h-full"
            category={accessory.category || 'accessory'}
          />
          
          {/* Add lighting effects for RGB products */}
          {isRgb && (
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-red-500/20 mix-blend-overlay dark:from-blue-500/30 dark:via-purple-500/30 dark:to-red-500/30 pointer-events-none animate-pulse"></div>
          )}
          
          {/* Add subtle reflections to make products look more realistic */}
          <div className="absolute inset-0 bg-gradient-to-br from-white/20 to-transparent dark:from-white/10 pointer-events-none"></div>
        </div>
      </div>
      
      <div className="flex-grow p-3">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-sm font-medium line-clamp-1">{accessory.name}</h3>
            <span className="text-xs text-muted-foreground capitalize">{accessory.category || 'accessory'}</span>
          </div>
          <span className="text-sm font-semibold">{formatPrice(accessory.price)}</span>
        </div>
        
        <p className="text-xs text-muted-foreground mt-1 line-clamp-2">
          {accessory.description}
        </p>
      </div>
      
      {onToggle && (
        <Button
          variant={isSelected ? "secondary" : "default"}
          size="icon"
          className={cn(
            "absolute bottom-2 right-2 h-7 w-7 rounded-full",
            !isSelected && "opacity-0 group-hover:opacity-100 transition-opacity"
          )}
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
        >
          <Plus className={cn(
            "h-3.5 w-3.5 transition-transform",
            isSelected && "rotate-45"
          )} />
        </Button>
      )}
    </div>
  );
};

export default AccessoryItem;
