
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { PCBuild, Accessory } from '@/utils/pcBuilderUtils';
import { ArrowLeft } from 'lucide-react';
import accessories from '@/lib/accessories';
import { useToast } from '@/components/ui/use-toast';
import OrderSummary from '@/components/purchase/OrderSummary';
import PurchaseActions from '@/components/purchase/PurchaseActions';
import PurchaseDetails from '@/components/purchase/PurchaseDetails';
import { generateNeweggCartLink } from '@/utils/neweggUtils';

const Purchase = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [selectedBuild, setSelectedBuild] = useState<PCBuild | null>(null);
  const [selectedAccessories, setSelectedAccessories] = useState<Accessory[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    try {
      if (location.state) {
        const { build, accessoryIds, total } = location.state;
        
        if (build) {
          setSelectedBuild(build);
        } else {
          setError('No build data was provided');
        }
        
        if (accessoryIds && Array.isArray(accessoryIds)) {
          const accessoryItems = accessories.filter(acc => 
            accessoryIds.includes(acc.id)
          );
          setSelectedAccessories(accessoryItems);
        }
        
        if (total) {
          setTotalPrice(total);
        }
      } else {
        setError('No purchase data was provided');
      }
    } catch (err) {
      console.error('Error initializing purchase page:', err);
      setError('Failed to load purchase data');
    } finally {
      setIsLoading(false);
    }
  }, [location, navigate]);

  const handleBackToHome = () => {
    navigate('/');
  };

  const handlePurchaseClick = () => {
    setIsProcessing(true);
    
    try {
      if (!selectedBuild) {
        throw new Error('No build selected');
      }
      
      const neweggUrl = generateNeweggCartLink(selectedBuild, selectedAccessories);
      console.log("Opening Newegg URL:", neweggUrl);
      
      setTimeout(() => {
        setIsProcessing(false);
        
        window.open(neweggUrl, '_blank');
      }, 800);
    } catch (error) {
      console.error("Failed to generate Newegg cart link:", error);
      setIsProcessing(false);
      
      toast({
        title: "Error",
        description: "There was a problem with the Newegg link. Please try again.",
        variant: "destructive",
        duration: 5000
      });
    }
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-12 max-w-5xl text-center">
        <div className="glass-panel rounded-2xl p-6 md:p-8 flex flex-col items-center justify-center min-h-[300px]">
          <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full mb-4"></div>
          <p>Loading purchase details...</p>
        </div>
      </div>
    );
  }

  // Error state
  if (error || !selectedBuild) {
    return (
      <div className="container mx-auto px-4 py-12 max-w-5xl text-center">
        <div className="glass-panel rounded-2xl p-6 md:p-8">
          <h2 className="text-xl font-bold mb-4 text-destructive">Unable to Load Purchase</h2>
          <p className="mb-4">{error || "No build data available"}</p>
          <Button onClick={handleBackToHome}>
            Back to Home
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12 max-w-5xl">
      <div className="glass-panel rounded-2xl p-6 md:p-8">
        <div className="space-y-8 animate-fade-in">
          <div className="flex items-center justify-between">
            <Button 
              variant="outline" 
              className="gap-2"
              onClick={handleBackToHome}
            >
              <ArrowLeft className="h-4 w-4" />
              Back to Home
            </Button>
            <h1 className="text-2xl font-bold">Purchase Your PC Build</h1>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2">
              <PurchaseDetails 
                selectedBuild={selectedBuild}
                selectedAccessories={selectedAccessories}
              />
            </div>
            
            <div className="lg:col-span-1">
              <OrderSummary
                selectedBuild={selectedBuild}
                selectedAccessories={selectedAccessories}
                totalPrice={totalPrice}
              />
              <PurchaseActions
                isProcessing={isProcessing}
                onPurchase={handlePurchaseClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchase;
