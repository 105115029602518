
import { PCBuild, Accessory, formatPrice } from '@/utils/pcBuilderUtils';
import { Card } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';

interface OrderSummaryProps {
  selectedBuild: PCBuild;
  selectedAccessories: Accessory[];
  totalPrice: number;
}

const OrderSummary = ({ 
  selectedBuild, 
  selectedAccessories, 
  totalPrice 
}: OrderSummaryProps) => {
  return (
    <Card className="p-4 md:p-6 sticky top-4">
      <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
      <div className="space-y-3">
        <div className="flex justify-between">
          <span>Base PC Build</span>
          <span>{formatPrice(selectedBuild.price)}</span>
        </div>
        
        {selectedAccessories.length > 0 && (
          <>
            <Separator />
            <div className="space-y-2">
              {selectedAccessories.map(accessory => (
                <div key={accessory.id} className="flex justify-between text-sm">
                  <span className="truncate pr-4">{accessory.name}</span>
                  <span>{formatPrice(accessory.price)}</span>
                </div>
              ))}
            </div>
          </>
        )}
        
        <Separator />
        <div className="flex justify-between font-bold">
          <span>Total</span>
          <span>{formatPrice(totalPrice)}</span>
        </div>
      </div>
    </Card>
  );
};

export default OrderSummary;
