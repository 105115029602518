
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { Cpu } from 'lucide-react';

interface LogoProps {
  className?: string;
  withText?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const Logo = ({ className, withText = true, size = 'md' }: LogoProps) => {
  const sizeClasses = {
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-3xl',
  };

  return (
    <Link to="/" className={cn(
      "flex items-center gap-2 font-bold transition-all hover:opacity-90",
      sizeClasses[size],
      className
    )}>
      <div className="relative flex items-center justify-center">
        <div className="relative flex items-center justify-center bg-primary text-primary-foreground p-1 rounded-md">
          <Cpu className="h-4 w-4 md:h-5 md:w-5" />
        </div>
      </div>
      {withText && (
        <span className="font-extrabold tracking-tight">
          PC<span className="text-primary">4U</span>
        </span>
      )}
    </Link>
  );
};

export default Logo;
