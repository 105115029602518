
import React from 'react';
import { formatPrice } from '@/utils/pcBuilder/formatUtils';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ComponentSpec } from './types';
import { AlertTriangle, CheckCircle2 } from 'lucide-react';
import ImageValidatorItem from '@/components/ImageValidator';

interface SelectedBuildProps {
  pcBuild: Record<string, ComponentSpec>;
  totalPrice: number;
}

const SelectedBuild: React.FC<SelectedBuildProps> = ({ pcBuild, totalPrice }) => {
  const componentNames = {
    cpu: 'CPU',
    motherboard: 'Motherboard',
    gpu: 'Graphics Card',
    ram: 'Memory',
    storage: 'Storage',
    case: 'Case',
    psu: 'Power Supply',
    cooling: 'CPU Cooler',
    caseFan: 'Case Fans',
    os: 'Operating System',
    monitor: 'Monitor',
    keyboard: 'Keyboard',
    mouse: 'Mouse',
    headset: 'Headset',
    speakers: 'Speakers',
    webcam: 'Webcam',
    microphone: 'Microphone',
    networkCard: 'Network Card',
    soundCard: 'Sound Card',
    cables: 'Cables',
    controller: 'Controller',
    ups: 'UPS Battery Backup',
    capture: 'Capture Card',
    thermalPaste: 'Thermal Paste'
  };

  // Required components for a functional PC build
  const requiredComponents = ['cpu', 'motherboard', 'ram', 'storage', 'case', 'psu'];
  
  // Count how many required components are selected
  const requiredComponentsSelected = requiredComponents.filter(comp => pcBuild[comp]).length;
  const buildProgress = Math.round((requiredComponentsSelected / requiredComponents.length) * 100);

  return (
    <Card className="mt-6">
      <CardHeader className="pb-2">
        <div className="flex justify-between items-center">
          <CardTitle className="text-xl">Your PC Build</CardTitle>
          <div className="text-sm text-muted-foreground">
            {buildProgress === 100 ? (
              <div className="flex items-center text-green-600 dark:text-green-500">
                <CheckCircle2 className="h-4 w-4 mr-1" />
                <span>Build Ready</span>
              </div>
            ) : (
              <div className="flex items-center text-amber-600 dark:text-amber-500">
                <AlertTriangle className="h-4 w-4 mr-1" />
                <span>{buildProgress}% Complete</span>
              </div>
            )}
          </div>
        </div>
      </CardHeader>
      
      <CardContent>
        {/* Progress bar */}
        <div className="w-full bg-secondary h-2 rounded-full overflow-hidden mb-4">
          <div 
            className="bg-primary h-full transition-all duration-300 ease-in-out"
            style={{ width: `${buildProgress}%` }}
          />
        </div>

        <div className="space-y-3">
          {/* Required components first */}
          {requiredComponents.map((type) => (
            <div key={type} className="flex justify-between items-center border-b pb-2">
              <div className="flex-1">
                <span className="text-sm font-medium text-muted-foreground">
                  {componentNames[type as keyof typeof componentNames] || type}:
                </span>
                {pcBuild[type] ? (
                  <p className="text-sm">{pcBuild[type].name}</p>
                ) : (
                  <p className="text-sm text-destructive">Not Selected</p>
                )}
              </div>
              <span className="text-sm font-medium">
                {pcBuild[type] ? formatPrice(pcBuild[type].price) : '-'}
              </span>
            </div>
          ))}
          
          {/* Optional components */}
          {Object.entries(pcBuild)
            .filter(([type]) => !requiredComponents.includes(type))
            .map(([type, component]) => (
              component && (
                <div key={type} className="flex justify-between items-center border-b pb-2">
                  <div className="flex-1">
                    <span className="text-sm font-medium text-muted-foreground">
                      {componentNames[type as keyof typeof componentNames] || type}:
                    </span>
                    <p className="text-sm">{component.name}</p>
                  </div>
                  <span className="text-sm font-medium">{formatPrice(component.price)}</span>
                </div>
              )
            ))}
          
          {/* Total price */}
          <div className="flex justify-between items-center pt-2 font-bold">
            <span>Total:</span>
            <span>{formatPrice(totalPrice)}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SelectedBuild;
