
import React from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { ArrowLeft, Check, Home } from 'lucide-react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { allGuides } from '@/data/guides';

interface Step {
  title: string;
  description: string;
  image?: string;
  tips?: string[];
}

const GuideDetail = () => {
  const { guideId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Try to get the guide from location state first (if passed from Guides page)
  const guideFromState = location.state?.guide;
  
  // Either use guide from state, or get it from the URL params
  const guide = guideFromState || allGuides.find(g => g.id === guideId);
  
  if (!guide) {
    return (
      <div className="container mx-auto py-12 px-4">
        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">Guide Not Found</h2>
          <p>Sorry, we couldn't find the guide you're looking for.</p>
          <Button onClick={() => navigate('/guides')} className="mt-4">
            Back to Guides
          </Button>
        </Card>
      </div>
    );
  }
  
  // Convert markdown-like content to steps for rendering
  const steps = parseGuideContent(guide.content);
  
  return (
    <div className="container mx-auto py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center gap-4 mb-8">
          <Button 
            variant="outline" 
            size="sm" 
            className="flex items-center gap-2"
            onClick={() => navigate('/guides')}
          >
            <ArrowLeft className="h-4 w-4" />
            Back to All Guides
          </Button>
          
          <Button 
            variant="outline" 
            size="sm" 
            className="flex items-center gap-2"
            onClick={() => navigate('/')}
          >
            <Home className="h-4 w-4" />
            Back to Home
          </Button>
        </div>
        
        <div className="mb-10">
          <h1 className="text-3xl font-bold mb-4">{guide.title}</h1>
          <p className="text-muted-foreground">{guide.description}</p>
        </div>
        
        <div className="space-y-10">
          {steps.map((step, index) => (
            <div key={index} className="space-y-4">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center text-primary-foreground font-semibold">
                  {index + 1}
                </div>
                <h2 className="text-xl font-semibold">{step.title}</h2>
              </div>
              
              <div className="pl-11">
                <p className="text-muted-foreground mb-4">{step.description}</p>
                
                {step.image && (
                  <div className="rounded-lg overflow-hidden my-6 border border-border">
                    <img 
                      src={step.image} 
                      alt={step.title} 
                      className="w-full h-auto object-cover"
                    />
                  </div>
                )}
                
                {step.tips && step.tips.length > 0 && (
                  <div className="bg-muted rounded-lg p-4 my-4">
                    <h3 className="font-medium mb-2">Tips</h3>
                    <ul className="space-y-2">
                      {step.tips.map((tip, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <Check className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                          <span>{tip}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        
        <Card className="p-6 mt-12">
          <CardContent className="p-0">
            <h3 className="text-lg font-semibold mb-4">Ready to build your own PC?</h3>
            <p className="mb-6">Use our PC builder to create a custom build that meets your needs.</p>
            <Button onClick={() => navigate('/pc-part-picker')}>PC4U: Find My PC</Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

// Helper function to parse markdown-like content into steps
const parseGuideContent = (content: string): Step[] => {
  const steps: Step[] = [];
  
  // Split by level-2 headers (##)
  const sections = content.split(/^## /gm);
  
  // Skip the first element if it's the introduction
  const sectionsToProcess = sections.length > 1 ? sections.slice(1) : sections;
  
  for (const section of sectionsToProcess) {
    if (!section.trim()) continue;
    
    const lines = section.split('\n');
    const title = lines[0].trim();
    let description = '';
    let tips: string[] = [];
    
    let inTipsSection = false;
    
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      
      if (line.startsWith('###') && line.toLowerCase().includes('tip')) {
        inTipsSection = true;
        continue;
      } else if (line.startsWith('###')) {
        inTipsSection = false;
        continue;
      }
      
      if (inTipsSection && line.startsWith('-')) {
        tips.push(line.substring(1).trim());
      } else if (!inTipsSection && line && !line.startsWith('#')) {
        if (description) description += ' ';
        description += line;
      }
    }
    
    steps.push({
      title,
      description,
      tips: tips.length > 0 ? tips : undefined
    });
  }
  
  return steps;
};

export default GuideDetail;
