
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { setupGlobalErrorTracking } from './utils/errorTrackingService'
import ErrorBoundary from './components/ErrorBoundary'
import logger from './utils/logger'

// Debug entry point
console.log("==== main.tsx ENTRY POINT ====");

// Apply immediate critical styles for white screen prevention
const applyEmergencyStyles = () => {
  const style = document.createElement('style');
  style.textContent = `
    body {
      background-color: #f8f9fa !important;
      color: #333 !important;
      display: block !important;
      opacity: 1 !important;
      visibility: visible !important;
    }
    .dark body {
      background-color: #121212 !important;
      color: #f8f9fa !important;
    }
    #root {
      display: block !important;
      min-height: 100vh;
      width: 100%;
    }
  `;
  document.head.appendChild(style);
  console.log("Emergency styles applied");
};

// Apply emergency styles immediately
applyEmergencyStyles();

// Create fallback content
const createFallbackContent = (rootElement, message = 'Loading application...') => {
  rootElement.innerHTML = `
    <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100vh; width: 100%; padding: 2rem; text-align: center;">
      <div style="width: 40px; height: 40px; border: 4px solid #f3f3f3; border-top: 4px solid #3498db; border-radius: 50%; animation: spin 1s linear infinite;"></div>
      <p style="margin-top: 20px;">${message}</p>
    </div>
    <style>
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    </style>
  `;
  console.log("Fallback content created with message:", message);
};

// Initialize error tracking
try {
  setupGlobalErrorTracking();
  logger.info('Global error tracking initialized');
} catch (error) {
  console.error('Failed to initialize error tracking:', error);
}

// Render the application
const renderApp = () => {
  console.log("Attempting to render app");
  const rootElement = document.getElementById('root');
  
  if (!rootElement) {
    console.error('Root element not found in the DOM');
    // Create root element if missing
    const newRoot = document.createElement('div');
    newRoot.id = 'root';
    document.body.appendChild(newRoot);
    console.log('Created new root element');
    renderApp(); // Try again with new root
    return;
  }
  
  // Show loading indicator while React initializes
  createFallbackContent(rootElement);
  
  try {
    console.log("Creating React root");
    const root = ReactDOM.createRoot(rootElement);
    
    console.log("Rendering app into root");
    root.render(
      <React.StrictMode>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </React.StrictMode>
    );
    
    logger.info('Application rendered successfully');
    console.log('React app rendered successfully');
  } catch (error) {
    console.error('Failed to render application:', error);
    
    // Show error fallback if rendering fails
    createFallbackContent(rootElement, 'Something went wrong. Please refresh the page.');
  }
};

// Execute immediately for faster loading
console.log("Document state:", document.readyState);

// Safety wrapper function to ensure clean rendering
const safeRenderApp = () => {
  try {
    renderApp();
  } catch (e) {
    console.error("Critical rendering error:", e);
    // Last resort emergency fallback
    const rootEl = document.getElementById('root');
    if (rootEl) {
      rootEl.innerHTML = `
        <div style="padding: 20px; text-align: center;">
          <h2 style="color: #d32f2f;">Critical Error</h2>
          <p>Please refresh the page or contact support.</p>
        </div>
      `;
    }
  }
};

// Ensure the DOM is ready, then render
if (document.readyState === 'loading') {
  console.log("Document still loading, waiting for DOMContentLoaded");
  document.addEventListener('DOMContentLoaded', safeRenderApp);
} else {
  console.log("Document already loaded, rendering immediately");
  safeRenderApp();
}

// Add safety timeouts to ensure rendering happens
setTimeout(safeRenderApp, 500);  // Quick first attempt
setTimeout(safeRenderApp, 2000); // Backup attempt

// Add visibility change handler to ensure app renders when tab becomes visible
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    console.log("Tab became visible, checking rendering");
    const rootElement = document.getElementById('root');
    if (rootElement && rootElement.innerHTML.includes('Loading application')) {
      console.log("App appears unrendered, attempting again");
      safeRenderApp();
    }
  }
});
