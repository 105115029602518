
import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import ImageValidatorItem from '@/components/ImageValidator/ImageValidatorItem';
import { ImageTrackingStatus } from '@/components/ImageTracker/ImageTrackingStatus';

interface EditImageDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  imageId: string;
  originalUrl: string;
  currentUrl: string;
  pendingUrl: string;
  onPendingUrlChange: (url: string) => void;
  onSave: () => void;
  onCancel: () => void;
  onReset: () => void;
  category?: string;
  fallbackUrl?: string;
}

const EditImageDialog = ({
  isOpen,
  onOpenChange,
  imageId,
  originalUrl,
  currentUrl,
  pendingUrl,
  onPendingUrlChange,
  onSave,
  onCancel,
  onReset,
  category,
  fallbackUrl
}: EditImageDialogProps) => {
  const handleSave = () => {
    onSave();
    onOpenChange(false);
  };
  
  const handleCancel = () => {
    onCancel();
    onOpenChange(false);
  };
  
  const handleReset = () => {
    onReset();
    onOpenChange(false);
  };
  
  const isUrlModified = pendingUrl !== originalUrl;
  
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Edit Image</DialogTitle>
        </DialogHeader>
        
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 gap-6">
            {/* Image preview */}
            <div className="h-48 overflow-hidden rounded-md border">
              <ImageValidatorItem 
                src={pendingUrl} 
                alt="Preview" 
                fallbackSrc={fallbackUrl}
                category={category}
              />
            </div>
            
            {/* URL input */}
            <div className="grid gap-2">
              <Label htmlFor="image-url">Image URL</Label>
              <Input
                id="image-url"
                placeholder="Enter image URL"
                value={pendingUrl}
                onChange={(e) => onPendingUrlChange(e.target.value)}
              />
            </div>
            
            {/* Image tracking info */}
            <ImageTrackingStatus imageId={imageId} originalUrl={originalUrl} />
          </div>
        </div>
        
        <DialogFooter className="flex justify-between">
          <div>
            {isUrlModified && (
              <Button 
                variant="outline" 
                onClick={handleReset}
              >
                Reset to Original
              </Button>
            )}
          </div>
          <div className="flex gap-2">
            <Button 
              variant="outline" 
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditImageDialog;
