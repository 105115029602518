
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { hasBeenEdited, getActiveImageUrl } from '@/utils/imageLoader/validationUtils';

interface ImageTrackingStatusProps {
  imageId: string;
  originalUrl: string;
}

export const ImageTrackingStatus = ({ imageId, originalUrl }: ImageTrackingStatusProps) => {
  const isEdited = hasBeenEdited(imageId);
  const activeUrl = getActiveImageUrl(imageId, originalUrl);
  
  return (
    <Card className="border-dashed">
      <CardHeader className="pb-2">
        <div className="flex items-center justify-between">
          <CardTitle className="text-sm">Image Tracking</CardTitle>
          {isEdited ? (
            <Badge variant="default" className="text-xs">Edited</Badge>
          ) : (
            <Badge variant="outline" className="text-xs">Original</Badge>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-2 text-xs">
        <div>
          <span className="text-muted-foreground">ID:</span> 
          <code className="ml-1 bg-muted px-1 py-0.5 rounded text-xs">
            {imageId}
          </code>
        </div>
        <div className="truncate">
          <span className="text-muted-foreground">Original:</span> 
          <span className="ml-1 italic">{originalUrl.substring(0, 30)}...</span>
        </div>
        <div className="truncate">
          <span className="text-muted-foreground">Active:</span> 
          <span className="ml-1 italic">{activeUrl.substring(0, 30)}...</span>
        </div>
      </CardContent>
    </Card>
  );
};

export default ImageTrackingStatus;
