
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { logErrorBoundaryError } from '@/utils/errorTrackingService';
import { AlertCircle, RefreshCw } from 'lucide-react';

interface Props {
  children: ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to our backend
    try {
      logErrorBoundaryError(error, errorInfo);
    } catch (e) {
      console.error("Failed to log error:", e);
    }
    
    this.setState({ errorInfo });
    console.error("Uncaught error:", error, errorInfo);
  }

  private handleReset = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  }

  public render() {
    if (this.state.hasError) {
      // Custom fallback UI if provided
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      // Default fallback UI - with minimal styling to ensure it renders
      return (
        <div style={{ 
          padding: '20px', 
          margin: '20px', 
          border: '1px solid #f88',
          borderRadius: '8px',
          backgroundColor: '#fff', 
          color: '#333'
        }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ marginRight: '10px', color: '#e11' }}>⚠️</div>
            <h2 style={{ margin: 0, fontSize: '18px' }}>Something went wrong</h2>
          </div>
          
          <div style={{ 
            padding: '10px', 
            background: '#f5f5f5', 
            borderRadius: '4px',
            marginBottom: '15px',
            fontSize: '14px',
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            maxHeight: '200px'
          }}>
            {this.state.error?.message || "An unknown error occurred"}
          </div>
          
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              onClick={this.handleReset}
              style={{
                padding: '8px 16px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                background: '#f5f5f5',
                cursor: 'pointer'
              }}
            >
              Try Again
            </button>
            
            <button
              onClick={() => window.location.reload()}
              style={{
                padding: '8px 16px',
                border: '1px solid #33a',
                borderRadius: '4px',
                background: '#33a',
                color: 'white',
                cursor: 'pointer'
              }}
            >
              Reload Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
