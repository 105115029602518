
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { componentToString } from '@/utils/inputHelpers';
import { PCBuild, UseCase } from '@/utils/pcBuilder/types';
import { Badge } from '@/components/ui/badge';
import { Monitor } from 'lucide-react';
import { Button } from '@/components/ui/button';
import ImageValidatorItem from '@/components/ImageValidator/ImageValidatorItem';

interface OptimalBuildDisplayProps {
  build: PCBuild;
  useCase?: UseCase;
  onProceedToCustomize?: () => void;
}

const OptimalBuildDisplay: React.FC<OptimalBuildDisplayProps> = ({ 
  build, 
  useCase,
  onProceedToCustomize 
}) => {
  return (
    <Card className="w-full">
      <CardHeader className="pb-2">
        <CardTitle className="text-xl flex items-center gap-2">
          <Monitor className="h-5 w-5" />
          {build.name}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-4 aspect-video w-full overflow-hidden rounded-md">
          <ImageValidatorItem 
            src={build.imageUrl || ''} 
            alt={build.name}
            className="w-full h-full object-cover"
            category={Array.isArray(build.useCase) ? build.useCase[0] : build.useCase}
          />
        </div>
        
        <div className="grid gap-4">
          <div className="flex justify-between items-center">
            <span className="text-muted-foreground">Price</span>
            <span className="font-semibold">${build.price.toLocaleString()}</span>
          </div>
          
          <div className="flex flex-wrap gap-1 mb-2">
            {Array.isArray(build.useCase) ? (
              build.useCase.map((use) => (
                <Badge key={use} variant="secondary">{use}</Badge>
              ))
            ) : (
              <Badge variant="secondary">{build.useCase}</Badge>
            )}
          </div>
          
          <div>
            <h4 className="font-medium mb-2">Components</h4>
            <ul className="space-y-1 text-sm">
              <li className="flex justify-between">
                <span className="text-muted-foreground">CPU:</span>
                <span>{componentToString(build.components.cpu)}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-muted-foreground">GPU:</span>
                <span>{componentToString(build.components.gpu)}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-muted-foreground">RAM:</span>
                <span>{componentToString(build.components.ram)}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-muted-foreground">Storage:</span>
                <span>{componentToString(build.components.storage)}</span>
              </li>
            </ul>
          </div>
          
          {onProceedToCustomize && (
            <Button 
              onClick={onProceedToCustomize}
              className="w-full mt-2"
            >
              Customize This Build
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default OptimalBuildDisplay;
