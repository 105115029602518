
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import ImageValidatorItem from '@/components/ImageValidator/ImageValidatorItem';
import { ImageEditButton } from '@/components/ImageEditor';
import { formatPrice } from '@/utils/pcBuilder';
import { ensureStringArray } from '@/utils/inputHelpers';
import { componentToString } from '@/utils/componentHelpers';

interface PCBuildComponents {
  cpu: string | any;
  gpu: string | any;
  ram: string | any;
  storage: string | any[];
  case: string | any;
  cooling: string | any;
  motherboard?: string | any;
  psu?: string | any;
}

interface PCBuild {
  id: string;
  name: string;
  description?: string;
  imageUrl?: string;
  price: number;
  components: PCBuildComponents;
  useCase: string[] | string;
  theme: string[] | string;
}

interface PCConfigCardProps {
  build: PCBuild;
  onClick?: () => void;
  isSelected?: boolean;
  showDetails?: boolean;
  className?: string;
  showEditButton?: boolean;
}

const PCConfigCard = ({
  build,
  onClick,
  isSelected = false,
  showDetails = false,
  className,
  showEditButton = false
}: PCConfigCardProps) => {
  // Generate a consistent ID for this build's image
  const imageId = build.id || `build-${build.name.toLowerCase().replace(/\s+/g, '-')}`;
  
  // Check if we have a valid image URL to display
  const hasValidImage = build.imageUrl && build.imageUrl.trim() !== '';
  
  // Convert to string arrays to safely handle
  const useCaseArray = ensureStringArray(build.useCase);
  const themeArray = ensureStringArray(build.theme);
  
  return (
    <div
      className={cn(
        "group flex flex-col overflow-hidden rounded-lg border transition-colors relative",
        isSelected ? "border-primary bg-primary/5" : "border-border hover:border-primary/50",
        onClick ? "cursor-pointer" : "",
        className
      )}
      onClick={onClick}
    >
      <div className="relative pt-[56.25%] bg-muted">
        <ImageValidatorItem
          src={build.imageUrl || ''}
          alt={build.name}
          className="absolute inset-0"
          category="pc-build"
        />
        
        {showEditButton && (
          <div className="absolute top-2 right-2 z-30">
            <ImageEditButton
              imageId={imageId}
              imageUrl={build.imageUrl}
              category="pc-build"
              variant="secondary"
            />
          </div>
        )}
      </div>
      
      <div className="flex-grow p-4">
        <div className="mb-1 flex items-start justify-between gap-2">
          <h3 className="font-semibold line-clamp-1">{build.name}</h3>
          <span className="text-sm font-medium whitespace-nowrap">
            {build.price ? formatPrice(build.price) : 'Price N/A'}
          </span>
        </div>
        
        <p className="text-sm text-muted-foreground line-clamp-2 mb-3">
          {build.description}
        </p>
        
        <div className="flex flex-wrap gap-1 mt-auto">
          {useCaseArray.slice(0, 2).map((use, index) => (
            <Badge key={`${use}-${index}`} variant="secondary" className="text-xs font-normal capitalize">
              {use.replace('-', ' ')}
            </Badge>
          ))}
          
          {themeArray.slice(0, 2).map((theme, index) => (
            <Badge key={`${theme}-${index}`} variant="outline" className="text-xs font-normal capitalize">
              {theme}
            </Badge>
          ))}
        </div>
      </div>
      
      {showDetails && (
        <div className="border-t p-4 grid gap-2 text-sm">
          <div className="grid grid-cols-2 gap-2">
            <div>
              <span className="text-muted-foreground">CPU:</span> {componentToString(build.components.cpu)}
            </div>
            <div>
              <span className="text-muted-foreground">GPU:</span> {componentToString(build.components.gpu)}
            </div>
            <div>
              <span className="text-muted-foreground">RAM:</span> {componentToString(build.components.ram)}
            </div>
            <div>
              <span className="text-muted-foreground">Storage:</span> {componentToString(build.components.storage)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PCConfigCard;
