
import { PCBuild } from '@/utils/pcBuilder';
import { getDefaultPerformance } from '@/utils/pcBuilder/defaultPerformance';

// Specialty form factor and purpose PC builds
const specialtyBuilds: PCBuild[] = [
  {
    id: 'compact-powerhouse',
    name: 'SFF Gaming PC',
    description: 'Versatile small form factor PC that excels in any task while maintaining a compact footprint.',
    imageUrl: '/lovable-uploads/3f0f2a92-28c9-4bf0-83af-d31950ddecf5.png',
    price: 1599,
    components: {
      cpu: 'AMD Ryzen 7 7800X3D',
      motherboard: 'ASUS ROG Strix B650E-I Gaming WiFi',
      gpu: 'Nvidia RTX 4070 12GB',
      ram: '32GB (2x16GB) DDR5-6000 CL30',
      storage: '2TB NVMe Gen4 SSD',
      case: 'SSUPD Meshroom S',
      psu: '750W 80+ Gold SFX',
      cooling: 'Noctua NH-L12S'
    },
    useCase: ['gaming', 'streaming', 'content-creation', 'office-work', 'programming', 'general-use', 'trading'],
    theme: ['compact', 'minimal'],
    isValidated: true,
    performance: getDefaultPerformance(1599)
  },
  {
    id: 'silent-operator',
    name: 'Silent Gaming PC',
    description: 'Ultra-quiet PC for distraction-free work and gaming.',
    imageUrl: '/lovable-uploads/0e48c2b9-aa4a-4b08-a7c8-07ac9d4cfa2e.png',
    price: 1799,
    components: {
      cpu: 'Intel Core i7-13700F',
      motherboard: 'MSI MAG B760M Mortar WiFi',
      gpu: 'Nvidia RTX 4070 Super 12GB',
      ram: '32GB (2x16GB) DDR4-3600',
      storage: '2TB NVMe SSD',
      case: 'be quiet! Pure Base 500DX',
      psu: '850W 80+ Gold',
      cooling: 'be quiet! Dark Rock Pro 4'
    },
    useCase: ['office-work', 'programming', 'gaming', 'general-use'],
    theme: ['minimal', 'stealth'],
    isValidated: true,
    performance: getDefaultPerformance(1799)
  },
  {
    id: 'streaming-specialist',
    name: 'Streaming Specialist',
    description: 'Optimized for content streaming with dedicated encoding capabilities.',
    imageUrl: '/lovable-uploads/76ceccf9-f8f3-45ca-8aca-7e7cad4f4970.png',
    price: 1999,
    components: {
      cpu: 'AMD Ryzen 9 7900X',
      motherboard: 'ASUS ROG Strix X670E-E Gaming WiFi',
      gpu: 'Nvidia RTX 4070 Ti 12GB',
      ram: '32GB (2x16GB) DDR5-6000',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Corsair 5000D Airflow',
      psu: '850W 80+ Gold',
      cooling: 'ARCTIC Liquid Freezer II 280'
    },
    useCase: ['streaming'],
    theme: ['sleek', 'rgb'],
    isValidated: true,
    performance: getDefaultPerformance(1999)
  },
  {
    id: 'compact-creator',
    name: 'Compact Creator PC',
    description: 'Small form factor PC for content creation with powerful components.',
    imageUrl: '/lovable-uploads/3f0f2a92-28c9-4bf0-83af-d31950ddecf5.png',
    price: 1899,
    components: {
      cpu: 'AMD Ryzen 9 7950X',
      motherboard: 'ASRock X670E Valkyrie ITX',
      gpu: 'Nvidia RTX 4070 12GB',
      ram: '64GB (2x32GB) DDR5-5600',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Cooler Master NR200P',
      psu: '850W 80+ Platinum SFX',
      cooling: 'Noctua NH-L12 Ghost S1 Edition'
    },
    useCase: ['content-creation', 'programming'],
    theme: ['compact', 'sleek'],
    isValidated: true,
    performance: getDefaultPerformance(1899)
  },
  {
    id: 'trading-compact',
    name: 'Compact Trading Station',
    description: 'Space-efficient PC optimized for financial trading and multi-monitor setups.',
    imageUrl: '/lovable-uploads/3f0f2a92-28c9-4bf0-83af-d31950ddecf5.png',
    price: 1599,
    components: {
      cpu: 'Intel Core i7-14700',
      motherboard: 'ASRock B760M Pro RS/D4',
      gpu: 'Nvidia RTX 4060 Ti 16GB',
      ram: '32GB (2x16GB) DDR4-3600',
      storage: '2TB NVMe Gen4 SSD',
      case: 'Fractal Design Pop Mini Air',
      psu: '650W 80+ Gold',
      cooling: 'DeepCool AK400'
    },
    useCase: ['trading', 'office-work'],
    theme: ['compact', 'stealth'],
    isValidated: true,
    performance: getDefaultPerformance(1599)
  }
];

export default specialtyBuilds;
