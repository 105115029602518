
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { ArrowRight, Home } from 'lucide-react';
import { allGuides } from '@/data/guides';

const Guides = () => {
  const navigate = useNavigate();
  
  return (
    <div className="container mx-auto py-8 px-4">
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold mb-4">PC Building Guides</h1>
          <Button 
            variant="outline" 
            size="sm" 
            className="flex items-center gap-2"
            onClick={() => navigate('/')}
          >
            <Home className="h-4 w-4" />
            Back to Home
          </Button>
        </div>
        <p className="text-muted-foreground max-w-3xl">
          Explore our comprehensive collection of PC building guides, tutorials, and expert advice. Whether you're a beginner or an experienced builder, we have resources to help you create the perfect custom PC.
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {allGuides.map(guide => (
          <Card key={guide.id} className="overflow-hidden flex flex-col h-full hover:shadow-lg transition-shadow">
            <div className="relative h-48 overflow-hidden">
              <img 
                src={guide.image} 
                alt={guide.title} 
                className="w-full h-full object-cover transition-transform hover:scale-105 duration-300"
              />
              <div className="absolute top-4 right-4">
                <Badge variant="secondary" className="font-medium">
                  {guide.category}
                </Badge>
              </div>
            </div>
            
            <CardHeader className="pb-2">
              <div className="flex items-center gap-2 mb-2">
                <div className="p-1.5 rounded-full bg-primary/10 text-primary">
                  {guide.icon}
                </div>
                <CardTitle className="text-xl">{guide.title}</CardTitle>
              </div>
              <CardDescription>{guide.description}</CardDescription>
            </CardHeader>
            
            <CardContent className="flex-grow">
              <div className="prose prose-sm dark:prose-invert line-clamp-3">
                {guide.content.split('\n').slice(0, 3).join('\n')}
              </div>
            </CardContent>
            
            <CardFooter>
              <Link to={`/guides/${guide.id}`} state={{ guide }}>
                <Button variant="outline" className="w-full flex items-center justify-center gap-2">
                  Read Full Guide
                  <ArrowRight className="h-4 w-4" />
                </Button>
              </Link>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Guides;
