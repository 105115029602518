
import { PCBuild } from '@/utils/pcBuilder';
import { getDefaultPerformance } from '@/utils/pcBuilder/defaultPerformance';

// Budget and entry-level PC builds
const budgetBuilds: PCBuild[] = [
  {
    id: 'budget-beast',
    name: 'Budget Gaming PC',
    description: 'Entry-level gaming PC with solid 1080p performance at affordable price.',
    imageUrl: 'https://images.unsplash.com/photo-1587202372616-b43abea06c2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=80',
    price: 699,
    components: {
      cpu: 'Intel Core i5-13400F',
      motherboard: 'MSI PRO B760M-P DDR4',
      gpu: 'AMD Radeon RX 6600',
      ram: '16GB (2x8GB) DDR4-3600 CL18',
      storage: '1TB NVMe SSD',
      case: 'Corsair 4000D Airflow',
      psu: '600W 80+ Bronze',
      cooling: 'Stock CPU Cooler'
    },
    useCase: ['gaming', 'office-work', 'general-use'],
    theme: ['minimal', 'compact'],
    isValidated: true,
    performance: getDefaultPerformance(699)
  },
  {
    id: 'mid-range-performer',
    name: 'Mid-Range Gaming PC',
    description: 'Balanced 1440p gaming rig with room for upgrades.',
    imageUrl: 'https://images.unsplash.com/photo-1615839518450-6f94fee31432?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3386&q=80',
    price: 1099,
    components: {
      cpu: 'Intel Core i5-13600K',
      motherboard: 'MSI PRO Z690-A DDR4',
      gpu: 'Nvidia RTX 4060 Ti 8GB',
      ram: '32GB (2x16GB) DDR4-3600 CL18',
      storage: '1TB NVMe Gen4 SSD',
      case: 'Corsair 4000D Airflow',
      psu: '750W 80+ Gold',
      cooling: 'DeepCool AK620'
    },
    useCase: ['gaming', 'streaming', 'general-use'],
    theme: ['minimal', 'rgb'],
    isValidated: true,
    performance: getDefaultPerformance(1099)
  },
  {
    id: 'office-professional',
    name: 'Office Professional',
    description: 'Reliable and silent PC for productivity and business applications.',
    imageUrl: 'https://images.unsplash.com/photo-1593640408182-31c70c8268f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2542&q=80',
    price: 899,
    components: {
      cpu: 'Intel Core i5-13400',
      motherboard: 'ASUS Prime B760-Plus',
      gpu: 'Intel Arc A580 8GB',
      ram: '16GB (2x8GB) DDR4-3200',
      storage: '1TB NVMe SSD + 2TB HDD',
      case: 'Fractal Design Define 7',
      psu: '550W 80+ Gold',
      cooling: 'Stock CPU Cooler'
    },
    useCase: ['office-work', 'general-use', 'programming'],
    theme: ['minimal', 'stealth'],
    isValidated: true,
    performance: getDefaultPerformance(899)
  }
];

export default budgetBuilds;
