
import { useState } from 'react';
import { useUserContext } from '@/contexts/UserContext';
import { Link } from 'react-router-dom';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardFooter 
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { 
  User, 
  Clock, 
  Trash2, 
  ArrowRight,
  PenSquare,
  LogOut,
  Mail,
  MapPin,
  Phone
} from 'lucide-react';
import { useFirebaseAuth } from '@/contexts/FirebaseAuthContext';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { toast } from 'sonner';

const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
};

const UserAccount = () => {
  const { currentUser, logOut } = useFirebaseAuth();
  const { savedBuilds, deleteSavedBuild } = useUserContext();
  const [activeTab, setActiveTab] = useState('account');
  const [isSigningOut, setIsSigningOut] = useState(false);

  const handleSignOut = async () => {
    setIsSigningOut(true);
    try {
      await logOut();
      toast.success("Signed out successfully", {
        description: "You have been logged out of your account",
      });
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Error signing out", {
        description: "There was a problem signing out. Please try again.",
      });
    } finally {
      setIsSigningOut(false);
    }
  };

  const getUserInitials = () => {
    if (!currentUser?.displayName) return 'U';
    return currentUser.displayName
      .split(' ')
      .map(name => name[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Card className="w-full shadow-lg">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle className="flex items-center gap-2">
            <User className="h-5 w-5" />
            My Account
          </CardTitle>
          <Button 
            variant="outline" 
            className="flex items-center gap-2"
            onClick={handleSignOut}
            disabled={isSigningOut}
          >
            {isSigningOut ? (
              <div className="animate-spin h-4 w-4 border-b-2 border-current rounded-full"></div>
            ) : (
              <LogOut className="h-4 w-4" />
            )}
            Sign Out
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="w-full">
            <TabsTrigger value="account" className="flex-1">Account Info</TabsTrigger>
            <TabsTrigger value="builds" className="flex-1">Saved Builds</TabsTrigger>
          </TabsList>
          
          <TabsContent value="account" className="pt-4">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="flex flex-col items-center space-y-3">
                <Avatar className="h-24 w-24">
                  {currentUser?.photoURL ? (
                    <AvatarImage src={currentUser.photoURL} alt={currentUser.displayName || 'User'} />
                  ) : (
                    <AvatarFallback className="text-xl">{getUserInitials()}</AvatarFallback>
                  )}
                </Avatar>
                <h3 className="text-lg font-medium">{currentUser?.displayName || 'User'}</h3>
              </div>
              
              <div className="flex-1 space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <Mail className="h-4 w-4 text-muted-foreground" />
                      <h3 className="text-sm font-medium text-muted-foreground">Email</h3>
                    </div>
                    <p className="text-base">{currentUser?.email || 'Not available'}</p>
                  </div>
                  
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <MapPin className="h-4 w-4 text-muted-foreground" />
                      <h3 className="text-sm font-medium text-muted-foreground">Address</h3>
                    </div>
                    <p className="text-base text-muted-foreground">No address information</p>
                  </div>
                  
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <Phone className="h-4 w-4 text-muted-foreground" />
                      <h3 className="text-sm font-medium text-muted-foreground">Phone</h3>
                    </div>
                    <p className="text-base text-muted-foreground">No phone information</p>
                  </div>
                </div>
              </div>
            </div>
          </TabsContent>
          
          <TabsContent value="builds" className="pt-4">
            {savedBuilds.length > 0 ? (
              <div className="space-y-4">
                {savedBuilds.map(build => (
                  <div 
                    key={build.id} 
                    className="border rounded-md p-4 flex justify-between items-center"
                  >
                    <div>
                      <h3 className="font-medium">{build.name}</h3>
                      <div className="flex items-center gap-1 text-sm text-muted-foreground">
                        <Clock className="h-3 w-3" />
                        Saved on {formatDate(build.createdAt)}
                      </div>
                    </div>
                    
                    <div className="flex gap-2">
                      <Button 
                        variant="destructive" 
                        size="icon"
                        onClick={() => deleteSavedBuild(build.id)}
                        title="Delete build"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                      
                      <Link to="/" state={{ buildId: build.buildId }}>
                        <Button 
                          variant="outline" 
                          size="icon"
                          title="View build"
                        >
                          <ArrowRight className="h-4 w-4" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-8 space-y-4">
                <p className="text-muted-foreground">No saved builds yet</p>
                <Link to="/">
                  <Button variant="outline" className="gap-2">
                    <PenSquare className="h-4 w-4" />
                    Create a Build
                  </Button>
                </Link>
              </div>
            )}
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default UserAccount;
