
import React from 'react';
import { BookOpen, Monitor, Cpu, HardDrive, Zap } from 'lucide-react';

interface GuideIconMap {
  [key: string]: JSX.Element;
}

// Map of guide categories to icons
const guideIcons: GuideIconMap = {
  'Beginner': React.createElement(BookOpen, { className: "h-5 w-5" }),
  'Gaming': React.createElement(Monitor, { className: "h-5 w-5" }),
  'Professional': React.createElement(Cpu, { className: "h-5 w-5" }),
  'Hardware': React.createElement(HardDrive, { className: "h-5 w-5" }),
  'Advanced': React.createElement(Zap, { className: "h-5 w-5" })
};

export const allGuides = [
  {
    id: 'pc-building-basics',
    title: 'PC Building Basics',
    description: 'Everything you need to know to build your first PC from scratch.',
    image: '/placeholder-tech.jpg',
    category: 'Beginner',
    icon: guideIcons['Beginner'],
    content: `
      # PC Building for Beginners

      ## Introduction
      
      Building your own PC can seem daunting at first, but it's actually one of the most rewarding tech experiences. This guide will walk you through the entire process step by step, from selecting components to powering on your new PC for the first time.

      ## Understanding PC Components

      Before building a PC, it's important to understand what each component does. The motherboard is the foundation that connects everything together. The CPU (Central Processing Unit) is the brain of your computer. RAM (Random Access Memory) is temporary storage used while your computer is running. Storage devices like SSDs and HDDs save your data permanently. The GPU (Graphics Processing Unit) handles visual rendering. The power supply provides electricity to all components. The case houses and protects everything.

      ### Tips
      - Start by researching each component to understand its role
      - Look at example builds to see how components work together
      - Create a budget before shopping for components
      - Check compatibility between parts before purchasing

      ## Selecting Compatible Parts

      Not all PC components work together. For example, a CPU must be compatible with your motherboard's socket type. RAM must match the motherboard's supported memory type. Graphics cards need adequate power and physical space. When selecting parts, use PC builder tools to verify compatibility or research the specifications for each component.

      ### Tips
      - Check CPU and motherboard socket compatibility
      - Ensure your case can fit all components, especially the GPU
      - Verify RAM type (DDR4/DDR5) matches your motherboard
      - Calculate your total power draw and add 20% buffer for your PSU

      ## Step-by-Step Assembly Guide

      Building a PC is like putting together a high-tech puzzle. Start by installing the CPU, RAM, and M.2 drives on the motherboard before placing it in the case. Mount the power supply, connect storage drives, and install the graphics card. Finally, connect all the necessary cables following your motherboard's manual, and boot up your new system.

      ### Tips
      - Work in a clean, static-free environment
      - Install the CPU, CPU cooler, and RAM before mounting the motherboard
      - Route cables behind the motherboard tray for better airflow
      - Don't force any components – if something doesn't fit, double-check compatibility

      ## Installing Windows & Essential Software

      After your PC boots successfully, you'll need to install an operating system. Windows is popular for gaming and general use, while Linux offers more customization. Create a bootable USB drive with your OS, boot from it, and follow the installation wizard. Afterward, install drivers for your components, update the system, and install essential software like browsers, antivirus, and productivity tools.

      ### Tips
      - Create a bootable USB drive using Microsoft's Media Creation Tool for Windows
      - Install graphics card drivers directly from NVIDIA or AMD websites
      - Update Windows immediately after installation
      - Consider using Ninite to quickly install common applications

      ## First Boot Troubleshooting

      If your PC doesn't boot properly the first time, don't panic. Common issues include loose power connections, improperly seated RAM, or missing CPU power. Check all connections, ensure components are properly seated, and verify that your monitor is connected to the right port. If you see a display but encounter errors, you may need to update your BIOS or drivers.

      ### Tips
      - If the PC doesn't power on, check the power supply switch and connections
      - If you get no display, verify your monitor is connected to the GPU, not the motherboard (unless using integrated graphics)
      - Try booting with just one RAM stick if experiencing memory issues
      - Listen for beep codes which can indicate specific hardware problems
    `
  },
  {
    id: 'overclocking-guide',
    title: 'Overclocking Guide',
    description: 'Safely push your components to their limits for better performance.',
    image: '/placeholder-tech.jpg',
    category: 'Advanced',
    icon: guideIcons['Advanced'],
    content: `
      # Overclocking Guide

      ## Understanding Overclocking Basics

      Overclocking is the process of running your computer components at speeds higher than their official specifications. This can improve performance in games and applications, but comes with increased heat output and power consumption. Before starting, understand that overclocking may void warranties and, if done improperly, can damage your components.

      ### Tips
      - Make sure you have adequate cooling before attempting to overclock
      - Research your specific CPU/GPU model to understand its overclocking potential
      - Plan for higher power consumption when overclocking
      - Consider the age of your components - newer hardware often has better overclocking headroom

      ## CPU Overclocking

      CPU overclocking involves increasing the multiplier, base clock, or both to achieve higher frequencies. Modern CPUs often allow overclocking directly through the BIOS/UEFI. Start with small increases in the multiplier, then test stability with stress testing software like Prime95 or AIDA64. Monitor temperatures carefully - most CPUs should stay under 85°C under full load.

      ### Tips
      - Enter your BIOS/UEFI by pressing the appropriate key during boot (often Delete, F2, or F12)
      - Increase multiplier in small increments (0.5-1.0) and test stability after each change
      - If your CPU has "boost" technology, you may need to disable it before manual overclocking
      - Use tools like CPU-Z and HWMonitor to verify clock speeds and temperatures

      ## GPU Overclocking

      GPU overclocking is typically done through software like MSI Afterburner or EVGA Precision X1. The process involves increasing the core clock, memory clock, and sometimes adjusting power limits and voltage. Start with small increments (25-50MHz) for the core clock and slightly larger steps for memory clock. Test stability with benchmarks like 3DMark or actual games you play regularly.

      ### Tips
      - Increase core clock first, then memory clock separately to isolate any stability issues
      - Look for artifacts (graphical glitches) which indicate an unstable overclock
      - Consider creating a custom fan curve to maintain lower temperatures
      - Some GPUs will "thermal throttle" and reduce performance if they get too hot

      ## RAM Overclocking & XMP Profiles

      RAM overclocking can be as simple as enabling XMP (Extreme Memory Profile) in BIOS, which automatically sets your RAM to run at its advertised speeds. For manual overclocking, you can adjust frequency, timings, and voltage. Start by enabling XMP, then if you want more performance, carefully increase frequency while loosening timings or increasing voltage slightly.

      ### Tips
      - Enable XMP/DOCP as your first step - this alone can provide significant performance improvements
      - If manually overclocking, take note of all original timings before making changes
      - Test RAM stability with tools like MemTest86
      - RAM overclocking results can vary significantly based on your CPU's memory controller

      ## Stress Testing & Stability

      After overclocking, thorough stability testing is essential. For CPUs, run Prime95 or AIDA64 stress tests for at least an hour. GPUs can be tested with Furmark, Unigine Heaven, or demanding games. RAM should be tested with MemTest86 for several passes. If any tests fail or you experience crashes, reduce your overclock or increase voltage (within safe limits).

      ### Tips
      - Run a variety of stress tests, as some may find instabilities that others miss
      - Test real-world usage with your typical workloads or games
      - Consider overnight stress testing for critical systems
      - Keep a record of successful settings in case you need to reset your BIOS
    `
  },
  {
    id: 'building-a-gaming-pc',
    title: 'Building a Gaming PC',
    description: 'Specialized guide for creating the ultimate gaming rig with the best performance for your budget.',
    image: '/placeholder-tech.jpg',
    category: 'Gaming',
    icon: guideIcons['Gaming'],
    content: `
      # Building a Gaming PC

      ## Choosing the Right Components for Gaming

      When building a gaming PC, you need to prioritize certain components differently than for other uses. The graphics card (GPU) is typically the most important component and should receive the largest portion of your budget. A capable CPU is also crucial, but many games don't fully utilize more than 6 cores. Fast RAM (16GB minimum, preferably 32GB) and an SSD for storage will ensure quick loading times.

      ### Tips
      - Allocate around 30-40% of your budget to the GPU for a balanced gaming build
      - For 1080p gaming, consider an RTX 3060 or RX 6600 XT; for 1440p, look at RTX 3070/4070 or RX 6700 XT; for 4K, an RTX 3080/4080 or RX 6800 XT and above
      - Choose a CPU with good single-thread performance - Intel Core i5/i7 or AMD Ryzen 5/7 are excellent choices
      - Don't overspend on the motherboard; put that money toward a better GPU instead

      ## Optimizing for Different Game Types

      Different game genres have different hardware demands. Competitive esports titles like CS:GO, Valorant, or Overwatch benefit from high refresh rate monitors and CPUs with strong single-thread performance. Open-world games and AAA titles are more GPU-dependent and may benefit from more VRAM. Strategy games and simulators might require more CPU cores and RAM.

      ### Tips
      - For competitive FPS games, prioritize a high refresh rate monitor (144Hz+) and a CPU with strong single-core performance
      - For open-world games, invest in a GPU with 8GB+ VRAM and plenty of system RAM
      - If you play a mix of games, aim for a balanced build with a 6-8 core CPU and mid to high-end GPU
      - Flight simulators and strategy games benefit from faster CPUs and more RAM

      ## Monitor Selection Guide

      Your monitor is just as important as your internal components for a good gaming experience. Consider resolution (1080p, 1440p, 4K), refresh rate (Hz), panel type (IPS, VA, TN), and adaptive sync technology (G-Sync, FreeSync). Higher resolutions provide sharper images but require more powerful GPUs. Higher refresh rates give smoother motion but are most beneficial in fast-paced games.

      ### Tips
      - Match your monitor to your GPU: mid-range GPUs for 1080p, high-end for 1440p, and top-tier for 4K
      - For competitive gaming, prioritize refresh rate (144Hz+) over resolution
      - IPS panels offer better color accuracy and viewing angles, while VA provides better contrast, and TN offers fastest response times
      - Ensure your chosen adaptive sync technology (G-Sync/FreeSync) matches your GPU brand for best performance

      ## Cooling and Airflow Optimization

      Gaming PCs generate significant heat, especially when playing demanding titles for extended periods. Proper cooling is essential for maintaining performance and component longevity. Consider aftermarket CPU coolers, cases with good airflow design, and additional case fans. Cable management also impacts airflow, so route cables neatly behind the motherboard tray when possible.

      ### Tips
      - Aim for a positive pressure airflow setup (more intake than exhaust fans) to reduce dust buildup
      - Position fans to create a clear path for air to flow from front/bottom (intake) to back/top (exhaust)
      - For heavy gaming, consider an aftermarket CPU cooler - air coolers offer good value, while AIOs provide better performance for high-end CPUs
      - Monitor your temperatures during gaming sessions - CPUs should generally stay under 80°C and GPUs under 85°C

      ## Streaming Setup Tips

      If you plan to stream your gameplay, you'll need additional hardware considerations. Streaming requires extra CPU cores or a newer GPU with good encoding capabilities. For a dual-PC setup, you'll need a capture card. Don't forget about peripherals like a good microphone, webcam, and lighting, which are essential for a quality streaming experience.

      ### Tips
      - For CPU encoding, aim for at least an 8-core processor like the Ryzen 7 or Intel Core i7
      - For GPU encoding, NVIDIA's NVENC on RTX cards provides excellent quality with minimal performance impact
      - Consider 32GB of RAM for streaming while playing memory-intensive games
      - A second monitor is extremely helpful for managing your stream while gaming
    `
  },
  {
    id: 'advanced-cooling-solutions',
    title: 'Advanced Cooling Solutions',
    description: 'Everything you need to know about cooling your PC, from air cooling to custom liquid cooling loops.',
    image: '/placeholder-tech.jpg',
    category: 'Advanced',
    icon: guideIcons['Advanced'],
    content: `
      # Advanced Cooling Solutions

      ## Understanding Heat in Computer Systems

      Heat is generated primarily by the CPU, GPU, VRMs (Voltage Regulator Modules), and to a lesser extent, RAM and storage devices. Thermal design power (TDP) ratings indicate how much heat a component generates at stock settings. Effective cooling solutions must absorb heat from components, transfer heat away efficiently, and dissipate heat into the surrounding environment.

      ### Tips
      - Monitor temperatures during stress tests to understand your system's thermal profile
      - Most modern CPUs should stay under 80-85°C during heavy loads
      - GPUs typically run hotter, with safe temperatures up to 85-90°C
      - VRM temperatures are often overlooked but should stay under 90°C for longevity

      ## Air Cooling

      Air cooling uses metal heatsinks to absorb heat and fans to move air across fins, dissipating heat into the surrounding environment. There are tower coolers, low-profile coolers, and downdraft coolers. Case fans come in various sizes with different airflow/static pressure characteristics. Fan controllers can be manual knobs, fan hubs, or motherboard PWM control.

      ### Tips
      - High-quality thermal paste application is crucial for effective heat transfer
      - Larger heatsinks and fans generally provide better cooling with less noise
      - Dual tower designs like the Noctua NH-D15 can match AIO liquid coolers in performance
      - Ensure your case has good airflow with intake fans at the front/bottom and exhaust at the top/rear

      ## All-In-One (AIO) Liquid Cooling

      AIO coolers use a closed loop of liquid coolant pumped through a waterblock (on CPU/GPU) to a radiator where fans dissipate the heat. Radiator sizes range from 120mm to 420mm. Pump designs can be integrated with the CPU block or separate. Fan configurations include push, pull, or push-pull.

      ### Tips
      - Radiator placement is important - top mount is ideal to prevent air bubbles in the pump
      - Tubes should not be the highest point in the loop to avoid air accumulation in the pump
      - Larger radiators (280mm/360mm) provide better cooling performance
      - AIOs require less clearance around the CPU socket compared to large air coolers

      ## Custom Water Cooling

      Custom loops offer the highest cooling potential and aesthetic customization but require significant knowledge, effort, and maintenance. Components include waterblocks, pumps, reservoirs, radiators, tubing, fittings, and coolant. Loop order generally follows: Reservoir → Pump → CPU → Radiator → GPU → Radiator → Reservoir.

      ### Tips
      - Always leak test for 24 hours with components unpowered before actual use
      - Use distilled water and appropriate additives to prevent algae/corrosion
      - Plan your loop carefully, considering tube routing and component placement
      - Include a drain port for easier maintenance
      - Change fluid every 6-12 months depending on coolant type

      ## Optimizing Your Cooling Solution

      Case selection is crucial for good cooling. Airflow-focused cases like the Fractal Design Meshify, Corsair 4000D Airflow, or Lian Li O11 Dynamic are popular choices. Fan selection strategy should consider intake fans (higher airflow), radiator fans (higher static pressure), and exhaust fans (balance of both).

      ### Tips
      - Remove unused drive cages and PCI slot covers to improve airflow
      - Consider fan curves in BIOS/software to balance noise and cooling
      - Dust filters are important but require regular cleaning
      - Cable management improves not just aesthetics but also airflow
      - Consider ambient room temperature - cooling is relative to room temperature
    `
  },
  {
    id: 'ultimate-storage-guide',
    title: 'The Ultimate Storage Guide',
    description: 'Comprehensive overview of storage technologies, from HDDs to NVMe SSDs, and how to create the optimal storage setup.',
    image: '/placeholder-tech.jpg',
    category: 'Hardware',
    icon: guideIcons['Hardware'],
    content: `
      # The Ultimate Storage Guide

      ## Storage Technology Overview

      Storage technology has evolved dramatically over the past decade, with numerous options available at different price points and performance levels. Hard Disk Drives (HDDs) are mechanical drives with spinning platters and movable read/write heads. They offer low cost per terabyte and high capacities but have slower access times. SATA Solid State Drives (SSDs) use flash memory connected via the SATA interface, offering much faster speeds than HDDs but limited by the SATA interface (about 550MB/s). NVMe Solid State Drives connect directly to PCIe lanes for extremely high performance, with speeds up to 7000MB/s on PCIe 4.0.

      ### Tips
      - HDDs are best for mass storage of media and backups
      - SATA SSDs offer a good balance of performance and value
      - NVMe drives provide the best performance for OS and frequently accessed applications
      - Consider endurance ratings (TBW) for workloads with heavy write operations

      ## Creating the Optimal Storage Configuration

      A tiered storage approach works best for most users. Tier 1 is your boot/system drive, ideally a 500GB-1TB PCIe 4.0 NVMe SSD for operating system, applications, and frequently used files. Tier 2 is your working data, using a 1TB-2TB SATA SSD or secondary NVMe for current projects, games, and frequently accessed data. Tier 3 is mass storage, using 2TB-20TB HDD array or large QLC SSDs for media libraries, backups, and infrequently accessed files.

      ### Tips
      - Keep 10-20% free space on SSDs for optimal performance
      - Use different drives for different workloads to distribute I/O operations
      - Consider RAID configurations for critical data
      - Modern games benefit significantly from SSD storage for faster loading times

      ## RAID Configurations

      RAID (Redundant Array of Independent Disks) configurations offer different benefits: RAID 0 stripes data across drives for performance but offers no redundancy. RAID 1 mirrors data for redundancy but requires twice the storage. RAID 5 uses striping with parity, offering a good balance for 3+ drives. RAID 10 combines RAID 1+0 for excellent performance and redundancy.

      ### Tips
      - RAID is not a backup solution - always maintain separate backups
      - Software RAID vs. hardware RAID has different performance implications
      - RAID rebuilds can be time-consuming, especially with large drives
      - Consider the rebuild time and failure risk when choosing RAID levels

      ## Optimizing Storage Performance

      SSD optimization involves maintaining 10-20% free space for wear leveling, enabling TRIM, and updating firmware regularly. For file systems, NTFS is standard for Windows with good all-around performance, exFAT offers good cross-platform compatibility, and Btrfs/ZFS provides advanced features for Linux/FreeBSD.

      ### Tips
      - Disable indexing on secondary drives to reduce unnecessary writes
      - Place frequently accessed files on your fastest storage
      - Consider partition alignment for optimal performance
      - Run periodic TRIM commands on SSDs (usually automated in modern OSes)
      - Monitor SSD health using manufacturer utilities or CrystalDiskInfo

      ## Backup Strategies

      Follow the 3-2-1 backup rule: maintain 3 copies of your data, on 2 different media types, with 1 copy stored offsite. Regular SMART monitoring for early failure detection is essential, as is watching for increasing reallocated sectors or CRC errors. Test your backups periodically to ensure they can be restored when needed.

      ### Tips
      - Automate your backup process to ensure consistency
      - Use versioning in your backup solution to recover from ransomware
      - Consider cloud backup services for offsite storage
      - Periodically test your restore process, not just the backup
      - Label external drives and document your backup system
    `
  }
];
