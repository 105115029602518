
import { useState, useEffect, useCallback } from 'react';
import { 
  trackEditedImage, 
  getActiveImageUrl, 
  hasBeenEdited, 
  resetEditedImage 
} from '@/utils/imageLoader/validationUtils';
import { getCategoryFallback } from '@/utils/imageFallbacks';

/**
 * Interface for useImageTracker options
 */
interface ImageTrackerOptions {
  category?: string;
  fallbackUrl?: string;
}

/**
 * Hook to track and manage image edits
 * @param imageId Unique identifier for the image
 * @param originalUrl Original URL of the image
 * @param options Additional options like category and fallback
 * @returns Methods and state to track and manage image edits
 */
export const useImageTracker = (
  imageId: string, 
  originalUrl: string,
  options: ImageTrackerOptions = {}
) => {
  const { category, fallbackUrl } = options;
  
  // Get default fallback URL if not provided
  const defaultFallback = category ? getCategoryFallback(category) : undefined;
  const effectiveFallbackUrl = fallbackUrl || defaultFallback;
  
  // Get current URL (edited or original)
  const currentImageUrl = getActiveImageUrl(imageId, originalUrl);
  
  // Track edit state and urls
  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(hasBeenEdited(imageId));
  const [currentUrl, setCurrentUrl] = useState(currentImageUrl);
  const [pendingUrl, setPendingUrl] = useState(currentImageUrl);
  
  // Update edited state when component mounts
  useEffect(() => {
    setIsEdited(hasBeenEdited(imageId));
    
    // Get latest active URL
    const activeUrl = getActiveImageUrl(imageId, originalUrl);
    setCurrentUrl(activeUrl);
    setPendingUrl(activeUrl);
    
    // Listen for image edit events
    const handleImageEdit = (event: CustomEvent) => {
      const { id, currentUrl } = event.detail;
      if (id === imageId) {
        setIsEdited(true);
        setCurrentUrl(currentUrl);
        setPendingUrl(currentUrl);
      }
    };
    
    // Listen for image reset events
    const handleImageReset = (event: CustomEvent) => {
      const { id } = event.detail;
      if (id === imageId) {
        setIsEdited(false);
        setCurrentUrl(originalUrl);
        setPendingUrl(originalUrl);
      }
    };
    
    // Add event listeners
    window.addEventListener('image-edited', handleImageEdit as EventListener);
    window.addEventListener('image-reset', handleImageReset as EventListener);
    
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('image-edited', handleImageEdit as EventListener);
      window.removeEventListener('image-reset', handleImageReset as EventListener);
    };
  }, [imageId, originalUrl]);
  
  // Start editing process
  const startEditing = useCallback(() => {
    setIsEditing(true);
    setPendingUrl(currentUrl);
  }, [currentUrl]);
  
  // Cancel editing
  const cancelEditing = useCallback(() => {
    setIsEditing(false);
    setPendingUrl(currentUrl);
  }, [currentUrl]);
  
  // Save edited URL
  const saveEdit = useCallback(() => {
    // If no change, just cancel
    if (pendingUrl === currentUrl) {
      setIsEditing(false);
      return;
    }
    
    // Track the edited image
    trackEditedImage(imageId, originalUrl, pendingUrl, category);
    
    // Update state
    setIsEdited(true);
    setCurrentUrl(pendingUrl);
    setIsEditing(false);
  }, [imageId, originalUrl, pendingUrl, currentUrl, category]);
  
  // Reset to original URL
  const resetToOriginal = useCallback(() => {
    resetEditedImage(imageId);
    setIsEdited(false);
    setCurrentUrl(originalUrl);
    setPendingUrl(originalUrl);
    setIsEditing(false);
  }, [imageId, originalUrl]);
  
  // Update pending URL
  const updatePendingUrl = useCallback((url: string) => {
    setPendingUrl(url);
  }, []);
  
  // Use fallback image
  const useFallback = useCallback(() => {
    if (effectiveFallbackUrl) {
      setPendingUrl(effectiveFallbackUrl);
    }
  }, [effectiveFallbackUrl]);
  
  return {
    isEditing,
    isEdited,
    currentUrl,
    pendingUrl,
    originalUrl,
    fallbackUrl: effectiveFallbackUrl,
    startEditing,
    cancelEditing,
    saveEdit,
    resetToOriginal,
    updatePendingUrl,
    useFallback
  };
};
