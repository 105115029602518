
import { PCBuild } from '@/utils/pcBuilder';
import budgetBuilds from './budgetBuilds';
import gamingBuilds from './gamingBuilds';
import workstationBuilds from './workstationBuilds';
import themeBuilds from './themeBuilds';
import specialtyBuilds from './specialtyBuilds';
import { getDefaultPerformance } from '@/utils/pcBuilder/defaultPerformance';

// Helper function to ensure performance metrics exist
const ensurePerformance = (build: any): PCBuild => {
  if (!build.performance) {
    return {
      ...build,
      performance: getDefaultPerformance(build.price)
    };
  }
  return build as PCBuild;
};

// Process all builds to ensure they have performance metrics
const processedBuilds: PCBuild[] = [
  ...budgetBuilds,
  ...gamingBuilds,
  ...workstationBuilds,
  ...themeBuilds,
  ...specialtyBuilds
].map(ensurePerformance);

export default processedBuilds;
