
import { createContext, useContext, useEffect, useState } from 'react';

type Theme = 'dark' | 'light';

type ThemeContextType = {
  theme: Theme;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Apply theme to document function - extracted for immediate use
export function applyThemeToDocument(theme: Theme) {
  try {
    console.log(`Applying theme: ${theme}`);
    if (typeof document === 'undefined') {
      console.log('Document not available, skipping theme application');
      return;
    }
    
    const root = document.documentElement;
    
    // Remove both classes first
    root.classList.remove('dark', 'light');
    
    // Add the current theme class
    root.classList.add(theme);
    
    // Set attribute for CSS selectors
    root.setAttribute('data-theme', theme);
    
    // Force set explicit colors as fallback
    if (theme === 'dark') {
      document.body.style.backgroundColor = 'hsl(222.2 84% 4.9%)';
      document.body.style.color = 'hsl(210 40% 98%)';
    } else {
      document.body.style.backgroundColor = 'white';
      document.body.style.color = 'hsl(222.2 84% 4.9%)';
    }
    
    // Force visibility
    document.body.style.display = 'block';
    document.body.style.visibility = 'visible';
    document.body.style.opacity = '1';
    
    console.log(`Theme applied to document: ${theme}`);
  } catch (error) {
    console.error("Error applying theme to document:", error);
    // Emergency fallback - set direct styles
    if (typeof document !== 'undefined') {
      document.body.style.backgroundColor = theme === 'dark' ? '#1a1a1a' : '#ffffff';
      document.body.style.color = theme === 'dark' ? '#ffffff' : '#1a1a1a';
      document.body.style.display = 'block';
      document.body.style.visibility = 'visible';
    }
  }
}

// Initialize theme before React hydration
export const initializeTheme = (): Theme => {
  console.log("Initializing theme");
  if (typeof window === 'undefined') {
    console.log("Window not available, defaulting to light theme");
    return 'light';
  }
  
  try {
    // Check local storage first
    const savedTheme = localStorage.getItem('theme');
    console.log("Saved theme from localStorage:", savedTheme);
    
    if (savedTheme === 'dark' || savedTheme === 'light') {
      return savedTheme;
    }
    
    // Otherwise check system preference
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      console.log("Using system preference: dark");
      return 'dark';
    }
  } catch (error) {
    console.error("Error initializing theme:", error);
  }
  
  console.log("Defaulting to light theme");
  return 'light';
};

// Immediate self-executing function to apply theme before React
(function() {
  console.log("Self-executing theme initialization");
  if (typeof window !== 'undefined') {
    try {
      const theme = initializeTheme();
      applyThemeToDocument(theme);
      console.log(`Initial theme set and applied: ${theme}`);
    } catch (error) {
      console.error("Error in self-executing theme initialization:", error);
      // Emergency fallback
      if (typeof document !== 'undefined') {
        document.documentElement.classList.add('light');
        document.body.style.backgroundColor = '#ffffff';
        document.body.style.color = '#1a1a1a';
        document.body.style.display = 'block';
      }
    }
  }
})();

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  console.log("ThemeProvider component rendering");
  
  // Ensure we have the theme immediately (non-lazy initialization)
  const [theme, setTheme] = useState<Theme>(() => {
    const initialTheme = initializeTheme();
    console.log("ThemeProvider initial state:", initialTheme);
    return initialTheme;
  });

  // Apply theme immediately and whenever it changes
  useEffect(() => {
    console.log("ThemeProvider effect running to apply theme:", theme);
    
    try {
      // Store theme preference
      if (typeof window !== 'undefined') {
        localStorage.setItem('theme', theme);
      }
      
      // Apply theme to document
      applyThemeToDocument(theme);
      
      console.log(`Theme updated and applied: ${theme}`);
    } catch (error) {
      console.error('Error applying theme:', error);
      // Ensure we still have a visible UI even if theme application fails
      if (typeof document !== 'undefined') {
        document.body.style.backgroundColor = theme === 'dark' ? 'hsl(222.2 84% 4.9%)' : 'white';
        document.body.style.color = theme === 'dark' ? 'hsl(210 40% 98%)' : 'hsl(222.2 84% 4.9%)';
        document.body.style.display = 'block';
        document.body.style.visibility = 'visible';
      }
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prev => {
      const newTheme = prev === 'dark' ? 'light' : 'dark';
      console.log(`Toggling theme to: ${newTheme}`);
      return newTheme;
    });
  };

  // Create context value
  const contextValue = { theme, toggleTheme };
  
  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    console.error('useTheme was called outside of its Provider');
    // Return a default context instead of throwing to prevent crashes
    return { theme: 'light', toggleTheme: () => {} };
  }
  return context;
}
