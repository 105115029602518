
// Log levels
type LogLevel = 'debug' | 'info' | 'warn' | 'error';

// Enable/disable logging based on environment
const isProduction = import.meta.env.PROD;
const LOG_LEVEL: LogLevel = isProduction ? 'warn' : 'debug';

// Determine if a log should be shown based on level
const shouldLog = (level: LogLevel): boolean => {
  const levels: LogLevel[] = ['debug', 'info', 'warn', 'error'];
  const currentIdx = levels.indexOf(LOG_LEVEL);
  const messageIdx = levels.indexOf(level);
  
  return messageIdx >= currentIdx;
};

// Main logger methods
export const logger = {
  debug: (...args: any[]): void => {
    if (shouldLog('debug')) {
      console.debug('[DEBUG]', ...args);
    }
  },
  
  info: (...args: any[]): void => {
    if (shouldLog('info')) {
      console.info('[INFO]', ...args);
    }
  },
  
  warn: (...args: any[]): void => {
    if (shouldLog('warn')) {
      console.warn('[WARN]', ...args);
    }
  },
  
  error: (...args: any[]): void => {
    if (shouldLog('error')) {
      console.error('[ERROR]', ...args);
    }
  },
  
  // Group logs for better organization
  group: (name: string, fn: () => void, collapsed = false): void => {
    if (collapsed) {
      console.groupCollapsed(name);
    } else {
      console.group(name);
    }
    
    fn();
    console.groupEnd();
  },
  
  // Performance timing
  time: (label: string): void => {
    if (!isProduction) {
      console.time(label);
    }
  },
  
  timeEnd: (label: string): void => {
    if (!isProduction) {
      console.timeEnd(label);
    }
  }
};

export default logger;
