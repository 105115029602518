
import { PerformanceRating } from './types';

/**
 * Generate default performance metrics based on PC build price
 */
export const getDefaultPerformance = (price: number): PerformanceRating => {
  // Base performance starts at 50
  let basePerformance = 50;
  
  // Add performance based on price tiers
  if (price >= 800) basePerformance += 10;
  if (price >= 1200) basePerformance += 10;
  if (price >= 1600) basePerformance += 10;
  if (price >= 2000) basePerformance += 5;
  if (price >= 2500) basePerformance += 5;
  if (price >= 3000) basePerformance += 5;
  if (price >= 4000) basePerformance += 5;
  
  // Cap at 100
  basePerformance = Math.min(basePerformance, 100);
  
  // Adjust specific workload performance based on the base
  return {
    gaming: Math.min(Math.round(basePerformance * 1.1), 100),
    productivity: Math.min(Math.round(basePerformance * 0.95), 100),
    videoEditing: Math.min(Math.round(basePerformance * 0.9), 100),
    streaming: Math.min(Math.round(basePerformance * 0.85), 100),
    overall: basePerformance
  };
};

/**
 * Ensure a build has performance metrics
 */
export const addDefaultPerformance = (build: any) => {
  if (!build.performance) {
    return {
      ...build,
      performance: getDefaultPerformance(build.price)
    };
  }
  return build;
};
