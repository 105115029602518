
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseCase, PCBuild } from '@/utils/pcBuilder';
import { selectOptimalComponents } from '@/utils/pcBuilder/bestComponentSelector';
import OptimalBuildDisplay from './OptimalBuildDisplay';
import UseCaseSelector from '@/components/UseCaseSelector';
import BudgetSlider from '@/components/BudgetSlider';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ArrowLeft, ArrowRight, Loader2 } from 'lucide-react';
import presetBuilds from '@/lib/presetBuilds';
import { addDefaultPerformance } from '@/utils/pcBuilder/buildHelper';

const OptimalBuildPage: React.FC = () => {
  const navigate = useNavigate();
  const [useCase, setUseCase] = useState<UseCase>('gaming');
  const [budget, setBudget] = useState<number>(1500);
  const [step, setStep] = useState<number>(1);
  const [optimalBuild, setOptimalBuild] = useState<PCBuild | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      generateOptimalBuild();
    }
  };
  
  const handleBack = () => {
    if (step === 1) {
      navigate('/');
    } else {
      setStep(step - 1);
    }
  };
  
  const generateOptimalBuild = () => {
    setIsLoading(true);
    // Add a small delay to show loading state
    setTimeout(() => {
      // selectOptimalComponents returns a PCBuild now
      const result = selectOptimalComponents(presetBuilds, useCase, budget);
      // Ensure the build has performance metrics
      const buildWithPerformance = addDefaultPerformance(result);
      setOptimalBuild(buildWithPerformance);
      setStep(3);
      setIsLoading(false);
    }, 800);
  };
  
  const handleCustomize = () => {
    // Navigate to the PC Part Picker page
    navigate('/pc-part-picker');
  };
  
  const StepTitle = () => {
    switch (step) {
      case 1: return "What will you use your PC for?";
      case 2: return "What's your budget?";
      case 3: return "Your Optimal PC Build";
      default: return "";
    }
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-secondary/20">
      <div className="container mx-auto py-12 px-4">
        {step < 3 && (
          <Card className="max-w-3xl mx-auto shadow-xl border border-primary/10">
            <div className="h-1 bg-primary/60 rounded-t-lg"></div>
            <CardHeader className="text-center pb-2">
              <CardTitle className="text-2xl md:text-3xl font-heading">
                <StepTitle />
              </CardTitle>
            </CardHeader>
            <CardContent className="pb-8">
              {step === 1 && (
                <UseCaseSelector 
                  selectedUseCase={useCase} 
                  onChange={setUseCase} 
                />
              )}
              
              {step === 2 && (
                <BudgetSlider 
                  value={budget} 
                  onChange={setBudget} 
                />
              )}
              
              <div className="flex justify-between mt-8">
                <Button 
                  variant="outline" 
                  onClick={handleBack}
                  className="gap-1"
                >
                  <ArrowLeft className="h-4 w-4" />
                  Back
                </Button>
                
                <Button 
                  onClick={handleNext}
                  className="gap-1"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      Processing
                    </>
                  ) : (
                    <>
                      Next
                      <ArrowRight className="h-4 w-4" />
                    </>
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
        
        {step === 3 && optimalBuild && (
          <div className="max-w-3xl mx-auto">
            <OptimalBuildDisplay 
              build={optimalBuild}
              useCase={useCase}
              onProceedToCustomize={handleCustomize}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OptimalBuildPage;
