
import { PCBuild, Accessory } from '@/utils/pcBuilder';
import { Card } from '@/components/ui/card';
import PCConfigCard from '@/components/PCConfigCard';
import AccessoryItem from '@/components/AccessoryItem';

interface PurchaseDetailsProps {
  selectedBuild: PCBuild;
  selectedAccessories: Accessory[];
}

const PurchaseDetails = ({ 
  selectedBuild, 
  selectedAccessories 
}: PurchaseDetailsProps) => {
  // Ensure id is present and convert the build to match PCConfigCard's expected type
  const buildWithId = {
    ...selectedBuild,
    id: selectedBuild.id || `build-${Date.now()}`,
    // Ensure theme property is present (it's required by PCBuild type in PCConfigCard)
    name: selectedBuild.name,
    useCase: selectedBuild.useCase,
    theme: selectedBuild.theme || 'minimalist', // Provide a default theme if not present
    price: selectedBuild.price,
    // Convert storage to ensure it matches the expected type
    components: {
      ...selectedBuild.components,
      storage: Array.isArray(selectedBuild.components.storage) 
        ? selectedBuild.components.storage 
        : selectedBuild.components.storage ? [selectedBuild.components.storage] : []
    }
  };
  
  return (
    <div className="space-y-6">
      <Card className="p-4 md:p-6">
        <h2 className="text-xl font-semibold mb-4">Your Selected Build</h2>
        <PCConfigCard build={buildWithId} className="w-full" />
      </Card>
      
      {selectedAccessories.length > 0 && (
        <Card className="p-4 md:p-6">
          <h2 className="text-xl font-semibold mb-4">Selected Accessories</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {selectedAccessories.map(accessory => (
              <AccessoryItem 
                key={accessory.id} 
                accessory={accessory} 
              />
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};

export default PurchaseDetails;
