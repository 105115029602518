
import { createClient } from '@supabase/supabase-js';

// Initialize the Supabase client with your specific Supabase URL and anon key
// Make sure URL includes https:// prefix and no trailing slash
export const supabaseUrl = 'https://rzysgtqfszmweqigaqhz.supabase.co';
export const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ6eXNndHFmc3ptd2VxaWdhcWh6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDE2MzI5NzIsImV4cCI6MjA1NzIwODk3Mn0.uqCDugpqS9tHKPs5VtZ-ERX4oFprkuf34ZeuKxvE5IA';

// Create the Supabase client with additional options for better reliability
export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
  },
  global: {
    headers: {
      'Content-Type': 'application/json',
    },
  },
});

// Add a simple test function to verify connection
export const testSupabaseConnection = async () => {
  try {
    console.log("Testing Supabase connection to:", supabaseUrl);
    console.log("Using key (first 10 chars):", supabaseKey.substring(0, 10) + "...");
    
    // First, test a simple health check to verify the connection
    const { data: healthData, error: healthError } = await supabase.from('health_check')
      .select('*')
      .limit(1)
      .maybeSingle();
    
    // If there's a health check error, it might be that the table doesn't exist
    // Try a different approach - check if we can connect to any existing table
    if (healthError) {
      console.log("Health check table might not exist, trying pc_components table...");
      
      // Try to get the count of pc_components as a simple connection test
      const { count, error } = await supabase
        .from('pc_components')
        .select('*', { count: 'exact', head: true });
      
      if (error) {
        // Still got an error, try one more table as a last resort
        console.log("pc_components table check failed, trying generic connection test...");
        
        // Try to list all tables via the system catalog as a last resort
        const { data, error: tablesError } = await supabase
          .rpc('get_tables');
          
        if (tablesError) {
          console.error('Supabase connection failed:', tablesError);
          return { 
            success: false, 
            message: `Connection failed: ${tablesError.message}`,
            error: tablesError,
            errorType: determineErrorType(tablesError)
          };
        }
        
        return { 
          success: true, 
          message: 'Connection successful! (Used fallback connection test)' 
        };
      }
      
      return { 
        success: true, 
        message: `Connection successful! Found ${count} components in database.`,
        count
      };
    }
    
    return { 
      success: true, 
      message: 'Connection successful! Health check passed.',
      data: healthData
    };
  } catch (error) {
    console.error('Supabase connection test failed:', error);
    
    // More detailed error information and troubleshooting steps
    let errorMessage = 'Unknown connection error';
    let errorType = determineErrorType(error);
    
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    
    return { 
      success: false, 
      message: errorMessage,
      errorType,
      error: error instanceof Error ? error : new Error(errorMessage)
    };
  }
};

// Helper function to determine the type of error
function determineErrorType(error: any): string {
  if (!error) return 'unknown';
  
  const errorMessage = typeof error === 'string' 
    ? error 
    : error.message || JSON.stringify(error);
  
  if (errorMessage.includes('invalid key') || 
      errorMessage.includes('JWT') || 
      errorMessage.includes('auth') || 
      errorMessage.includes('apikey') || 
      errorMessage.includes('credential')) {
    return 'auth';
  }
  
  if (errorMessage.includes('CORS') || 
      errorMessage.includes('network') || 
      errorMessage.includes('fetch') || 
      errorMessage.includes('connection') ||
      errorMessage.includes('invalid path') ||
      errorMessage.includes('failed to fetch')) {
    return 'network';
  }
  
  if (errorMessage.includes('permission') || 
      errorMessage.includes('access') || 
      errorMessage.includes('unauthorized') || 
      errorMessage.includes('not allowed') || 
      errorMessage.includes('forbidden')) {
    return 'permission';
  }
  
  if (errorMessage.includes('timeout') || 
      errorMessage.includes('timed out')) {
    return 'timeout';
  }
  
  return 'unknown';
}
