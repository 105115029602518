
import { PCBuild, UseCase, ThemeOption } from '../pcBuilder';
import { componentToString } from '@/utils/inputHelpers';

export interface OptimalBuildResult extends PCBuild {
  reason?: string;
}

/**
 * Select the optimal PC build based on user preferences
 */
export const selectOptimalComponents = (
  builds: PCBuild[],
  useCase: UseCase,
  budget: number
): PCBuild => {
  // Create a budget range (±10%)
  const minBudget = budget * 0.9;
  const maxBudget = budget * 1.1;
  
  // Filter builds within budget range and matching the use case
  let filteredBuilds = builds.filter(build => {
    // Check budget
    const withinBudget = build.price >= minBudget && build.price <= maxBudget;
    
    // Check use case (either string or array of strings)
    let matchesUseCase = false;
    if (Array.isArray(build.useCase)) {
      matchesUseCase = build.useCase.includes(useCase);
    } else {
      matchesUseCase = build.useCase === useCase;
    }
    
    return withinBudget && matchesUseCase;
  });
  
  // If no builds found in range, get closest match
  if (filteredBuilds.length === 0) {
    // Find the build closest to budget with the right use case
    const buildWithUseCase = builds.filter(build => {
      if (Array.isArray(build.useCase)) {
        return build.useCase.includes(useCase);
      }
      return build.useCase === useCase;
    });
    
    if (buildWithUseCase.length > 0) {
      // Sort by price and find closest to budget
      buildWithUseCase.sort((a, b) => {
        const aDiff = Math.abs(a.price - budget);
        const bDiff = Math.abs(b.price - budget);
        return aDiff - bDiff;
      });
      
      filteredBuilds = [buildWithUseCase[0]];
    } else {
      // Fallback to any builds, closest to budget
      builds.sort((a, b) => {
        const aDiff = Math.abs(a.price - budget);
        const bDiff = Math.abs(b.price - budget);
        return aDiff - bDiff;
      });
      
      filteredBuilds = [builds[0]];
    }
  }
  
  // Sort remaining builds by performance for the selected use case
  filteredBuilds.sort((a, b) => {
    // Get relevant performance metric
    let aScore = 0;
    let bScore = 0;
    
    switch (useCase) {
      case 'gaming':
        aScore = a.performance.gaming || a.performance.overall;
        bScore = b.performance.gaming || b.performance.overall;
        break;
      case 'streaming':
        aScore = a.performance.streaming || a.performance.overall;
        bScore = b.performance.streaming || b.performance.overall;
        break;
      case 'content-creation':
        aScore = a.performance.videoEditing || a.performance.overall;
        bScore = b.performance.videoEditing || b.performance.overall;
        break;
      case 'workstation':
      case 'programming':
      case 'office-work':
        aScore = a.performance.productivity || a.performance.overall;
        bScore = b.performance.productivity || b.performance.overall;
        break;
      default:
        aScore = a.performance.overall;
        bScore = b.performance.overall;
    }
    
    // Sort in descending order (highest performance first)
    return bScore - aScore;
  });
  
  // Return the best build
  return filteredBuilds[0] || builds[0];
};

/**
 * Convert component object or string to string
 */
const safeComponentToString = (component: any): string => {
  return componentToString(component);
};

/**
 * Get component compatibility info between a motherboard and CPU
 */
export const getComponentCompatibility = (
  motherboard: any,
  cpu: any
): { compatible: boolean; reason?: string } => {
  const mbString = safeComponentToString(motherboard);
  const cpuString = safeComponentToString(cpu);
  
  // Simple compatibility check based on strings
  if (cpuString.includes('Intel') && mbString.includes('AMD')) {
    return { 
      compatible: false, 
      reason: 'Intel CPU is not compatible with AMD motherboard' 
    };
  }
  
  if (cpuString.includes('AMD') && mbString.includes('Intel')) {
    return { 
      compatible: false, 
      reason: 'AMD CPU is not compatible with Intel motherboard' 
    };
  }
  
  return { compatible: true };
};

/**
 * Check if a CPU cooler is compatible with a CPU
 */
export const isCoolerCompatibleWithCPU = (
  cooler: any,
  cpu: any
): { compatible: boolean; reason?: string } => {
  const coolerString = safeComponentToString(cooler);
  const cpuString = safeComponentToString(cpu);
  
  // Basic compatibility checks
  if (coolerString.includes('AMD only') && cpuString.includes('Intel')) {
    return { 
      compatible: false, 
      reason: 'This cooler is only compatible with AMD CPUs' 
    };
  }
  
  if (coolerString.includes('Intel only') && cpuString.includes('AMD')) {
    return { 
      compatible: false, 
      reason: 'This cooler is only compatible with Intel CPUs' 
    };
  }
  
  return { compatible: true };
};

/**
 * Check if a GPU will fit in a case
 */
export const isGPUCompatibleWithCase = (
  gpu: any,
  pcCase: any
): { compatible: boolean; reason?: string } => {
  const gpuString = safeComponentToString(gpu);
  const caseString = safeComponentToString(pcCase);
  
  // For now, just return compatible since we don't have detailed specs
  return { compatible: true };
};

/**
 * Check if RAM is compatible with a motherboard
 */
export const isRAMCompatibleWithMotherboard = (
  ram: any,
  motherboard: any
): { compatible: boolean; reason?: string } => {
  const ramString = safeComponentToString(ram);
  const mbString = safeComponentToString(motherboard);
  
  // Basic compatibility checks
  if (ramString.includes('DDR5') && !mbString.includes('DDR5')) {
    return { 
      compatible: false, 
      reason: 'DDR5 RAM is not compatible with this motherboard' 
    };
  }
  
  if (ramString.includes('DDR4') && !mbString.includes('DDR4')) {
    return { 
      compatible: false, 
      reason: 'DDR4 RAM is not compatible with this motherboard' 
    };
  }
  
  return { compatible: true };
};

/**
 * Check if power supply is sufficient for the components
 */
export const isPSUSufficientForBuild = (
  psu: any,
  gpu: any,
  cpu: any
): { compatible: boolean; reason?: string } => {
  const psuString = safeComponentToString(psu);
  const gpuString = safeComponentToString(gpu);
  const cpuString = safeComponentToString(cpu);
  
  // Rough power draw estimations
  let estimatedPower = 150; // Base system
  
  // Add GPU power estimate
  if (gpuString.includes('4090')) estimatedPower += 450;
  else if (gpuString.includes('4080')) estimatedPower += 320;
  else if (gpuString.includes('4070')) estimatedPower += 220;
  else if (gpuString.includes('3090')) estimatedPower += 350;
  else if (gpuString.includes('3080')) estimatedPower += 320;
  else if (gpuString.includes('3070')) estimatedPower += 220;
  else if (gpuString.includes('6900')) estimatedPower += 300;
  else if (gpuString.includes('6800')) estimatedPower += 250;
  else estimatedPower += 180; // Default
  
  // Add CPU power estimate
  if (cpuString.includes('i9')) estimatedPower += 125;
  else if (cpuString.includes('i7')) estimatedPower += 105;
  else if (cpuString.includes('i5')) estimatedPower += 65;
  else if (cpuString.includes('Ryzen 9')) estimatedPower += 105;
  else if (cpuString.includes('Ryzen 7')) estimatedPower += 85;
  else estimatedPower += 65; // Default
  
  // Extract PSU wattage
  const wattageMatch = psuString.match(/(\d+)W/);
  const psuWattage = wattageMatch ? parseInt(wattageMatch[1]) : 0;
  
  if (psuWattage === 0) {
    // Couldn't determine wattage, assume compatible
    return { compatible: true };
  }
  
  if (psuWattage < estimatedPower) {
    return { 
      compatible: false, 
      reason: `Power supply (${psuWattage}W) may not be sufficient for this build (estimated ${estimatedPower}W)`
    };
  }
  
  return { compatible: true };
};

/**
 * Check if a case is compatible with a motherboard
 */
export const isCaseCompatibleWithMotherboard = (
  pcCase: any,
  motherboard: any
): { compatible: boolean; reason?: string } => {
  const caseString = safeComponentToString(pcCase);
  const mbString = safeComponentToString(motherboard);
  
  // For now, just return compatible since we don't have detailed specs
  return { compatible: true };
};
