
import { useState, useEffect, useMemo } from 'react';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Cpu, Monitor, HardDrive, MemoryStick, Fan, Keyboard, Mouse } from 'lucide-react';
import PartsList from './PartsList';
import SelectedBuild from './SelectedBuild';
import { ComponentSpec, ComponentType } from './types';
import mockComponents from './mockData';

interface PCPartSelectorProps {
  onBuildComplete?: (buildData: Record<string, ComponentSpec>) => void;
}

// Core components needed for a complete build
const requiredComponents: ComponentType[] = ['cpu', 'motherboard', 'ram', 'storage', 'case', 'psu'];

// Categories for better organization
const categoryGroups = {
  'Core Components': ['cpu', 'motherboard', 'gpu', 'ram', 'storage', 'case', 'psu'],
  'Cooling': ['cooling', 'caseFan', 'thermalPaste'],
  'Peripherals': ['monitor', 'keyboard', 'mouse', 'headset', 'speakers'],
  'Accessories': ['webcam', 'microphone', 'networkCard', 'soundCard', 'cables', 'controller', 'ups', 'capture'],
  'Software': ['os']
};

// Icon mapping for component types
const getComponentIcon = (type: ComponentType) => {
  switch (type) {
    case 'cpu': return <Cpu className="h-4 w-4 mr-2" />;
    case 'gpu': return <Monitor className="h-4 w-4 mr-2" />;
    case 'ram': return <MemoryStick className="h-4 w-4 mr-2" />;
    case 'storage': return <HardDrive className="h-4 w-4 mr-2" />;
    case 'cooling': return <Fan className="h-4 w-4 mr-2" />;
    case 'keyboard': return <Keyboard className="h-4 w-4 mr-2" />;
    case 'mouse': return <Mouse className="h-4 w-4 mr-2" />;
    default: return null;
  }
};

const PCPartSelector = ({ onBuildComplete }: PCPartSelectorProps) => {
  const [activeTab, setActiveTab] = useState<ComponentType>('cpu');
  const [selectedParts, setSelectedParts] = useState<Record<string, ComponentSpec>>({});
  const [components, setComponents] = useState<ComponentSpec[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // Get all component types
  const componentTypes = useMemo(() => {
    const types: ComponentType[] = [];
    Object.values(categoryGroups).forEach(group => {
      group.forEach(type => {
        if (!types.includes(type as ComponentType)) {
          types.push(type as ComponentType);
        }
      });
    });
    return types;
  }, []);
  
  // Load mock data when tab changes
  useEffect(() => {
    const loadComponents = () => {
      setIsLoading(true);
      try {
        // Simulate API fetch delay
        setTimeout(() => {
          setComponents(mockComponents[activeTab] || []);
          setIsLoading(false);
        }, 300);
      } catch (err) {
        setError('Failed to load components');
        setIsLoading(false);
      }
    };
    
    loadComponents();
  }, [activeTab]);
  
  // Calculate total price
  const totalPrice = Object.values(selectedParts).reduce(
    (sum, component) => sum + (component?.price || 0),
    0
  );
  
  // Handle component selection
  const handleSelectComponent = (component: ComponentSpec) => {
    setSelectedParts(prev => ({
      ...prev,
      [activeTab]: component
    }));
    
    // Move to next tab automatically if possible
    const currentIndex = componentTypes.indexOf(activeTab);
    if (currentIndex < componentTypes.length - 1) {
      const nextTab = componentTypes[currentIndex + 1];
      setActiveTab(nextTab);
    }
  };
  
  // Complete the build process
  const handleComplete = () => {
    if (onBuildComplete) {
      onBuildComplete(selectedParts);
    }
  };
  
  // Check if build is complete (all required components selected)
  const isBuildComplete = requiredComponents.every(type => selectedParts[type]);
  
  // Display name for component types
  const getComponentDisplayName = (type: ComponentType): string => {
    const displayNames: Record<ComponentType, string> = {
      'cpu': 'CPU',
      'motherboard': 'Motherboard',
      'gpu': 'Graphics Card',
      'ram': 'Memory (RAM)',
      'storage': 'Storage',
      'case': 'Case',
      'psu': 'Power Supply',
      'cooling': 'CPU Cooler',
      'caseFan': 'Case Fans',
      'thermalPaste': 'Thermal Paste',
      'os': 'Operating System',
      'monitor': 'Monitor',
      'keyboard': 'Keyboard',
      'mouse': 'Mouse',
      'headset': 'Headset',
      'speakers': 'Speakers',
      'webcam': 'Webcam',
      'microphone': 'Microphone',
      'networkCard': 'Network Card',
      'soundCard': 'Sound Card',
      'cables': 'Cables',
      'controller': 'Controller',
      'ups': 'UPS',
      'capture': 'Capture Card'
    };
    
    return displayNames[type] || type;
  };

  // Check if a component is selected
  const isComponentSelected = (type: ComponentType) => {
    return !!selectedParts[type];
  };
  
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Left sidebar: Component selection */}
        <div className="md:col-span-1">
          <div className="bg-card rounded-lg shadow-sm p-4 border">
            <h3 className="font-medium mb-4">Component Selection</h3>
            <div className="space-y-2">
              {Object.entries(categoryGroups).map(([category, types]) => (
                <div key={category} className="mb-4">
                  <h4 className="text-sm font-medium text-muted-foreground mb-2">{category}</h4>
                  <div className="space-y-1">
                    {types.map((type) => (
                      <button
                        key={type}
                        onClick={() => setActiveTab(type as ComponentType)}
                        className={`w-full text-left px-3 py-2 rounded-md text-sm flex items-center ${
                          activeTab === type 
                            ? 'bg-primary text-primary-foreground' 
                            : isComponentSelected(type as ComponentType)
                              ? 'bg-primary/10 hover:bg-primary/20' 
                              : 'hover:bg-secondary'
                        }`}
                      >
                        {getComponentIcon(type as ComponentType)}
                        <span>{getComponentDisplayName(type as ComponentType)}</span>
                        {isComponentSelected(type as ComponentType) && (
                          <span className="ml-auto text-xs font-medium">
                            ${selectedParts[type as ComponentType]?.price.toFixed(2)}
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Main content: Parts list */}
        <div className="md:col-span-2">
          <div className="bg-card rounded-lg shadow-sm p-4 border">
            <h3 className="font-medium mb-4">{getComponentDisplayName(activeTab)}</h3>
            <PartsList
              components={components}
              isLoading={isLoading}
              error={error}
              selectedComponent={selectedParts[activeTab] || null}
              componentType={activeTab}
              onSelectComponent={handleSelectComponent}
            />
          </div>
        </div>
      </div>
      
      {/* Selected build summary */}
      <SelectedBuild
        pcBuild={selectedParts}
        totalPrice={totalPrice}
      />
      
      <div className="flex justify-between mt-8">
        <Button 
          variant="outline"
          onClick={() => setSelectedParts({})}
        >
          Reset Build
        </Button>
        <Button 
          size="lg" 
          onClick={handleComplete}
          disabled={!isBuildComplete}
        >
          Complete Build
        </Button>
      </div>
    </div>
  );
};

export default PCPartSelector;
