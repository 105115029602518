
import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Search } from 'lucide-react';
import ComponentCard from './ComponentCard';
import { ComponentSpec, ComponentType } from './types';

interface PartsListProps {
  components: ComponentSpec[];
  isLoading: boolean;
  error: string;
  selectedComponent: ComponentSpec | null;
  componentType: ComponentType;
  onSelectComponent: (component: ComponentSpec) => void;
}

const PartsList: React.FC<PartsListProps> = ({
  components,
  isLoading,
  error,
  selectedComponent,
  componentType,
  onSelectComponent,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const filteredComponents = components.filter(component => 
    component.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    component.brand.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Card>
      <CardHeader className="pb-3">
        <div className="flex items-center justify-between">
          <CardTitle className="text-xl capitalize">
            {componentType === 'psu' ? 'Power Supply' : 
             componentType === 'gpu' ? 'Graphics Card' : 
             componentType === 'ram' ? 'Memory' : 
             componentType}
          </CardTitle>
          <div className="relative">
            <Search className="h-4 w-4 absolute left-2.5 top-2.5 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search..."
              className="pl-8 w-[200px]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="text-center py-10">Loading components...</div>
        ) : error ? (
          <div className="text-center py-10 text-destructive">{error}</div>
        ) : filteredComponents.length === 0 ? (
          <div className="text-center py-10">No components found matching your search</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredComponents.map(component => (
              <ComponentCard
                key={component.id}
                component={component}
                isSelected={selectedComponent?.id === component.id}
                onClick={() => onSelectComponent(component)}
              />
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PartsList;
