
import { Moon, Sun } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useTheme } from './ThemeProvider';
import { cn } from '@/lib/utils';

interface ThemeToggleProps {
  className?: string;
}

export function ThemeToggle({ className }: ThemeToggleProps) {
  const { theme, toggleTheme } = useTheme();
  
  console.log("ThemeToggle rendering with theme:", theme);

  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={() => {
        console.log("Theme toggle button clicked");
        toggleTheme();
      }}
      className={cn("rounded-full relative", className)}
      aria-label="Toggle theme"
      style={{
        backgroundColor: theme === 'dark' ? 'hsl(var(--secondary))' : 'hsl(var(--secondary))',
        color: theme === 'dark' ? 'hsl(var(--secondary-foreground))' : 'hsl(var(--secondary-foreground))',
      }}
    >
      <Sun className={cn(
        "h-[1.2rem] w-[1.2rem] transition-all text-foreground",
        theme === 'dark' ? "opacity-100 scale-100 rotate-0" : "opacity-0 scale-0 -rotate-90"
      )} />
      <Moon className={cn(
        "absolute h-[1.2rem] w-[1.2rem] transition-all text-foreground",
        theme === 'light' ? "opacity-100 scale-100 rotate-0" : "opacity-0 scale-0 rotate-90"
      )} />
      <span className="sr-only">Toggle theme</span>
    </Button>
  );
}
