
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Hero from '@/components/Hero';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import Logo from '@/components/Logo';
import { ThemeToggle } from '@/components/ThemeToggle';
import { useUserContext } from '@/contexts/UserContext';
import { useFirebaseAuth } from '@/contexts/FirebaseAuthContext';
import { UserCircle, ShieldAlert, Image, Sparkles, Monitor, Cpu, BookOpen, ChevronRight } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '@/components/ui/card';

const ADMIN_EMAIL = "logan.derose@gmail.com";

const Index = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [contentKey, setContentKey] = useState(0);
  const { isAuthenticated, currentUser } = useFirebaseAuth();
  const navigate = useNavigate();
  
  const isAdmin = isAuthenticated && currentUser?.email === ADMIN_EMAIL;
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, [isAnimating]);
  
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-background to-secondary/20">
      <header className="border-b border-border/40 backdrop-blur-sm sticky top-0 z-10 bg-background/80">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <Logo />
          <div className="flex gap-3 items-center">
            <ThemeToggle />
            <Link to="/guides">
              <Button variant="outline" size="sm" className="hidden sm:flex">Guides</Button>
            </Link>
            <Link to="/contact">
              <Button variant="outline" size="sm" className="hidden sm:flex">Contact Us</Button>
            </Link>
            {isAdmin && (
              <>
                <Link to="/admin" className="hidden md:block">
                  <Button variant="outline" size="sm" className="gap-1">
                    <ShieldAlert className="h-4 w-4" />
                    Admin
                  </Button>
                </Link>
                <Link to="/image-manager" className="hidden md:block">
                  <Button variant="outline" size="sm" className="gap-1">
                    <Image className="h-4 w-4" />
                    Images
                  </Button>
                </Link>
              </>
            )}
            <Link to="/account">
              <Button 
                variant={isAuthenticated ? "default" : "outline"} 
                size="sm"
                className="aspect-square p-2"
                aria-label={isAuthenticated ? "My Account" : "Sign In"}
              >
                <UserCircle className="h-5 w-5" />
              </Button>
            </Link>
          </div>
        </div>
      </header>
      
      <main className="flex-1">
        <div className="container mx-auto px-4 py-12">
          <div key={`hero-${contentKey}`} className="animate-fade-in w-full">
            <Hero />
          </div>
          
          <div className="mt-24 mb-8 text-center">
            <h2 className="text-3xl font-bold mb-4">Customize Your Dream PC</h2>
            <p className="text-muted-foreground max-w-2xl mx-auto">
              Choose from our expert-designed builds or create your own. We help you select the perfect components for your needs.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            <Card className="shadow-md hover:shadow-xl transition-all duration-300 border-border/50 overflow-hidden group">
              <div className="h-3 bg-gradient-to-r from-blue-500 to-purple-500"></div>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Cpu className="h-5 w-5 text-blue-500" />
                  PC Part Picker
                </CardTitle>
                <CardDescription>
                  Build your custom PC by selecting compatible components
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="mb-4">
                  Our interactive PC Part Picker helps you create the perfect computer tailored to your needs. 
                  Select compatible components and build your dream PC with real-time compatibility checking.
                </p>
              </CardContent>
              <CardFooter>
                <Button onClick={() => navigate('/pc-part-picker')} className="w-full group-hover:bg-blue-500 group-hover:text-white transition-colors">
                  PC4U: Find My PC
                </Button>
              </CardFooter>
            </Card>
            
            <Card className="shadow-md hover:shadow-xl transition-all duration-300 border-border/50 overflow-hidden group">
              <div className="h-3 bg-gradient-to-r from-purple-500 to-pink-500"></div>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Sparkles className="h-5 w-5 text-purple-500" />
                  Recommended Builds
                </CardTitle>
                <CardDescription>
                  Get our expert recommendations for your specific needs
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="mb-4">
                  Let our system select the optimal components for your needs. Tell us what you'll use your PC for 
                  and your budget, and we'll create the perfect configuration with detailed explanations.
                </p>
              </CardContent>
              <CardFooter>
                <Button onClick={() => navigate('/optimal-build')} className="w-full group-hover:bg-purple-500 group-hover:text-white transition-colors bg-purple-500 text-white hover:bg-purple-600">
                  Find My Optimal Build
                </Button>
              </CardFooter>
            </Card>
            
            <Card className="shadow-md hover:shadow-xl transition-all duration-300 border-border/50 overflow-hidden group md:col-span-2 lg:col-span-1">
              <div className="h-3 bg-gradient-to-r from-green-500 to-teal-500"></div>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <BookOpen className="h-5 w-5 text-green-500" />
                  PC Building Guides
                </CardTitle>
                <CardDescription>
                  Learn how to build your own PC with our comprehensive guides
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="mb-4">
                  New to PC building? Our detailed guides cover everything from component selection to assembly and troubleshooting.
                  Learn best practices, tips, and tricks for building your own custom PC.
                </p>
              </CardContent>
              <CardFooter>
                <Button 
                  onClick={() => navigate('/guides')} 
                  className="w-full bg-green-500 hover:bg-green-600 text-white transition-colors flex items-center justify-center gap-2"
                >
                  View PC Building Guides
                  <ChevronRight className="h-4 w-4" />
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </main>
      
      <footer className="border-t border-border/40 py-6 mt-12 bg-muted/30">
        <div className="container mx-auto px-4 text-center text-sm text-muted-foreground">
          <p>© {new Date().getFullYear()} PC4U. All rights reserved.</p>
          <div className="flex justify-center gap-4 mt-3">
            <Link to="/contact" className="hover:text-primary transition-colors">Contact</Link>
            <Link to="/terms" className="hover:text-primary transition-colors">Terms</Link>
            <Link to="/privacy" className="hover:text-primary transition-colors">Privacy</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Index;
