/**
 * Centralized registry of fallback images for PC components
 * Organized by component category
 */

// Base URL for placeholder images (using Unsplash high-quality tech images)
const PLACEHOLDER_BASE = "https://images.unsplash.com/";

// Component-specific fallback images
export const fallbackImages = {
  // Core PC components
  cpu: `${PLACEHOLDER_BASE}photo-1555618254-3a96d71d4047`,  // CPU image
  gpu: `${PLACEHOLDER_BASE}photo-1591488320449-187e68c583e9`,  // GPU image
  motherboard: `${PLACEHOLDER_BASE}photo-1518770660439-4636190af475`, // Motherboard image
  ram: `${PLACEHOLDER_BASE}photo-1562976540-1502c2145186`, // RAM image
  storage: `${PLACEHOLDER_BASE}photo-1597838816902-35b18e22c528`, // Storage/SSD image
  psu: `${PLACEHOLDER_BASE}photo-1563863563956-fcc073d6eb1d`, // Power supply image
  case: `${PLACEHOLDER_BASE}photo-1587202372616-b43abea06c2a`, // PC case image
  cooling: `${PLACEHOLDER_BASE}photo-1587202372616-b43abea06c2a?cooling`, // Cooling system
  
  // Peripherals
  monitor: `${PLACEHOLDER_BASE}photo-1527443195645-1133f7f28990`, // Monitor
  keyboard: `${PLACEHOLDER_BASE}photo-1561112078-7d24e04c3407`, // Keyboard
  mouse: `${PLACEHOLDER_BASE}photo-1615663245857-ac93bb7c39e7`, // Mouse
  headset: `${PLACEHOLDER_BASE}photo-1505740420928-5e560c06d30e`, // Headphones
  speakers: `${PLACEHOLDER_BASE}photo-1545454675-3531b543be5d`, // Speakers
  
  // Workspace
  desk: `${PLACEHOLDER_BASE}photo-1593062096033-9a26b09da705`, // Desk
  chair: `${PLACEHOLDER_BASE}photo-1596079890744-c1a0462d0975`, // Chair
  
  // General tech
  "pc-build": `${PLACEHOLDER_BASE}photo-1593640408182-31c70c8268f5`, // Complete PC build
  tech: `${PLACEHOLDER_BASE}photo-1518770660439-4636190af475`, // Generic tech fallback
  component: `${PLACEHOLDER_BASE}photo-1591799264318-7e6ef8ddb7ea`, // Generic component fallback
  accessory: `${PLACEHOLDER_BASE}photo-1563770660941-20978e870e26`, // Generic accessory fallback
  
  // Default fallback
  default: `${PLACEHOLDER_BASE}photo-1518770660439-4636190af475`, // Default tech image
};

/**
 * Get fallback image for a specific category
 * @param category The category to get a fallback for
 * @param name Optional name of the component for more specific matches
 * @returns URL to a fallback image
 */
export const getCategoryFallback = (category?: string, name?: string): string => {
  if (!category) {
    return fallbackImages.default;
  }
  
  // Normalize the category to handle case variations and plurals
  const normalizedCategory = category.toLowerCase().trim();
  
  // Handle common synonyms and variations
  const categoryMapping: Record<string, string> = {
    'processor': 'cpu',
    'graphics card': 'gpu',
    'graphic card': 'gpu',
    'video card': 'gpu',
    'memory': 'ram',
    'hard drive': 'storage',
    'ssd': 'storage',
    'hdd': 'storage',
    'power supply': 'psu',
    'tower': 'case',
    'chassis': 'case',
    'cooler': 'cooling',
    'fan': 'cooling',
    'heatsink': 'cooling',
    'display': 'monitor',
    'screen': 'monitor',
    'headphones': 'headset',
    'speaker': 'speakers',
    'computer': 'pc-build',
    'desktop': 'pc-build',
    'workstation': 'pc-build',
    'gaming pc': 'pc-build',
  };
  
  // Try to map the category to a known one
  const mappedCategory = categoryMapping[normalizedCategory] || normalizedCategory;
  
  // Check if we have a direct fallback for this category
  if (fallbackImages[mappedCategory as keyof typeof fallbackImages]) {
    return fallbackImages[mappedCategory as keyof typeof fallbackImages];
  }
  
  // For components that don't match any specific category
  if (normalizedCategory.includes('board')) {
    return fallbackImages.motherboard;
  }
  
  // If we reach here, use the default fallback
  return fallbackImages.default;
};

/**
 * Utility to validate if an image URL is likely an actual image
 * @param url The URL to check
 * @returns Boolean indicating if this is likely an image URL
 */
export const hasValidImageExtension = (url: string): boolean => {
  // Validation for images that end with standard image extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|webp|bmp|svg)($|\?)/i;
  return imageExtensions.test(url);
};

/**
 * Formats an Amazon image URL to ensure it's using the high-quality version
 * @param url The Amazon image URL
 * @returns Formatted high-quality URL
 */
export const formatAmazonImageUrl = (url: string): string => {
  if (!url || !url.includes('amazon') || !url.includes('images')) {
    return url;
  }
  
  try {
    // Extract the image ID - typically formatted like "I/[IMAGE_ID]"
    const match = url.match(/\/images\/I\/([^\.\/]+)/i);
    if (!match || !match[1]) return url;
    
    const imageId = match[1];
    
    // Create a high-quality version URL
    return `https://m.media-amazon.com/images/I/${imageId}._AC_SL1200_.jpg`;
  } catch (e) {
    console.error('Error formatting Amazon image URL:', e);
    return url;
  }
};

/**
 * Save a PC build image to localStorage
 * @param buildId The build ID
 * @param imageUrl The image URL to save
 */
export const savePCBuildImage = (buildId: string, imageUrl: string): void => {
  try {
    // Get existing stored images
    const storedImages = JSON.parse(localStorage.getItem('pcBuildImages') || '{}');
    
    // Update with new image
    storedImages[buildId] = imageUrl;
    
    // Save back to localStorage
    localStorage.setItem('pcBuildImages', JSON.stringify(storedImages));
    
    // Dispatch an event to notify other components
    const event = new CustomEvent('pc-build-image-updated', {
      detail: { buildId, url: imageUrl }
    });
    window.dispatchEvent(event);
  } catch (error) {
    console.error('Error saving PC build image:', error);
  }
};
