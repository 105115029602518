
import { Button } from '@/components/ui/button';
import { ExternalLink, ShoppingCart } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { useState } from 'react';

interface PurchaseActionsProps {
  isProcessing: boolean;
  onPurchase: () => void;
}

const PurchaseActions = ({ 
  isProcessing, 
  onPurchase 
}: PurchaseActionsProps) => {
  const { toast } = useToast();
  
  const handlePurchase = () => {
    try {
      onPurchase();
      
      // Show a success toast
      toast({
        title: "Opening Newegg cart",
        description: "You'll be redirected to complete your purchase",
        duration: 3000
      });
    } catch (error) {
      console.error("Error opening Newegg cart:", error);
      toast({
        title: "Error",
        description: "There was a problem redirecting to Newegg. Please try again.",
        variant: "destructive"
      });
    }
  };
  
  return (
    <div className="space-y-2 mt-6">
      <Button 
        className="w-full gap-2"
        size="lg"
        onClick={handlePurchase}
        disabled={isProcessing}
      >
        <ShoppingCart className="h-4 w-4" />
        {isProcessing ? "Processing..." : "Purchase on Newegg"}
        <ExternalLink className="h-4 w-4 ml-1" />
      </Button>
      
      <p className="text-xs text-muted-foreground text-center">
        Products will be automatically added to your Newegg cart
      </p>
    </div>
  );
};

export default PurchaseActions;
