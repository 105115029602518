
import { Accessory } from '@/utils/pcBuilder';

/**
 * Extract brand from a product name
 */
export const extractBrandFromName = (name: string): string => {
  // Common brands in PC peripherals
  const commonBrands = [
    'Logitech', 'Corsair', 'Razer', 'ASUS', 'Acer', 'Dell', 'HP', 
    'LG', 'Samsung', 'Sony', 'SteelSeries', 'HyperX', 'Microsoft',
    'BenQ', 'ViewSonic', 'Acer', 'AOC', 'Alienware', 'Apple', 'Cooler Master',
    'EVGA', 'Fractal Design', 'Gigabyte', 'NZXT', 'MSI', 'Thermaltake',
    'Sennheiser', 'Audio-Technica', 'Blue', 'Elgato', 'Shure', 'Rode',
    'Philips', 'Govee', 'Nanoleaf', 'SecretLab', 'Herman Miller', 'IKEA'
  ];
  
  // Check if any common brand is at the start of the name
  for (const brand of commonBrands) {
    if (name.startsWith(brand) || name.toLowerCase().startsWith(brand.toLowerCase())) {
      return brand;
    }
  }
  
  // Default: use first word as brand
  const firstWord = name.split(' ')[0];
  return firstWord || 'Generic';
};

/**
 * Add 'brand' field to an accessory if missing
 */
export const processAccessory = (accessory: any): Accessory => {
  // If brand is already present, return as is
  if (accessory.brand) {
    return accessory as Accessory;
  }
  
  // Extract brand from name
  const brand = extractBrandFromName(accessory.name);
  
  // Remove any unrecognized fields (like productUrl)
  const {
    id,
    name,
    description,
    imageUrl,
    price,
    category,
    compatibleWith,
    theme,
    group,
    recommended,
    compatibility,
    compatibleThemes,
    useFallbackImage,
    ...rest
  } = accessory;
  
  // Return a properly formatted accessory with brand
  return {
    id,
    name,
    description,
    imageUrl,
    price,
    brand,
    category,
    compatibility: compatibleWith || compatibility || [], // Use either property
    theme: theme || [],
    ...(group && { group }),
    ...(recommended !== undefined && { recommended }),
    ...(compatibleThemes && { theme: compatibleThemes }),  // Map to the correct property
    ...(useFallbackImage !== undefined && { useFallbackImage })
  };
};

/**
 * Process an array of accessories to ensure all required fields are present
 */
export const processAccessories = (accessories: any[]): Accessory[] => {
  return accessories.map(accessory => processAccessory(accessory));
};
