import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowLeft, AlertCircle, RefreshCw } from 'lucide-react';
import { useFirebaseAuth } from '@/contexts/FirebaseAuthContext';
import UserAccount from '@/components/UserAccount';
import FirebaseAuth from '@/components/auth/FirebaseAuth';
import { Skeleton } from '@/components/ui/skeleton';
import { Alert, AlertDescription } from '@/components/ui/alert';

const Account = () => {
  const { currentUser, isAuthenticated, loading: authLoading, authError } = useFirebaseAuth();
  const [pageReady, setPageReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  // Initialize page state
  useEffect(() => {
    console.log("Account page mounted, auth state:", { isAuthenticated, authLoading, user: currentUser?.email });
    
    // Set page ready after a short delay to allow auth to complete
    const timer = setTimeout(() => {
      setPageReady(true);
      console.log("Account page ready");
    }, 500);
    
    return () => clearTimeout(timer);
  }, [isAuthenticated, authLoading, currentUser]);

  // Reset page ready state when auth state changes
  useEffect(() => {
    if (authLoading && pageReady) {
      setPageReady(false);
    } else if (!authLoading && !pageReady) {
      // If loading completes and page isn't ready, set it ready
      setPageReady(true);
    }
  }, [authLoading, pageReady]);

  // Set error state from auth context
  useEffect(() => {
    if (authError) {
      setError(authError);
    }
  }, [authError]);

  const handleRefresh = () => {
    setRefreshing(true);
    setError(null);
    
    // Allow time for UI to update, then refresh the page
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="flex justify-between items-center mb-6">
        <Link to="/">
          <Button variant="outline" size="sm">
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back
          </Button>
        </Link>
        <h1 className="text-2xl font-bold">Account</h1>
        {(error || authError) && (
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleRefresh}
            disabled={refreshing}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${refreshing ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        )}
      </div>

      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            {error}
          </AlertDescription>
        </Alert>
      )}

      {(!pageReady || authLoading) && (
        <div className="space-y-4">
          <Skeleton className="h-[250px] w-full rounded-lg" />
        </div>
      )}

      {pageReady && !authLoading && (
        <>
          {isAuthenticated ? <UserAccount /> : <FirebaseAuth />}
        </>
      )}
    </div>
  );
};

export default Account;
